import { DefaultButton, IIconProps, Link, Stack, Text } from "@fluentui/react";
import { useKeycloak } from "@react-keycloak/web";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchAdminState, logout } from "../helpers/auth";
import { RootState } from "../slices";

const logoutIcon: IIconProps = {iconName: 'Leave'};

// Display the identity of the user
const UserInfo: React.FunctionComponent = () => {
  const isAdmin = useSelector((state: RootState) => state.user.isAdmin);
  const userProfileUrl = useSelector((state: RootState) => state.config.userProfileUrl);
  const username = useSelector((state: RootState) => state.user.username);
  const dispatch = useDispatch();
  const {keycloak} = useKeycloak();

  React.useEffect(() => {
    fetchAdminState(dispatch);
  }, [dispatch]);

  async function onLogoutClick() {
    await logout(keycloak, dispatch);
  }

  return (
    <Stack horizontal verticalAlign={"center"} tokens={{childrenGap: 20}}>
      <Text variant={"large"}>
        <Link
          href={userProfileUrl}
          target="_blank"
          rel="noopener noreferrer"
        >{username}</Link>
        {isAdmin ? " (admin)" : ""}
      </Text>
      <DefaultButton iconProps={logoutIcon} text="Déconnexion" onClick={onLogoutClick} allowDisabledFocus />
    </Stack>
  );
};

export default UserInfo;
