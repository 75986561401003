import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { configSlice } from "./configSlice";
import { errorSlice } from "./errorSlice";
import { userSlice } from "./userSlice";


const rootReducer = combineReducers({
  config: configSlice.reducer,
  error: errorSlice.reducer,
  user: userSlice.reducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export { store, userSlice, configSlice };

export type RootState = ReturnType<typeof rootReducer>;
