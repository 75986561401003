import { IColumn, Selection, Text, TextField } from "@fluentui/react";
import React from "react";
import { useDispatch } from "react-redux";

import { createOneTaskType, deleteOneTaskType, getAllTaskTypes, modifyOneTaskType } from "../../api/actions";
import { ITaskType, ITaskTypePatch, ITaskTypePost, ITmpTaskType, validateTmpTaskType } from "../../api/types";
import { GenericActionBar } from "../../components/genericconfig/GenericActionBar";
import { GenericActionDialog, GenericRemoveDialog } from "../../components/genericconfig/GenericActionDialog";
import { GenericTable } from "../../components/genericconfig/GenericTable";
import { ActionMode } from "../../components/genericconfig/types";
import { ImageSelector } from "../../components/ImageSelector";
import { unpackResponse } from "../../helpers/requests";
import { errorSlice } from "../../slices/errorSlice";


const DEFAULT_TMP_TASK_TYPE: ITmpTaskType = {name: "", image_url: ""};
const tableColumns: IColumn[] = [
  {key: "id", name: "ID", fieldName: "id", minWidth: 20, maxWidth: 40},
  {key: "name", name: "Nom", fieldName: "name", minWidth: 100},
  {key: "image_url", name: "URL de l'image", fieldName: "image_url", minWidth: 300},
];

export const TaskTypesTab: React.FC = () => {
  const dispatch = useDispatch();
  const [allTaskTypes, setAllTaskTypes] = React.useState<ITaskType[]>([]);
  const [selected, setSelected] = React.useState<ITaskType>();

  const [actionMode, setActionMode] = React.useState<ActionMode>(ActionMode.ADD);
  const [isDialogHidden, setIsDialogHidden] = React.useState(true);
  const [tmpType, setTmpType] = React.useState<ITmpTaskType>(DEFAULT_TMP_TASK_TYPE);
  const validationError = validateTmpTaskType(tmpType);

  const selection = React.useMemo(() => {
    return new Selection({
      onSelectionChanged: () => {
        const allSelected: ITaskType[] = selection.getSelection() as ITaskType[];
        const firstSelected = allSelected.length !== 0 ? allSelected[0] : undefined;
        setSelected(firstSelected);
      },
    });
  }, []);

  const createErrorPopup = React.useCallback((err: Error) => {
    dispatch(errorSlice.actions.push({
      title: "Erreur lors de la requête",
      message: err.message,
    }));
  }, [dispatch]); // In memo because it is used by a function in memo

  const fetchTaskTypes = React.useCallback(() => {
    getAllTaskTypes().then(unpackResponse).then(
      newData => setAllTaskTypes(newData),
      err => createErrorPopup(err),
    );
  }, [createErrorPopup]); // In memo because it is used in a useEffect

  function sendTaskTypePost(): void {
    const postData: ITaskTypePost = {name: tmpType.name, image_url: tmpType.image_url || null};
    createOneTaskType(postData).then(unpackResponse).then(
      () => {
        fetchTaskTypes();
      },
      err => {
        createErrorPopup(err);
      }
    );
  }

  function sendTaskTypePatch(): void {
    if (selected !== undefined) {
      const patchData: ITaskTypePatch = {name: tmpType.name, image_url: tmpType.image_url || null};
      modifyOneTaskType(selected.id, patchData).then(unpackResponse).then(
        () => {
          fetchTaskTypes();
        },
        err => {
          createErrorPopup(err);
        }
      );
    } else {
      console.warn("Can't modify when no task is selected.");
    }
  }

  function sendTaskTypeDelete(): void {
    if (selected !== undefined) {
      deleteOneTaskType(selected.id).then(unpackResponse).then(
        () => {
          fetchTaskTypes();
        },
        err => {
          createErrorPopup(err);
        }
      );
    } else {
      console.warn("Can't delete when no task is selected.");
    }
  }

  function onDialogSubmit(): void {
    setIsDialogHidden(true);
    if (actionMode === ActionMode.ADD) {
      sendTaskTypePost();
    } else if (actionMode === ActionMode.REMOVE) {
      sendTaskTypeDelete();
    } else if (actionMode === ActionMode.EDIT) {
      sendTaskTypePatch();
    }
  }

  React.useEffect(() => {
    fetchTaskTypes();
  }, [fetchTaskTypes]);

  return (
    <>
      <GenericTable
        items={allTaskTypes}
        columns={tableColumns}
        selection={selection}
      />
      <GenericActionBar
        disableRemoveEdit={selected === undefined}
        setActionMode={setActionMode}
        displayModal={() => setIsDialogHidden(false)}
        emptyTmp={() => setTmpType(DEFAULT_TMP_TASK_TYPE)}
        fillTmp={() => {
          setTmpType({
            name: selected?.name ?? DEFAULT_TMP_TASK_TYPE.name,
            image_url: selected?.image_url ?? DEFAULT_TMP_TASK_TYPE.image_url,
          });
        }}
      />
      <GenericRemoveDialog
        title={"Supprimer un type de tâche"}
        isHidden={isDialogHidden || actionMode !== ActionMode.REMOVE}
        hide={() => setIsDialogHidden(true)}
        onSubmit={onDialogSubmit}
      >
        <Text>
          {`Voulez-vous supprimer le type #${selected?.id} : `}
          <b>{selected?.name}</b> ?<br />
          Toutes les tâches associées seront également supprimées.
        </Text>
      </GenericRemoveDialog>
      <GenericActionDialog
        isHidden={isDialogHidden || actionMode === ActionMode.REMOVE}
        hide={() => setIsDialogHidden(true)}
        actionMode={actionMode}
        onSubmit={onDialogSubmit}
        genericName={"un type de tâche"}
        errorText={validationError}
      >
        <TextField
          label={"Nom"}
          value={tmpType.name}
          onChange={(ev, val) => setTmpType({...tmpType, name: val ?? ""})}
        />
        <ImageSelector
          category={"tasktype"}
          value={tmpType.image_url}
          onChange={val => setTmpType({...tmpType, image_url: val})}
        />
      </GenericActionDialog>
    </>
  );
};
