import {DatePicker, DayOfWeek, Dropdown, IDropdownOption, Stack, Text, TextField, Toggle,} from "@fluentui/react";
import React from "react";
import {SlotInfo} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

import {
  createAbsence,
  createOneRecurringTask,
  createOneTask,
  deleteAbsence,
  deleteOneRecurringTask,
  deleteOneTask,
  getAllPlaces,
  getAllTaskTypes,
  getAllUsers,
  getResidentAbsence,
  getUserRecurringTasks,
  getUserTasks,
  modifyAbsence,
  modifyOneRecurringTask,
  modifyOneTask
} from "../../api/actions";
import {
  default_day_of_week,
  IAbsence,
  IAbsencePatch,
  IAbsencePost,
  IPlace,
  IRecurringTask,
  IRecurringTaskPost,
  ITask,
  ITaskPatch,
  ITaskPost,
  ITaskType,
  ITmpTask,
  IUser,
  ISelectedDateRange,
  recurring_type_list,
  UserRole,
  validateTmpTask,
} from "../../api/types";
import {GenericActionDialog, GenericRemoveDialog} from "../../components/genericconfig/GenericActionDialog";
import {ActionMode} from "../../components/genericconfig/types";
import {IEvent, TaskCalendar} from "../../components/TaskCalendar";
import {createErrorPopup} from "../../helpers/errors";
import {unpackResponse} from "../../helpers/requests";
import {dateAsString, mergeDayAndTime, onFormatDate, stringAsDate, toHoursAndMinutes} from "../../helpers/time";
import {frDatePickerString} from "../../helpers/frCalendarStrings";
import moment from "moment";


const DEFAULT_TMP_TASK: ITmpTask = {
  user_id: 0,
  type_id: 0,
  place_id: 0,
  day: new Date(),
  start: "",
  end: "",
  start_period: "",
  end_period: "",
  start_task_time: "",
  end_task_time: "",
  timer: "",
  recurring_task: false,
  recurring_type: "daily",
  day_of_week: default_day_of_week,
  absence: false,
  absence_all_day: true
};

const series_or_instance_selection = [{
  key: "instance",
  text: "L'occurrence",
},{
  key: "series",
  text: "Toute la série",
}]

export const TasksTab: React.FC = () => {
  const [allResidents, setAllResidents] = React.useState<IUser[]>([]);
  const [residentAbsences, setResidentAbsences] = React.useState<IAbsence[]>([]);
  const [selectedResident, setSelectedResident] = React.useState<IUser>();
  const [selectedDateRange, setSelectedDateRange] = React.useState<ISelectedDateRange>({
    start: moment().startOf('isoWeek').toISOString().slice(0, -1), // Remove the trailing "Z"
    end: moment().endOf('isoWeek').toISOString().slice(0, -1) // Remove the trailing "Z"
  });
  const residentOptions: IDropdownOption[] = React.useMemo(() => {
    return allResidents.map(resident => ({
      key: resident.id,
      text: resident.firstname + " " + resident.lastname,
    }));
  }, [allResidents]);

  const [taskList, setTaskList] = React.useState<ITask[]>([]);
  const [recurringTaskList, setRecurringTaskList] = React.useState<IRecurringTask[]>([])
  const displayedEvents: IEvent[] = React.useMemo(() => {
    let eventList = taskList.map(task => ({
      id: task.id,
      title: task.type.name,
      start: new Date(task.start),
      end: new Date(task.end),
      allDay: false,
      absence: false,
    }));
    return eventList.concat(residentAbsences.map(absence => ({
      id: -absence.id,
      title: "Absence",
      start: new Date(absence.start),
      end: new Date(absence.end),
      allDay: absence.all_day,
      absence: true,
    })))
  }, [taskList, residentAbsences]);
  const [selectedTask, setSelectedTask] = React.useState<ITask>();
  const [selectedAbsence, setSelectedAbsence] = React.useState<IAbsence>();
  const [tmpTask, setTmpTask] = React.useState<ITmpTask>(DEFAULT_TMP_TASK);
  const [isTaskDialogHidden, setIsTaskDialogHidden] = React.useState(true);
  const [actionMode, setActionMode] = React.useState<ActionMode>(ActionMode.ADD);
  const [seriesSelected, setSeriesSelected] = React.useState<string>("instance")

  const validationError = validateTmpTask(tmpTask);

  const [allTaskTypes, setAllTaskTypes] = React.useState<ITaskType[]>([]);
  const taskTypesOptions: IDropdownOption[] = React.useMemo(() => {
    return allTaskTypes.map(taskType => ({
      key: taskType.id,
      text: taskType.name,
    })).sort((a, b) =>
      // Sort by name
      a.text.toLowerCase().localeCompare(b.text.toLowerCase())
    );
  }, [allTaskTypes]);

  const [allPlaces, setAllPlaces] = React.useState<IPlace[]>([]);
  const placesOptions: IDropdownOption[] = React.useMemo(() => {
    return allPlaces.map(place => ({
      key: place.id,
      text: place.name,
    })).sort((a, b) =>
      // Sort by name
      a.text.toLowerCase().localeCompare(b.text.toLowerCase())
    );
  }, [allPlaces]);

  function fetchResidents(): void {
    getAllUsers().then(unpackResponse).then(
      data => {
        const residents = (data as IUser[]).filter(user => user.role === UserRole.RESIDENT);
        setAllResidents(residents);
      },
      err => createErrorPopup("Erreur lors du chargement des résidents", err.message),
    );
  }

  // Fetch and display the tasks for the current selectedResident
  // It's memoized because used in an effect.
  const fetchUserTasks: () => void = React.useCallback(() => {
    const userID = selectedResident?.id;
    if (userID === undefined) {
      setTaskList([]);
      return;
    }
    getUserTasks(userID, selectedDateRange.start, selectedDateRange.end).then(unpackResponse).then(
      data => setTaskList(data),
      err => createErrorPopup("Erreur lors du chargement des tâches", err.message),
    );
  }, [selectedResident, selectedDateRange.start, selectedDateRange.end]);

  const fetchUserRecurringTasks: () => void = React.useCallback(() => {
    const userID = selectedResident?.id;
    if (userID === undefined) {
      return;
    }
    getUserRecurringTasks(userID, selectedDateRange.start, selectedDateRange.end).then(unpackResponse).then(
        data => setRecurringTaskList(data),
        err => createErrorPopup("Erreur lors du chargement des tâches récurrentes", err.message),
    );
  }, [selectedResident, selectedDateRange.start, selectedDateRange.end]);

  const  fetchResidentAbsences: () => void = React.useCallback(() => {
    const userID = selectedResident?.id;
    if (userID === undefined) {
      setResidentAbsences([]);
      return;
    }
    getResidentAbsence(userID, selectedDateRange.start, selectedDateRange.end).then(unpackResponse).then(
        data => {
          const absences = data as IAbsence[];
          setResidentAbsences(absences);
        },
        err => createErrorPopup("Erreur lors du chargement des absences du résident", err.message),
    );
  }, [selectedResident, selectedDateRange.start, selectedDateRange.end]);

  function fetchTaskTypes(): void {
    getAllTaskTypes().then(unpackResponse).then(
      data => setAllTaskTypes(data),
      err => createErrorPopup("Erreur lors du chargement des types", err.message),
    );
  }

  function fetchPlaces(): void {
    getAllPlaces().then(unpackResponse).then(
      data => setAllPlaces(data),
      err => createErrorPopup("Erreur lors du chargement des lieux", err.message),
    );
  }

  const setDateRange: (start: string, end: string) => void = React.useCallback((start: string, end: string) => {
    setSelectedDateRange({
      start: start,
      end: end
    })
  }, [])

  const refreshTasks: () => void = React.useCallback(() => {
    fetchUserTasks();
    fetchUserRecurringTasks();
    fetchResidentAbsences();
  }, [fetchUserTasks, fetchUserRecurringTasks, fetchResidentAbsences])

  function sendPostTask(): void {
    const startDate = mergeDayAndTime(tmpTask.day, tmpTask.start);
    const endDate = mergeDayAndTime(tmpTask.day, tmpTask.end);
    const postData: ITaskPost = {
      user_id: tmpTask.user_id,
      type_id: tmpTask.type_id,
      place_id: tmpTask.place_id,
      start: startDate.toISOString().slice(0, -1), // Remove the trailing "Z"
      end: endDate.toISOString().slice(0, -1), // Idem
      timer: Number(tmpTask.timer),
    };

    createOneTask(postData).then(unpackResponse).then(
      _ => {refreshTasks();},
      err => createErrorPopup("Erreur lors de la création de la tâche", err.message),
    );
  }

  function sendPatchTask(): void {
    if (selectedTask === undefined) {
      createErrorPopup("Tâche invalide", "La tâche sélectionnée n'existe pas.");
      return;
    }
    const startDate = mergeDayAndTime(tmpTask.day, tmpTask.start);
    const endDate = mergeDayAndTime(tmpTask.day, tmpTask.end);
    const patchData: ITaskPatch = {
      type_id: tmpTask.type_id,
      place_id: tmpTask.place_id,
      start: startDate.toISOString().slice(0, -1), // Remove the trailing "Z"
      end: endDate.toISOString().slice(0, -1), // Idem
      timer: Number(tmpTask.timer),
    };
    modifyOneTask(selectedTask.id, patchData).then(unpackResponse).then(
      _ => {refreshTasks();},
      err => createErrorPopup("Erreur lors de la modification de la tâche", err.message),
    );
  }

  function sendDeleteTask(): void {
    if (selectedTask === undefined) {
      createErrorPopup("Tâche invalide", "La tâche sélectionnée n'existe pas.");
      return;
    }
    deleteOneTask(selectedTask.id).then(unpackResponse).then(
      _ => {refreshTasks();},
      err => createErrorPopup("Erreur lors de la suppression de la tâche", err.message),
    );
  }

  function sendPostRecurringTask(): void {
    // noinspection DuplicatedCode
    const startPeriod = tmpTask.start_period;
    const endPeriod = tmpTask.end_period;
    let postData: IRecurringTaskPost = {
      recurring_type: tmpTask.recurring_type?? "",
      start_period: startPeriod,
      end_period: endPeriod,
      start_task_time: tmpTask.start,
      end_task_time: tmpTask.end,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      user_id: tmpTask.user_id,
      type_id: tmpTask.type_id,
      place_id: tmpTask.place_id,
      timer: Number(tmpTask.timer),
      day_of_week: tmpTask.recurring_type === "daily" ? (tmpTask.day_of_week ?? undefined) : undefined,
    };

    createOneRecurringTask(postData).then(unpackResponse).then(
        _ => {refreshTasks();},
        err => createErrorPopup("Erreur lors de la création de la tâche récurrente", err.message),
    );
  }


  function sendPatchRecurringTask(): void {
    if (selectedTask === undefined) {
      createErrorPopup("Tâche invalide", "La tâche sélectionnée n'existe pas.");
      return;
    }
    // noinspection DuplicatedCode
    const startPeriod = tmpTask.start_period;
    const endPeriod = tmpTask.end_period;
    const postData: IRecurringTaskPost = {
      recurring_type: tmpTask.recurring_type?? "",
      start_period: startPeriod,
      end_period: endPeriod,
      start_task_time: tmpTask.start,
      end_task_time: tmpTask.end,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      user_id: tmpTask.user_id,
      type_id: tmpTask.type_id,
      place_id: tmpTask.place_id,
      timer: Number(tmpTask.timer),
      day_of_week: tmpTask.recurring_type === "daily" ? (tmpTask.day_of_week ?? undefined) : undefined,
    };

    modifyOneRecurringTask(selectedTask.recurring_task_id, postData).then(unpackResponse).then(
        _ => {refreshTasks();},
        err => createErrorPopup("Erreur lors de la création de la tâche récurrente", err.message),
    );
  }

  function sendDeleteRecurringTask(): void {
    if (selectedTask === undefined) {
      createErrorPopup("Tâche invalide", "La tâche sélectionnée n'existe pas.");
      return;
    }
    deleteOneRecurringTask(selectedTask.recurring_task_id).then(unpackResponse).then(
        _ => {refreshTasks();},
        err => createErrorPopup("Erreur lors de la suppression de la tâche récurrente", err.message),
    );
  }

  function sendPostAbsence(): void {
    const startDate = mergeDayAndTime(tmpTask.day, tmpTask.start);
    const endDate = mergeDayAndTime(tmpTask.day, tmpTask.end);
    const postData: IAbsencePost = {
      user_id: tmpTask.user_id,
      start: tmpTask.absence_all_day?
          moment(startDate).startOf('day').toISOString().slice(0, -1) :
          startDate.toISOString().slice(0, -1),
      end: tmpTask.absence_all_day?
          moment(endDate).endOf('day').toISOString().slice(0, -1) :
          endDate.toISOString().slice(0, -1), // Idem
      all_day: tmpTask.absence_all_day,
    };

    createAbsence(postData).then(unpackResponse).then(
        _ => {refreshTasks();},
        err => createErrorPopup("Erreur lors de la création de l'absence", err.message),
    );
  }

  function sendPatchAbsence(): void {
    if (selectedAbsence === undefined) {
      createErrorPopup("Absence invalide", "L'absence sélectionnée n'existe pas.");
      return;
    }
    const startDate = mergeDayAndTime(tmpTask.day, tmpTask.start);
    const endDate = mergeDayAndTime(tmpTask.day, tmpTask.end);
    const patchData: IAbsencePatch = {
      start: startDate.toISOString().slice(0, -1), // Remove the trailing "Z"
      end: endDate.toISOString().slice(0, -1), // Idem
      all_day: tmpTask.absence_all_day,
    };
    modifyAbsence(selectedAbsence.id, patchData).then(unpackResponse).then(
        _ => {refreshTasks();},
        err => createErrorPopup("Erreur lors de la modification de l'absence", err.message),
    );
  }

  function sendDeleteAbsence(): void {
    if (selectedAbsence === undefined) {
      createErrorPopup("Absence invalide", "L'absence sélectionnée n'existe pas.");
      return;
    }
    deleteAbsence(selectedAbsence.id).then(unpackResponse).then(
        _ => {refreshTasks();},
        err => createErrorPopup("Erreur lors de la suppression de l'absence", err.message),
    );
  }

  function selectTask(task: ITask, event: IEvent, residentId: number): void {
    setSelectedTask(task);

    if(task.recurring_task_id) {
      const recurringTask = recurringTaskList.find(t => t.id === task.recurring_task_id);
      if (recurringTask === undefined) {
        createErrorPopup("Tâche invalide", "La tâche sélectionnée n'existe plus.");
        return;
      }
      let tmp_day_of_week = recurringTask.day_of_week
      if(!tmp_day_of_week) {
        tmp_day_of_week = {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: true,
          sunday: true,
        }
      }

      setTmpTask({
        user_id: residentId,
        place_id: task.place.id,
        type_id: task.type.id,
        day: event.start,
        start: toHoursAndMinutes(event.start),
        end: toHoursAndMinutes(event.end),
        start_period: recurringTask.start_period,
        end_period: recurringTask.end_period,
        start_task_time: recurringTask.start_task_time,
        end_task_time: recurringTask.end_task_time,
        timer: String(recurringTask.timer),
        recurring_task: true,
        recurring_type: recurringTask.recurring_type,
        day_of_week: tmp_day_of_week,
        absence: false,
        absence_all_day: true,
      });
      setSeriesSelected("instance");
    }
    else {
      setTmpTask({
        user_id: residentId,
        place_id: task.place.id,
        type_id: task.type.id,
        day: event.start,
        start: toHoursAndMinutes(event.start),
        end: toHoursAndMinutes(event.end),
        start_period: "",
        end_period: "",
        start_task_time: "",
        end_task_time: "",
        timer: String(task.timer),
        recurring_task: false,
        recurring_type: "daily",
        absence: false,
        absence_all_day: true,
      });
    }
  }

  function selectAbsence(absence: IAbsence, event: IEvent, residentId: number): void {
    setSelectedAbsence(absence);

    setTmpTask({
      user_id: residentId,
      place_id: -1,
      type_id: -1,
      day: event.start,
      start: toHoursAndMinutes(event.start),
      end: toHoursAndMinutes(event.end),
      start_period: "",
      end_period: "",
      start_task_time: "",
      end_task_time: "",
      timer: "0",
      recurring_task: false,
      recurring_type: "daily",
      absence: true,
      absence_all_day: absence.all_day,
    });
  }

  function onEventSelect(event: IEvent) {
    if(event.id < 0) {
      // Absence selected
      const absence = residentAbsences.find(t => t.id === -event.id);
      if (absence === undefined) {
        createErrorPopup("Absence invalide", "L'absence sélectionnée n'existe plus.");
        return;
      }
      if (selectedResident === undefined) {
        createErrorPopup("Utilisateur invalide", "Aucun utilisateur n'a été sélectionné.");
        return;
      }
      selectAbsence(absence, event, selectedResident.id)
    }
    else {
      const task = taskList.find(t => t.id === event.id);
      if (task === undefined) {
        createErrorPopup("Tâche invalide", "La tâche sélectionnée n'existe plus.");
        return;
      }
      if (selectedResident === undefined) {
        createErrorPopup("Utilisateur invalide", "Aucun utilisateur n'a été sélectionné.");
        return;
      }
      selectTask(task, event, selectedResident.id)
    }

    setActionMode(ActionMode.EDIT);
    setIsTaskDialogHidden(false);
  }

  function onSlotSelect(slot: SlotInfo): void {
    if (selectedResident === undefined) {
      createErrorPopup("Utilisateur invalide", "Aucun utilisateur n'a été sélectionné.");
      return;
    }
    const start = toHoursAndMinutes(slot.start as Date);
    const end = toHoursAndMinutes(slot.end as Date);
    setTmpTask({
      ...DEFAULT_TMP_TASK,
      user_id: selectedResident.id,
      day: slot.start as Date,
      start: start,
      end: end,
    });
    setActionMode(ActionMode.ADD);
    setIsTaskDialogHidden(false);
  }

  function processTaskOrRecurringTaskOrAbsence(): void {
    if(tmpTask.recurring_task && (actionMode === ActionMode.ADD || (seriesSelected === "series" && (actionMode === ActionMode.EDIT || actionMode === ActionMode.REMOVE)))){
      processRecurringTask();
    }
    else if(tmpTask.absence) {
      processAbsence();
    }
    else {
      processTask();
    }
    setSelectedTask(undefined)
  }

  function processTask(): void {
    setIsTaskDialogHidden(true);

    if (actionMode === ActionMode.ADD) {
      sendPostTask();
    } else if (actionMode === ActionMode.REMOVE) {
      sendDeleteTask();
    } else if (actionMode === ActionMode.EDIT) {
      sendPatchTask();
    }
  }

  function processRecurringTask(): void {
    setIsTaskDialogHidden(true);

    if (actionMode === ActionMode.ADD) {
      sendPostRecurringTask();
    } else if (actionMode === ActionMode.REMOVE) {
      sendDeleteRecurringTask();
    } else if (actionMode === ActionMode.EDIT) {
      sendPatchRecurringTask();
    }
  }

  function processAbsence(): void {
    setIsTaskDialogHidden(true);

    if (actionMode === ActionMode.ADD) {
      sendPostAbsence();
    } else if (actionMode === ActionMode.REMOVE) {
      sendDeleteAbsence();
    } else if (actionMode === ActionMode.EDIT) {
      sendPatchAbsence();
    }
  }

  // Fetch the residents, task types and places on component creation
  React.useEffect(() => {
    fetchResidents();
    fetchTaskTypes();
    fetchPlaces();
  }, []);

  // Fetch the tasks to be displayed when the selected user changes
  React.useEffect(() => {
    refreshTasks()
  }, [refreshTasks, selectedDateRange.start, selectedDateRange.end])

  return (
    <>
      <Dropdown
        label={"Résident"}
        placeholder={"Sélectionner un résident pour afficher son planning"}
        options={residentOptions}
        selectedKey={selectedResident?.id}
        onChange={(ev, val) => setSelectedResident(allResidents.find(r => r.id === val?.key))}
        styles={{root: {marginBottom: 10}}}
      />
      {selectedResident !== undefined && (
        <TaskCalendar
          events={displayedEvents}
          onEventSelect={onEventSelect}
          onSlotSelect={onSlotSelect}
          refreshEvents={setDateRange}
        />
      )}

      <GenericActionDialog
        isHidden={isTaskDialogHidden || actionMode === ActionMode.REMOVE}
        hide={() => {
          setIsTaskDialogHidden(true);
          setSelectedTask(undefined);
        }}
        actionMode={actionMode}
        onSubmit={processTaskOrRecurringTaskOrAbsence}
        genericName={tmpTask.absence ? "une absence" : "une tâche"}
        errorText={validationError}
        displayRemove={true}
        onRemovePress={() => setActionMode(ActionMode.REMOVE)}
      >
        <Toggle label={"Absence"}
                checked={tmpTask.absence}
                disabled={actionMode === ActionMode.EDIT}
                onChange={(ev, val) => setTmpTask({...tmpTask, absence: val ?? false})}
        />
        {!tmpTask.absence && <>
          <Dropdown
            label={"Type"}
            options={taskTypesOptions}
            selectedKey={tmpTask.type_id}
            onChange={(ev, opt) => setTmpTask({...tmpTask, type_id: opt?.key as number ?? 0})}
          />
          <Dropdown
            label={"Lieu"}
            options={placesOptions}
            selectedKey={tmpTask.place_id}
            onChange={(ev, opt) => setTmpTask({...tmpTask, place_id: opt?.key as number ?? 0})}
          />
          </>}
        {(tmpTask.absence || !tmpTask.recurring_task || seriesSelected === "instance") && <DatePicker
          label={tmpTask.absence ? "Date de l'absence" : "Date de la tâche"}
          firstDayOfWeek={DayOfWeek.Monday}
          placeholder="Choisissez une date..."
          ariaLabel="Choisissez une date"
          strings={frDatePickerString}
          formatDate={onFormatDate}
          value={tmpTask.day}
          disabled={!tmpTask.absence && tmpTask.recurring_task && (seriesSelected === "series" || actionMode === ActionMode.ADD)}
          onSelectDate={(date: Date | null | undefined) => {
            if(date) {
              setTmpTask({
                    ...tmpTask,
                    day: date
                  }
              )
            }
          }}
        />}
        {tmpTask.absence && <Toggle label={"Toute la journée"}
                                    checked={tmpTask.absence_all_day}
                                    onChange={(ev, val) => setTmpTask({...tmpTask, absence_all_day: val ?? false})}
        />}
        {!tmpTask.absence && tmpTask.recurring_task && <><DatePicker
            label={"Date de début de la récurrence"}
            firstDayOfWeek={DayOfWeek.Monday}
            placeholder="Choisissez une date de début..."
            ariaLabel="Choisissez une date"
            strings={frDatePickerString}
            formatDate={onFormatDate}
            disabled={seriesSelected === "instance" && actionMode !== ActionMode.ADD}
            value={stringAsDate(tmpTask.start_period)}
            maxDate={stringAsDate(tmpTask.end_period)}
            onSelectDate={(date: Date | null | undefined) => {
              if(date) {
                setTmpTask({
                      ...tmpTask,
                      start_period: dateAsString(date)
                    }
                )
              }
            }}
          />
          <DatePicker
              firstDayOfWeek={DayOfWeek.Monday}
              label={"Date de fin de la récurrence"}
              placeholder="Choisissez une date de fin..."
              ariaLabel="Choisissez une date"
              strings={frDatePickerString}
              formatDate={onFormatDate}
              disabled={seriesSelected === "instance" && actionMode !== ActionMode.ADD}
              value={stringAsDate(tmpTask.end_period)}
              minDate={stringAsDate(tmpTask.start_period)}
              onSelectDate={(date: Date | null | undefined) => {
                if(date) {
                  setTmpTask({
                        ...tmpTask,
                        end_period: dateAsString(date)
                      }
                  )
                }
              }}
          />
        </>}
        {(!tmpTask.absence || (tmpTask.absence && !tmpTask.absence_all_day)) && <><TextField
          label={"Heure de début"}
          value={tmpTask.start}
          onChange={(ev, val) => setTmpTask({...tmpTask, start: val ?? ""})}
        />
        <TextField
          label={"Heure de fin"}
          value={tmpTask.end}
          onChange={(ev, val) => setTmpTask({...tmpTask, end: val ?? ""})}
        /></>}
        {!tmpTask.absence && <TextField
          label={"Durée du décompte (minutes)"}
          value={tmpTask.timer}
          onChange={(ev, val) => setTmpTask({...tmpTask, timer: val ?? ""})}
        />}
        {!tmpTask.absence && <Toggle label={"Evénement récurrent"}
                checked={tmpTask.recurring_task}
                disabled={actionMode === ActionMode.EDIT || (seriesSelected !== "series" && !(actionMode === ActionMode.ADD))}
                onText="Activé"
                offText="Désactivé"
                onChange={(ev, val) => setTmpTask({...tmpTask, recurring_task: val ?? false})}
        />}

        {!tmpTask.absence && tmpTask.recurring_task && <Dropdown
            label={"Type de récurrence"}
            placeholder={"Sélectionner un type de récurrence"}
            options={recurring_type_list}
            disabled={seriesSelected !== "series" && !(actionMode === ActionMode.ADD)}
            selectedKey={tmpTask.recurring_type}
            onChange={(ev, val) => setTmpTask({...tmpTask, recurring_type: val?.key as string ?? ""})}
            styles={{root: {marginBottom: 10}}}
        />}
        {!tmpTask.absence && tmpTask.recurring_task && tmpTask.recurring_type === "daily" &&
            <Stack>
              <Toggle label={"Lundi"}
                      inlineLabel
                      checked={tmpTask.day_of_week?.monday}
                      disabled={seriesSelected !== "series" && !(actionMode === ActionMode.ADD)}
                      onChange={(ev, val) => setTmpTask({
                        ...tmpTask,
                        day_of_week: {
                          ...tmpTask.day_of_week,
                          monday: val ?? true
                      }})} />
              <Toggle label={"Mardi"}
                      inlineLabel
                      checked={tmpTask.day_of_week?.tuesday}
                      disabled={seriesSelected !== "series" && !(actionMode === ActionMode.ADD)}
                      onChange={(ev, val) => setTmpTask({
                        ...tmpTask,
                        day_of_week: {
                          ...tmpTask.day_of_week,
                          tuesday: val ?? true
                        }})} />
              <Toggle label={"Mercredi"}
                      inlineLabel
                      checked={tmpTask.day_of_week?.wednesday}
                      disabled={seriesSelected !== "series" && !(actionMode === ActionMode.ADD)}
                      onChange={(ev, val) => setTmpTask({
                        ...tmpTask,
                        day_of_week: {
                          ...tmpTask.day_of_week,
                          wednesday: val ?? true
                        }})} />
              <Toggle label={"Jeudi"}
                      inlineLabel
                      checked={tmpTask.day_of_week?.thursday}
                      disabled={seriesSelected !== "series" && !(actionMode === ActionMode.ADD)}
                      onChange={(ev, val) => setTmpTask({
                        ...tmpTask,
                        day_of_week: {
                          ...tmpTask.day_of_week,
                          thursday: val ?? true
                        }})} />
              <Toggle label={"Vendredi"}
                      inlineLabel
                      checked={tmpTask.day_of_week?.friday}
                      disabled={seriesSelected !== "series" && !(actionMode === ActionMode.ADD)}
                      onChange={(ev, val) => setTmpTask({
                        ...tmpTask,
                        day_of_week: {
                          ...tmpTask.day_of_week,
                          friday: val ?? true
                        }})} />
              <Toggle label={"Samedi"}
                      inlineLabel
                      checked={tmpTask.day_of_week?.saturday}
                      disabled={seriesSelected !== "series" && !(actionMode === ActionMode.ADD)}
                      onChange={(ev, val) => setTmpTask({
                        ...tmpTask,
                        day_of_week: {
                          ...tmpTask.day_of_week,
                          saturday: val ?? true
                        }})} />
              <Toggle label={"Dimanche"}
                      inlineLabel
                      checked={tmpTask.day_of_week?.sunday}
                      disabled={seriesSelected !== "series" && !(actionMode === ActionMode.ADD)}
                      onChange={(ev, val) => setTmpTask({
                        ...tmpTask,
                        day_of_week: {
                          ...tmpTask.day_of_week,
                          sunday: val ?? true
                        }})} />
            </Stack>
        }
        {selectedTask?.recurring_task_id && <Dropdown
          label={"Appliquer la modification ou la suppression à"}
          placeholder={"Sélectionner l'occurence ou toute la série"}
          options={series_or_instance_selection}
          selectedKey={seriesSelected}
          onChange={(ev, val) => setSeriesSelected(val?.key as string ?? "")}
          styles={{root: {marginBottom: 10}}}
          />}

      </GenericActionDialog>
        <GenericRemoveDialog
          isHidden={isTaskDialogHidden || actionMode !== ActionMode.REMOVE}
          hide={() => setIsTaskDialogHidden(true)}
          title={"Supprimer une " + (tmpTask.absence ? "absence" : "tâche")}
          onSubmit={processTaskOrRecurringTaskOrAbsence}
        >
          {!tmpTask.absence && tmpTask.recurring_task && seriesSelected !== "series" && <Text>Voulez-vous supprimer toute la série de tâches ?</Text>}
          {!tmpTask.absence && !(tmpTask.recurring_task && seriesSelected !== "series") && <Text>Voulez-vous supprimer la tâche ?</Text>}
          {tmpTask.absence && <Text>Voulez-vous supprimer l'absence ?</Text>}
        </GenericRemoveDialog>
    </>
  );
};
