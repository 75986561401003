import { Pivot, PivotItem } from "@fluentui/react";
import React from "react";

import { TagsTab } from "./TagsTab";
import { ResourcesTab } from "./ResourcesTab";


export const ScanWallsConfig: React.FC = () => {
  return (
    <Pivot>
      <PivotItem headerText={"Tags"} itemIcon={"Tag"}>
        <TagsTab />
      </PivotItem>
      <PivotItem headerText={"Ressources"} itemIcon={"PageList"}>
        <ResourcesTab/>
      </PivotItem>
    </Pivot>
  );
};
