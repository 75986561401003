import {
  Stack,
  Text,
} from "@fluentui/react";
import React from "react";

export const NFC: React.FC = () => {

  return (
    <>
      <Text variant={"large"}>Gestion des tags NFC</Text>
      <Stack>
      </Stack>
    </>
  );

};
