import { IColumn, Selection, Text, TextField } from "@fluentui/react";
import React from "react";
import { createOnePlace, deleteOnePlace, getAllPlaces, modifyOnePlace } from "../../api/actions";

import { IPlace, IPlacePatch, IPlacePost, ITmpPlace, validatePlace } from "../../api/types";
import { GenericActionBar } from "../../components/genericconfig/GenericActionBar";
import { GenericActionDialog, GenericRemoveDialog } from "../../components/genericconfig/GenericActionDialog";
import { GenericTable } from "../../components/genericconfig/GenericTable";
import { ActionMode } from "../../components/genericconfig/types";
import { ImageSelector } from "../../components/ImageSelector";
import { createErrorPopup } from "../../helpers/errors";
import { unpackResponse } from "../../helpers/requests";


const DEFAULT_TMP_PLACE: ITmpPlace = {name: "", image_url: ""};
const tableColumns: IColumn[] = [
  {key: "id", name: "ID", fieldName: "id", minWidth: 20, maxWidth: 40},
  {key: "name", name: "Nom", fieldName: "name", minWidth: 100},
  {key: "image_url", name: "URL de l'image", fieldName: "image_url", minWidth: 300},
];

export const PlacesTab: React.FC = () => {
  const [allPlaces, setAllPlaces] = React.useState<IPlace[]>([]);
  const [selected, setSelected] = React.useState<IPlace>();
  const [actionMode, setActionMode] = React.useState(ActionMode.ADD);
  const [isActionDialogHidden, setIsActionDialogHidden] = React.useState(true);
  const [tmpPlace, setTmpPlace] = React.useState(DEFAULT_TMP_PLACE);
  const validationError = validatePlace(tmpPlace);

  const selection = React.useMemo(() => {
    return new Selection({
      onSelectionChanged: () => {
        const allSelected: IPlace[] = selection.getSelection() as IPlace[];
        const firstSelected = allSelected.length !== 0 ? allSelected[0] : undefined;
        setSelected(firstSelected);
      },
    });
  }, []);

  function fetchPlaces(): void {
    getAllPlaces().then(unpackResponse).then(
      data => {
        setAllPlaces(data);
      },
      err => {
        createErrorPopup("Erreur lors du chargement", err.message);
      },
    );
  }

  function sendPostPlace(): void {
    const postData: IPlacePost = {name: tmpPlace.name, image_url: tmpPlace.image_url || null};
    createOnePlace(postData).then(unpackResponse).then(
      _ => {
        fetchPlaces();
      },
      err => {
        createErrorPopup("Erreur lors de la création", err.message);
      },
    );
  }

  function sendDeletePlace(): void {
    if (selected === undefined) {
      console.warn("Can't delete when no place is selected.");
      return;
    }
    deleteOnePlace(selected.id).then(unpackResponse).then(
      _ => {
        fetchPlaces();
      },
      err => {
        createErrorPopup("Erreur lors de la suppression", err.message);
      },
    );
  }

  function sendPatchPlace(): void {
    if (selected === undefined) {
      console.warn("Can't edit when no place is selected.");
      return;
    }
    const patchData: IPlacePatch = {name: tmpPlace.name, image_url: tmpPlace.image_url || null};
    modifyOnePlace(selected.id, patchData).then(unpackResponse).then(
      _ => {
        fetchPlaces();
      },
      err => {
        createErrorPopup("Erreur lors de la modification", err.message);
      },
    );
  }

  function onActionDialogSubmit(): void {
    setIsActionDialogHidden(true);
    if (actionMode === ActionMode.ADD) {
      sendPostPlace();
    } else if (actionMode === ActionMode.REMOVE) {
      sendDeletePlace();
    } else if (actionMode === ActionMode.EDIT) {
      sendPatchPlace();
    }
  }

  React.useEffect(() => {
    fetchPlaces();
  }, []);

  return (
    <>
      <GenericTable items={allPlaces} columns={tableColumns} selection={selection} />
      <GenericActionBar
        disableRemoveEdit={selected === undefined}
        setActionMode={setActionMode}
        displayModal={() => setIsActionDialogHidden(false)}
        emptyTmp={() => setTmpPlace(DEFAULT_TMP_PLACE)}
        fillTmp={() => setTmpPlace({
          name: selected?.name ?? DEFAULT_TMP_PLACE.name,
          image_url: selected?.image_url ?? DEFAULT_TMP_PLACE.image_url,
        })}
      />
      <GenericRemoveDialog
        isHidden={isActionDialogHidden || actionMode !== ActionMode.REMOVE}
        hide={() => setIsActionDialogHidden(true)}
        title={"Supprimer un lieu"}
        onSubmit={onActionDialogSubmit}
      >
        <Text>
          {`Voulez-vous supprimer le lieu #${selected?.id} : `}
          <b>{selected?.name}</b> ?<br />
          Toutes les tâches associées seront également supprimées.
        </Text>
      </GenericRemoveDialog>
      <GenericActionDialog
        isHidden={isActionDialogHidden || actionMode === ActionMode.REMOVE}
        hide={() => setIsActionDialogHidden(true)}
        actionMode={actionMode}
        onSubmit={onActionDialogSubmit}
        genericName={"un lieu"}
        errorText={validationError}
      >
        <TextField
          label={"Nom"}
          value={tmpPlace.name}
          onChange={(ev, val) => setTmpPlace({...tmpPlace, name: val ?? ""})}
        />
        <ImageSelector
          category={"place"}
          value={tmpPlace.image_url}
          onChange={(url) => setTmpPlace({...tmpPlace, image_url: url})}
        />
      </GenericActionDialog>
    </>
  );
};
