import { Icon, INavLink, INavLinkGroup, Nav, Stack } from "@fluentui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../slices";

const urlTinyMDM = "https://www.tinymdm.fr/console/?locale=fr#login:default";

export const NavBar: React.FC = () => {
  const navigate = useNavigate();
  const isAdmin = useSelector((state: RootState) => state.user.isAdmin);
  const realmAdministrationUrl = useSelector((state: RootState) => state.config.realmAdministrationUrl);

  const applicationGroup = {
    name: "Applications",
    expandAriaLabel: "AppIconDefaultList",
    links: [
      {name: "Profils et contrôles", url: "/actions", icon: "AddToShoppingList"},
      {name: "Je suis perdu", url: "/iamlost", icon: "MapPinSolid"},
      {name: "Localisation", url: "/loc", icon: "ScaleVolume"},
      {name: "Plannings", url: "/plannings", icon: "CalendarSettings"},
      {name: "Écran accueil", url: "/welcome-screen", icon: "TVMonitor"},
      {name: "Murs de services", url: "/scanwalls", icon: "AnalyticsQuery"},
      {name: "Stats applications", url: "/apps", icon: "BarChartVerticalFill"},
    ]
  }

  const adminGroup = {
    name: "Administration",
    expandAriaLabel: "Settings",
    links: [
      {name: "Utilisateurs", url: "/users", icon: "PlayerSettings"},
      {name: "Beacons", url: "/beacons", icon: "NetworkTower"},
      {name: "Équipements", url: urlTinyMDM, icon: "CellPhone", ariaLabel: "redirect", target: "_blank"},
      {name: "Keycloak", url: realmAdministrationUrl, icon: "FabricUserFolder", ariaLabel: "redirect", target: "_blank"},
    ]
  }

  const groups: INavLinkGroup[] = isAdmin ? [applicationGroup, adminGroup]:[applicationGroup];

  function _onRenderLink(link: INavLink) {
    return <Stack horizontal horizontalAlign="space-between" tokens={{childrenGap: '10'}}>
      <span><Icon iconName={link.title} /></span>
      <span style={{fontSize: '0.8vw'}}>{link.name}</span>
      {
        link.ariaLabel === "redirect" &&
        <span style={{fontSize: '1vw', right: 0}}>
          <Icon iconName="NavigateExternalInline" />
        </span>
      }
    </Stack>;
  }

  function _onRenderGroupHeader(groups: INavLinkGroup): JSX.Element {
    return <div>
      <h1 style={{marginLeft: 10, marginBottom: 5, marginTop: 15, color: "#323130", fontSize: '1vw'}}>
        <Icon iconName={groups.expandAriaLabel} style={{marginRight: 10}} />{groups.name}
      </h1>
    </div>
  }

  return (
    <Nav
      groups={groups}
      onRenderLink={(i) => {if (i) return _onRenderLink(i); else return <div />}}
      onRenderGroupHeader={(i) => {if (i) return _onRenderGroupHeader(i); else return <div />}}
      onLinkClick={(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
        if (item !== undefined) {
          if (item.ariaLabel !== "redirect") {
            navigate(item.url);
            ev?.preventDefault();
          }
        }
      }}
      styles={{root: {width: '12vw'}, groupContent: {marginBottom: 0}}}
    />
  );
};
