import { Text } from "@fluentui/react";
import React from "react";

import { getVersion } from "../api/actions";

// Do not edit - automatically set to correct version during image build.
const FRONT_VERSION = "1.1.0";

const VersionInfo: React.FunctionComponent = () => {
  const [apiVersion, setApiVersion] = React.useState("");

  React.useEffect(() => {
    getVersion()
      .then(res => res.json())
      .then(json => {
        setApiVersion(json.api_version);
      });
  }, []);

  return (
    <Text variant="medium" styles={{root: {textAlign: "center"}}}>
      Front version: {FRONT_VERSION}<br />Back version: {apiVersion}
    </Text>
  );
};

export default VersionInfo;
