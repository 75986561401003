import {
  DetailsList,
  DetailsRow,
  FontIcon,
  IColumn,
  IDetailsRowProps,
  IDetailsRowStyles,
  Image,
  ImageFit,
  IRenderFunction,
  mergeStyles,
  Pivot,
  PivotItem,
  Selection,
  SelectionMode,
  Separator,
  Stack,
  StackItem,
  Text
} from "@fluentui/react";


import moment from 'moment';
import React, { useEffect, useState } from "react";
import {
  getAllAssetBeacons,
  getAllBeaconPlaces,
  getAllBeaconScannerEvents,
  getAllBeaconScanners,
  getAllUsers
} from '../api/actions';
import { IAssetBeacon, IBeaconScanner, IRoom, IScannerEvent, IUser, } from '../api/types';
import { handleError } from "../helpers/requests";


export const Location: React.FC = () => {

  return (
    <Stack>
      <Pivot>
        <PivotItem headerText="Dernière localisation" itemIcon="POISolid"><ScannerEventsByUsers /></PivotItem>
        <PivotItem headerText="Présence sur site" itemIcon="HomeVerify"><ScannerEventsByAreas /></PivotItem>
        {/* <PivotItem headerText="Test" itemIcon="Add"><ScannerEventsTest/></PivotItem> */}
      </Pivot>
    </Stack>
  );
};


// const roomData: IRoom[] = [
//     {id: 1, label: "Rez de chaussée", image_id: "FloorZero"},
//     {id: 2, label: "Première étage", image_id: "FloorOne"},
//     {id: 3, label: "Chambre 115", image_id: "Room115"},
//     {id: 4, label: "Chambre 118", image_id: "Room118"},
//     {id: 5, label: "Local technique, RDC", image_id: "TechnicalCabinet00"},
//     {id: 6, label: "Local technique, premier étage", image_id: "TechnicalCabinet01"},
//     {id: 7, label: "Salle à manger", image_id: "LivingRoom01"},
//     {id: 8, label: "Salle de réunion", image_id: "MeetingRoom00"},
// ]

// const userData: IUser[] = [
//     {id: 3, username: "", firstname: "Ada", lastname: "Lovelace", role: UserRole.RESIDENT},
//     {id: 4, username: "", firstname: "Alan", lastname: "Turing", role: UserRole.RESIDENT},
//     {id: 5, username: "", firstname: "Jean", lastname: "Ichbiah", role: UserRole.RESIDENT},
//     {id: 6, username: "", firstname: "Marvin", lastname: "Minsky", role: UserRole.RESIDENT},
//     {id: 7, username: "", firstname: "Edsger W.", lastname: "Dijkstra", role: UserRole.RESIDENT},
//     {id: 8, username: "", firstname: "Donald", lastname: "Knuth", role: UserRole.RESIDENT},
//     {id: 9, username: "", firstname: "Tony", lastname: "Hoare", role: UserRole.RESIDENT},
//     {id: 10, username: "", firstname: "Robin", lastname: "Milner", role: UserRole.RESIDENT},
//     {id: 11, username: "", firstname: "Leslie", lastname: "Valiant", role: UserRole.RESIDENT},
// ]

// const assetBeaconData: IAssetBeacon[] = [
//     { id: 1, user_id: 3, application_id: "50424443-4b2d-4245-4143-4f4e2d415051", major: 1, minor: 1 },
//     { id: 2, user_id: 4, application_id: "50424443-4b2d-4245-4143-4f4e2d415051", major: 1, minor: 2 },
//     { id: 3, user_id: 5, application_id: "50424443-4b2d-4245-4143-4f4e2d415051", major: 1, minor: 3 },
//     { id: 4, user_id: 6, application_id: "50424443-4b2d-4245-4143-4f4e2d415051", major: 2, minor: 1 },
//     { id: 5, user_id: 7, application_id: "50424443-4b2d-4245-4143-4f4e2d415051", major: 2, minor: 2 },
//     { id: 6, user_id: 8, application_id: "50424443-4b2d-4245-4143-4f4e2d415051", major: 2, minor: 3 },
//     { id: 7, user_id: 9, application_id: "50424443-4b2d-4245-4143-4f4e2d415051", major: 2, minor: 4 },
//     { id: 8, user_id: 10, application_id: "50424443-4b2d-4245-4143-4f4e2d415051", major: 2, minor: 5 },
//     { id: 9, user_id: 11, application_id: "50424443-4b2d-4245-4143-4f4e2d415051", major: 2, minor: 6 },
// ]

// const scannerData: IBeaconScanner[] = [
//     {id: 1, mac_id: "010203040506", room_id: 4},
//     {id: 2, mac_id: "010203040507", room_id: 6},
//     {id: 3, mac_id: "010203040508", room_id: 8},
// ]

// const scannerEventData: IScannerEvent[] = [
//     { id: 1, scanner_id: 1, beacon_id: 1 , timestamp: moment().add(-1, 'minutes').toISOString() },
//     { id: 2, scanner_id: 1, beacon_id: 2 , timestamp: moment().add(-1, 'minutes').toISOString() },
//     { id: 3, scanner_id: 1, beacon_id: 3 , timestamp: moment().add(-1, 'minutes').toISOString() },
//     { id: 4, scanner_id: 1, beacon_id: 4 , timestamp: moment().add(-1, 'minutes').toISOString() },
//     { id: 5, scanner_id: 2, beacon_id: 5 , timestamp: moment().add(-1, 'minutes').toISOString() },
//     { id: 6, scanner_id: 2, beacon_id: 6 , timestamp: moment().add(-100, 'minutes').toISOString() },
//     { id: 7, scanner_id: 2, beacon_id: 7 , timestamp: moment().add(-100, 'minutes').toISOString() },
//     { id: 8, scanner_id: 3, beacon_id: 8 , timestamp: moment().add(-2, 'minutes').toISOString() },
//     { id: 9, scanner_id: 3, beacon_id: 9 , timestamp: moment().add(-5, 'minutes').toISOString() },
//     { id: 10, scanner_id: 2, beacon_id: 1 , timestamp: moment().add(-1, 'minutes').toISOString() },
//     { id: 11, scanner_id: 2, beacon_id: 2 , timestamp: moment().add(-1, 'minutes').toISOString() },
// ]

const ScannerEventsByUsers: React.FC = () => {

  interface _IScannerEvent {
    id: number
    user?: IUser,
    room?: IRoom,
    timestamp: string,

  }

  const outTimeoutMinutes = 5;
  const defaultImageId = "FloorOne";
  const [, setIsLoading] = useState(true);
  const [, setSelectedEvent] = useState<_IScannerEvent>();
  const [selectedImage, setSelectedImage] = useState<IRoom>();
  const [items, setItems] = useState<_IScannerEvent[]>([]);

  function imagePath(room: IRoom | undefined) {
    if (room)
      return `beacons/scanner/${room.image_id}.png`;
    else
      return `beacons/scanner/${defaultImageId}.png`;
  }

  function userLabel(user: IUser | undefined) {
    return user ? `${user.firstname} ${user.lastname}` : "";
  }

  function roomLabel(room: IRoom | undefined) {
    return room ? `${room.label}` : "";
  }

  function timeLabel(ts: string | undefined) {
    return moment.utc(ts).local().fromNow();
  }

  const listStyle = mergeStyles({
    flex: "1 1 auto",
    overflowX: "hidden",
    overflowY: "auto",
    //maxHeight: '100%',
    height: 500
  });

  const columns: IColumn[] = React.useMemo(() => [
    {
      key: "id",
      name: "#",
      fieldName: "id",
      minWidth: 25,
      maxWidth: 25,
    }, {
      key: "user",
      name: "résident",
      minWidth: 150,
      maxWidth: 150,
      onRender: (item: _IScannerEvent) => (<Text>{userLabel(item.user)}</Text>)
    },
    {
      key: "room",
      name: "Lieu",
      minWidth: 200,
      maxWidth: 200,
      onRender: (item: _IScannerEvent) => (<Text>{roomLabel(item.room)}</Text>)
    },
    {
      key: "timestamp",
      name: "Heure",
      minWidth: 100,
      maxWidth: 100,
      onRender: (item: _IScannerEvent) => (<Text>{timeLabel(item.timestamp)}</Text>)
    }
  ], []);

  const onRenderRow: IRenderFunction<IDetailsRowProps> = (rp) => {

    const customStyles: Partial<IDetailsRowStyles> = {};
    if (rp) {
      const se = rp.item as _IScannerEvent;

      if (se) {
        const now = moment();
        if (now.diff(moment.utc(se.timestamp).local(), 'minutes') < outTimeoutMinutes)
          customStyles.root = {backgroundColor: '#41F50C42'};
      }
      return <DetailsRow {...rp} styles={customStyles} />;
    }
    return null;
  };


  const selection = new Selection({
    onSelectionChanged: () => {
      const se = selection.count ? selection.getSelection()[0] as _IScannerEvent : undefined;
      setSelectedEvent(se);
      if (se) {
        setSelectedImage(se.room);
      }
    }
  });

  async function fetchData() {

    setIsLoading(true);

    try {
      const resUsers = await getAllUsers();
      const resBeacons = await getAllAssetBeacons();
      const resScanners = await getAllBeaconScanners();
      const resPlaces = await getAllBeaconPlaces();
      const resEvents = await getAllBeaconScannerEvents();

      if (resUsers.ok && resBeacons.ok && resScanners.ok && resPlaces.ok && resEvents.ok) {
        const users: IUser[] = await resUsers.json();
        const beacons: IAssetBeacon[] = await resBeacons.json();
        const scanners: IBeaconScanner[] = await resScanners.json();
        const places: IRoom[] = await resPlaces.json();
        const events: IScannerEvent[] = await resEvents.json();


        const now = moment();
        setItems(
          events.map(
            e => {
              const room = places.find(r => r.id === scanners.find(s => s.id === e.scanner_id)?.room_id);
              const user = users.find(u => u.id === beacons.find(b => b.id === e.beacon_id)?.user_id);
              return {
                id: e.id,
                user: user,
                room: room,
                timestamp: e.timestamp,
                out: now.diff(moment.utc(e.timestamp).local(), 'minutes') > outTimeoutMinutes
              };
            }
          ).sort((a, b) => (a.user?.lastname ?? "").localeCompare(b.user?.lastname ?? ""))
        );
        setSelectedImage(places.find(x => x.image_id === defaultImageId));
      }
    } catch (error) {
      handleError(Error("fetchData error: " + error));
    } finally {
      setIsLoading(false);
    }

  }

  useEffect(() => {

    const timer = setInterval(() => {
      fetchData();
    }, 1000 * 10);

    fetchData();
    return () => clearInterval(timer);

  }, []);

  return (
    <Stack tokens={{childrenGap: 20}} style={{height: '80%'}}>

      <Stack tokens={{childrenGap: 20}}>
        <StackItem align="center" style={{paddingBottom: 30}}>
          <Text variant={"large"} style={{textDecorationLine: 'underline'}}>Dernières localisations
            connues</Text>
        </StackItem>
        <Stack horizontal tokens={{childrenGap: 20}} verticalAlign="center">
          <StackItem grow={2} align="center" style={{width: 300}}>
            <Stack tokens={{childrenGap: 20}} style={{overflowY: "auto"}}>
              <div className={listStyle} data-is-scrollable="true">
                <DetailsList
                  items={items}
                  columns={columns}
                  selectionMode={SelectionMode.single}
                  selection={selection}
                  onRenderRow={onRenderRow}
                />
              </div>
            </Stack>
          </StackItem>
          <Separator vertical />
          <StackItem grow={1} align="center">
            <Stack horizontal>
              <Image
                src={imagePath(selectedImage)}
                height={300}
                {...{imageFit: ImageFit.contain}} />
            </Stack>
          </StackItem>
        </Stack>
      </Stack>
    </Stack>
  );

};


const ScannerEventsByAreas: React.FC = () => {

  interface _IScannerEvent {
    id: number
    user?: IUser,
    room?: IRoom,
    timestamp: string,
    out: boolean
  }


  const outTimeoutMinutes = 5;
  const [, setIsLoading] = useState(true);
  const [items, setItems] = useState<_IScannerEvent[]>([]);


  function userLabel(user: IUser | undefined) {
    return user ? `${user.firstname} ${user.lastname}` : "";
  }

  function roomLabel(room: IRoom | undefined) {
    return room ? `${room.label}` : "";
  }

  function timeLabel(ts: string | undefined) {
    return moment.utc(ts).local().fromNow();
  }

  const listStyle = mergeStyles({
    flex: "1 1 auto",
    overflowX: "hidden",
    overflowY: "visible",
    height: 500
  });

  const iconClass = mergeStyles({
    fontSize: 50,
    height: 30,
    width: 30,
    margin: '0 25px',
  });

  const columns: IColumn[] = React.useMemo(() => [
    {
      key: "id",
      name: "#",
      fieldName: "id",
      minWidth: 25,
      maxWidth: 25,
    }, {
      key: "user",
      name: "résident",
      minWidth: 150,
      maxWidth: 150,
      onRender: (item: _IScannerEvent) => (<Text>{userLabel(item.user)}</Text>)
    },
    {
      key: "room",
      name: "Lieu",
      minWidth: 200,
      maxWidth: 200,
      onRender: (item: _IScannerEvent) => (<Text>{roomLabel(item.room)}</Text>)
    },
    {
      key: "timestamp",
      name: "Heure",
      minWidth: 100,
      maxWidth: 100,
      onRender: (item: _IScannerEvent) => (<Text>{timeLabel(item.timestamp)}</Text>)
    }
  ], []);


  async function fetchData() {

    setIsLoading(true);

    try {
      const resUsers = await getAllUsers();
      const resBeacons = await getAllAssetBeacons();
      const resScanners = await getAllBeaconScanners();
      const resPlaces = await getAllBeaconPlaces();
      const resEvents = await getAllBeaconScannerEvents();

      if (resUsers.ok && resBeacons.ok && resScanners.ok && resPlaces.ok && resEvents.ok) {
        const users: IUser[] = await resUsers.json();
        const beacons: IAssetBeacon[] = await resBeacons.json();
        const scanners: IBeaconScanner[] = await resScanners.json();
        const places: IRoom[] = await resPlaces.json();
        const events: IScannerEvent[] = await resEvents.json();


        const now = moment();
        setItems(
          events.map(
            e => {
              const room = places.find(r => r.id === scanners.find(s => s.id === e.scanner_id)?.room_id);
              const user = users.find(u => u.id === beacons.find(b => b.id === e.beacon_id)?.user_id);
              return {
                id: e.id,
                user: user,
                room: room,
                timestamp: e.timestamp,
                out: now.diff(moment.utc(e.timestamp).local(), 'minutes') > outTimeoutMinutes
              };
            }
          ).sort((a, b) => (a.user?.lastname ?? "").localeCompare(b.user?.lastname ?? ""))
        );
      }
    } catch (error) {
      handleError(Error("fetchData error: " + error));
    } finally {
      setIsLoading(false);
    }

  }

  useEffect(() => {
    fetchData();

  }, []);


  return (
    <Stack tokens={{childrenGap: 20}} style={{height: '80%'}}>

      <Stack tokens={{childrenGap: 20}}>
        <StackItem align="center" style={{paddingBottom: 30}}>
          <Text variant={"large"} style={{textDecorationLine: 'underline'}}>Dernières localisations
            connues</Text>
        </StackItem>
        <Stack horizontal tokens={{childrenGap: 20}} verticalAlign="center">
          <StackItem grow={2} align="center" style={{width: 300}}>
            <Stack tokens={{childrenGap: 20}} style={{overflowY: "auto"}}>
              <Stack horizontal>
                <FontIcon aria-label="Compass" iconName="UserFollowed" className={iconClass} />
                <Text variant="medium">Présents sur site</Text>
              </Stack>
              <div className={listStyle} data-is-scrollable="true">
                <DetailsList
                  items={items.filter(x => !x.out)}
                  columns={columns}
                  selectionMode={SelectionMode.none}
                />
              </div>
            </Stack>
          </StackItem>
          <Separator vertical />
          <StackItem grow={2} align="center" style={{width: 300}}>
            <Stack tokens={{childrenGap: 20}} style={{overflowY: "auto"}}>
              <Stack horizontal>
                <FontIcon aria-label="Compass" iconName="UserWarning" className={iconClass} />
                <Text variant="medium">Non-présents sur site</Text>
              </Stack>
              <div className={listStyle} data-is-scrollable="true">
                <DetailsList
                  items={items.filter(x => x.out)}
                  columns={columns}
                  selectionMode={SelectionMode.none}
                />
              </div>
            </Stack>
          </StackItem>
        </Stack>
      </Stack>
    </Stack>
  );
};
