import { mergeStyles } from '@fluentui/react';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";

import SecuredApp from "./SecuredApp";
import { store } from "./slices";


initializeIcons();

// Inject some global styles
mergeStyles({
  ":global(body,html,#root)": {
    margin: 0,
    padding: 0,
    height: '100vh',
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <SecuredApp />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
