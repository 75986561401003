import {
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton
} from "@fluentui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../slices";
import { errorSlice } from "../slices/errorSlice";


// Load the errors stored in Redux and display them one by one.
export const ErrorDialog: React.FC = () => {
  const messages = useSelector((state: RootState) => state.error.messages);
  const dispatch = useDispatch();

  // Hide if there's no message
  const hidden = messages.length === 0;

  function popOne() {
    dispatch(errorSlice.actions.pop());
  }

  return (
    <Dialog
      hidden={hidden}
      onDismiss={popOne}
      dialogContentProps={{
        type: DialogType.normal,
        title: messages[0]?.title || "", // The "messages" list may be empty
        subText: messages[0]?.message || "", // Idem
      }}
    >
      <DialogFooter>
        <PrimaryButton onClick={popOne} text="OK" />
      </DialogFooter>
    </Dialog>
  );
};
