import {format, getDay, parse, startOfWeek} from "date-fns";
import {fr} from "date-fns/locale";
import React from "react";
import {
  Calendar,
  Culture,
  dateFnsLocalizer,
  DateLocalizer,
  DateRange,
  Formats,
  SlotInfo,
  Views
} from 'react-big-calendar';


// Localizer for the calendar. Set to french, but some manual tweaks are needed.
const localizer = dateFnsLocalizer({
  format: format,
  parse: parse,
  startOfWeek: startOfWeek,
  getDay: getDay,
  locales: {"fr-FR": fr},
});

// Translations in french
const messages = {
  previous: "<",
  next: ">",
  today: "Aujourd'hui",
  month: "Mois",
  week: "Semaine",
  day: "Jour",
  agenda: "Agenda",
  date: "Date",
  time: "Heure",
  event: "Tâche",
  showMore: (total: number) => `Voir ${total} de plus`,
};

// Tweaks on the date formatting
// Documentation for the date format : https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
const formats: Formats = {
  dayFormat: (date: Date, culture?: Culture, localizer?: DateLocalizer) => {
    return localizer?.format(date, 'EEEE dd', culture || "") || "";
  },
  dayRangeHeaderFormat: (range: DateRange, culture?: Culture, localizer?: DateLocalizer) => {
    const {start, end} = range;
    const formattedStart = localizer?.format(start, 'EEEE dd MMM', culture || "") || "";
    const formattedEnd = localizer?.format(end, 'EEEE dd MMM', culture || "") || "";
    return formattedStart + " - " + formattedEnd;
  },
  dayHeaderFormat: (date: Date, culture?: Culture, localizer?: DateLocalizer) => {
    return localizer?.format(date, 'EEEE dd MMMM', culture || "") || "";
  },
};

export interface IEvent {
  id: number;
  title: string;
  start: Date;
  end: Date;
  allDay?: boolean;
  absence?: boolean;
}

interface ITaskCalendarProps {
  events: IEvent[];
  // Function called on event selection (simple click)
  onEventSelect: (ev: IEvent) => void;
  // Function called on drag to select a time range
  onSlotSelect: (slot: SlotInfo) => void;
  // Function called to refresh event list
  refreshEvents: (start: string, end: string) => void;
}

function addDays(myDate: Date, nbOfDays: number): Date {
  const returned_date = new Date(myDate.valueOf());
  returned_date.setDate(returned_date.getDate() + nbOfDays);
  return returned_date;
}

export const TaskCalendar: React.FunctionComponent<ITaskCalendarProps> = (props) => {
  return (
    <div className={"CalendarPage"}>
      <Calendar
        // Localisation
        localizer={localizer}
        culture={"fr-FR"}
        messages={messages}
        formats={formats}
        // Style
        style={{minHeight: 400, height: "100%"}}
        views={[Views.MONTH, Views.WEEK, Views.DAY, Views.AGENDA]}
        defaultView="week"
        popup={true}
        // Events
        events={props.events}
        onSelectEvent={ev => props.onEventSelect(ev)}
        // Slot selection
        selectable={true}
        onSelectSlot={props.onSlotSelect}
        onRangeChange={(range, view) => {
          if(view === 'day'){
            if(Array.isArray(range)){
              const start = range[0].toISOString().slice(0, -1); // Remove the trailing "Z"
              const end = addDays(range[0], 1).toISOString().slice(0, -1); // Remove the trailing "Z"
              props.refreshEvents(start, end);
            }
            return;
          }
          else if(Array.isArray(range)) {
            const start = addDays(range[0], 1).toISOString().slice(0, -1); // Remove the trailing "Z"
            const end = addDays(range[range.length-1], 2).toISOString().slice(0, -1); // Remove the trailing "Z"
            props.refreshEvents(start, end);
          }
          else {
            let start = range.start;
            let end = range.end;
            if(typeof start !== "string") {
              start = addDays(start, 1).toISOString().slice(0, -1); // Remove the trailing "Z"
            }
            if(typeof end !== "string") {
              end = addDays(end, 1).toISOString().slice(0, -1); // Remove the trailing "Z"
            }
            props.refreshEvents(start, end);
          }
        }}
        eventPropGetter={(event) => {
          const backgroundColor = event.absence ? 'grey' : '#278b96';
          return { style: { backgroundColor } }
        }}
      />
    </div>
  );
};
