import { Dropdown, IColumn, IDropdownOption, Selection, Spinner, Text, TextField } from "@fluentui/react";
import React from "react";
import {
  createOneResource,
  deleteOneResource,
  getAllResources,
  IResourcePatch,
  IResourcePost,
  IScanWallsResource,
  ITmpResource,
  modifyOneResource,
  ResourceType,
  resourceTypeToString,
  validateTmpResource
} from "../../api/scanwalls";

import { GenericActionBar } from "../../components/genericconfig/GenericActionBar";
import { GenericActionDialog, GenericRemoveDialog } from "../../components/genericconfig/GenericActionDialog";
import { GenericTable } from "../../components/genericconfig/GenericTable";
import { ActionMode } from "../../components/genericconfig/types";
import { createErrorPopup } from "../../helpers/errors";
import { unpackResponse } from "../../helpers/requests";


const DEFAULT_TMP_RESOURCE: ITmpResource = {desc: "", type: ResourceType.WEBSITE, url: ""};
const tableColumns: IColumn[] = [
  {key: "id", name: "ID", fieldName: "id", minWidth: 20, maxWidth: 40},
  {key: "desc", name: "Description", fieldName: "desc", minWidth: 100, isResizable: true},
  {
    key: "type",
    name: "Type",
    fieldName: "type",
    minWidth: 100,
    isResizable: true,
    onRender: item => resourceTypeToString(item.type)
  },
  {key: "url", name: "Contenu", fieldName: "url", minWidth: 100, isResizable: true},
];
const typeOptions: IDropdownOption[] = Object.entries(ResourceType).map(([_key, val]) => ({
  key: val,
  text: resourceTypeToString(val as ResourceType),
}));

export const ResourcesTab: React.FC = () => {
  const [allResources, setAllResources] = React.useState<IScanWallsResource[]>([]);
  const [isLoadingResources, setIsLoadingResources] = React.useState(true);
  const [selected, setSelected] = React.useState<IScanWallsResource>();
  const [actionMode, setActionMode] = React.useState(ActionMode.ADD);
  const [isActionDialogHidden, setIsActionDialogHidden] = React.useState(true);
  const [tmpResource, setTmpResource] = React.useState(DEFAULT_TMP_RESOURCE);
  const validationError = validateTmpResource(tmpResource);

  const selection = React.useMemo(() => {
    return new Selection({
      onSelectionChanged: () => {
        const allSelected: IScanWallsResource[] = selection.getSelection() as IScanWallsResource[];
        const firstSelected = allSelected.length !== 0 ? allSelected[0] : undefined;
        setSelected(firstSelected);
      },
    });
  }, []);

  function fetchResources(): void {
    setIsLoadingResources(true);
    getAllResources().then(unpackResponse).then(
      data => {
        setAllResources(data);
        setIsLoadingResources(false);
      },
      err => {
        createErrorPopup("Erreur lors du chargement", err.message);
      },
    );
  }

  function sendPostResource(): void {
    const postData: IResourcePost = {
      desc: tmpResource.desc,
      type: tmpResource.type,
      url: tmpResource.url,
    };
    createOneResource(postData).then(unpackResponse).then(
      _data => {
        fetchResources();
      },
      err => {
        createErrorPopup("Erreur lors de la création", err.message);
      },
    );
  }

  function sendDeleteResource(): void {
    if (selected === undefined) {
      console.warn("Can't delete when no resource is selected.");
      return;
    }
    deleteOneResource(selected.id).then(unpackResponse).then(
      _data => {
        fetchResources();
      },
      err => {
        createErrorPopup("Erreur lors de la suppression", err.message);
      },
    );
  }

  function sendPatchResource(): void {
    if (selected === undefined) {
      console.warn("Can't edit when no resource is selected.");
      return;
    }
    const patchData: IResourcePatch = {
      desc: tmpResource.desc,
      url: tmpResource.url,
    };
    modifyOneResource(selected.id, patchData).then(unpackResponse).then(
      _data => {
        fetchResources();
      },
      err => {
        createErrorPopup("Erreur lors de la modification", err.message);
      },
    );
  }

  function onActionDialogSubmit(): void {
    setIsActionDialogHidden(true);
    if (actionMode === ActionMode.ADD) {
      sendPostResource();
    } else if (actionMode === ActionMode.REMOVE) {
      sendDeleteResource();
    } else if (actionMode === ActionMode.EDIT) {
      sendPatchResource();
    }
  }

  React.useEffect(() => {
    fetchResources();
  }, []);

  if (isLoadingResources) {
    return <Spinner />;
  }

  return (
    <>
      <GenericTable items={allResources} columns={tableColumns} selection={selection} />
      <GenericActionBar
        disableRemoveEdit={selected === undefined}
        setActionMode={setActionMode}
        displayModal={() => setIsActionDialogHidden(false)}
        emptyTmp={() => setTmpResource(DEFAULT_TMP_RESOURCE)}
        fillTmp={() => setTmpResource({
          desc: selected?.desc ?? DEFAULT_TMP_RESOURCE.desc,
          type: selected?.type ?? DEFAULT_TMP_RESOURCE.type,
          url: selected?.url ?? DEFAULT_TMP_RESOURCE.url,
        })}
      />
      <GenericRemoveDialog
        isHidden={isActionDialogHidden || actionMode !== ActionMode.REMOVE}
        hide={() => setIsActionDialogHidden(true)}
        title={"Supprimer une ressource"}
        onSubmit={onActionDialogSubmit}
      >
        <Text>
          {`Voulez-vous supprimer la ressource #${selected?.id} : `}
          <b>{selected?.desc}</b> ?
        </Text>
      </GenericRemoveDialog>
      <GenericActionDialog
        isHidden={isActionDialogHidden || actionMode === ActionMode.REMOVE}
        hide={() => setIsActionDialogHidden(true)}
        actionMode={actionMode}
        onSubmit={onActionDialogSubmit}
        genericName={"une ressource"}
        errorText={validationError}
      >
        <TextField
          label={"Description"}
          value={tmpResource.desc}
          onChange={(ev, val) => setTmpResource({...tmpResource, desc: val ?? ""})}
        />
        <Dropdown
          label={"Type"}
          disabled={actionMode === ActionMode.EDIT}
          options={typeOptions}
          selectedKey={tmpResource.type}
          onChange={(ev, val) => setTmpResource({
            ...tmpResource,
            type: val?.key as ResourceType ?? ResourceType.WEBSITE,
          })}
        />
        <TextField
          label={"Contenu"}
          value={tmpResource.url ?? ""}
          onChange={(ev, val) => setTmpResource({...tmpResource, url: val ?? ""})}
        />
      </GenericActionDialog>
    </>
  );
};
