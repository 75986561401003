import { Dispatch } from "@reduxjs/toolkit";
import { KeycloakInstance } from "keycloak-js";

import { getIsAdmin } from "../api/actions";
import { userSlice } from "../slices";


// Put the admin state in the use store
export function fetchAdminState(dispatch: Dispatch) {
  getIsAdmin().then(res => {
    if (res.ok) {
      dispatch(userSlice.actions.setIsAdmin({isAdmin: true}));
    } else {
      dispatch(userSlice.actions.setIsAdmin({isAdmin: false}));
    }
  });
}

// Logout the user and clear the store.
export async function logout(keycloak: KeycloakInstance, dispatch: Dispatch) {
  dispatch(userSlice.actions.logout());
  await keycloak.logout();
}
