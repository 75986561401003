import { Depths, Icon, Stack, Text } from "@fluentui/react";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { ErrorDialog } from "../components/ErrorsDialog";
import { NavBar } from "../components/NavBar";
import UserInfo from "../components/UserInfo";
import VersionInfo from "../components/Version";
import { RootState } from "../slices";
import { MobileApps } from "./AppUsages";
import { Beacons } from "./Beacons";
import { AlertPage } from "./iamlost/AlertPage";
import { Location } from "./Location";
import LogoPBD from "./LogoPBD.png";
import { NFC } from "./NFC";
import { PlanningConfig } from "./plannings/PlanningConfig";
import { ResidentActions } from "./ResidentActions";
import { ScanWallsConfig } from "./scanwalls/ScanWallsConfig";
import { UserConfig } from "./UserConfig";
import { WelcomeScreenConfig } from "./WelcomeScreenConfig";


function AdminOnly({children}: {children: JSX.Element}): JSX.Element {
  const isAdmin = useSelector((state: RootState) => state.user.isAdmin);

  if (!isAdmin) {
    return <p>Panneau réservé aux administrateurs.</p>;
  }

  return children;
}

const HomePage: React.FunctionComponent = () => {
  const location = useLocation();
  const pathname = location.pathname;

  let component =
  <h1 style={{marginLeft: 30, marginTop: 20, color: "#323130", textAlign: 'center', }}>
    <Icon iconName="SkypeArrow" style={{marginRight: 20, fontSize: 20}} />
    <Text variant={"xxLarge"}>Sélectionnez une page de configuration dans le menu ci-contre.</Text>
  </h1>;

  switch (pathname) {
    case "/actions":
      component = <ResidentActions />;
      break;
    case "/iamlost":
      component = <AlertPage />;
      break;
    case "/nfc":
      component = <NFC />;
      break;
    case "/loc":
      component = <Location />;
      break;
    case "/plannings":
      component = <PlanningConfig />;
      break;
    case "/welcome-screen":
      component = <WelcomeScreenConfig />;
      break;
    case "/scanwalls":
      component = <ScanWallsConfig />;
      break;
    case "/apps":
      component = <MobileApps />;
      break;
    // Administration
    case "/users":
      component = <AdminOnly><UserConfig /></AdminOnly>;
      break;
    case "/beacons":
      component = <AdminOnly><Beacons /></AdminOnly>;
      break;
  }

  return (
    <Stack styles={{root: {height: '100vh'}}}>
      <Stack
        horizontal
        horizontalAlign={"space-between"}
        styles={{
          root: {
            height: '11vh',
            padding: 10,
            paddingLeft: '3.5vw',
            paddingRight: '3.5vw',
            boxShadow: Depths.depth8,
            zIndex: 2
          }
        }}
      >
        <img src={LogoPBD} height={'98%'} alt="LogoPBD" />
        <Stack.Item align="center">
          <Text variant={"xxLarge"}>Interface d'administration Papillons Blancs Douaisis</Text>
        </Stack.Item>
        <ErrorDialog />
        <UserInfo />
      </Stack>

      <Stack styles={{root: {height: '1vh', backgroundColor: '#efefef', }}} />

      <Stack horizontal styles={{root: {height: "88vh"}}}>
        <Stack
          verticalAlign={"space-between"}
          styles={{
            root: {
              paddingBottom: 25,
              boxShadow: Depths.depth8,
              zIndex: 1,
            }
          }}
        >
          <NavBar />
          <VersionInfo />
        </Stack>

        <Stack styles={{root: {width: '1.5vh', backgroundColor: '#efefef', }}} />

        <Stack
          styles={{
            root: {
              backgroundColor: '#ffffff',
              width: "100%",
              height: '100%',
              overflow: 'hidden',
              boxShadow: Depths.depth8,
              zIndex: 1,
            }
          }}
        >
          <Stack
            styles={{
              root: {
                height: "100%",
                padding: 30,
                overflow: 'auto',
                paddingRight: '20px'
              }
            }}>
            {component}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default HomePage;
