import {useKeycloak} from '@react-keycloak/web';
import React from 'react';
import {useLocation} from 'react-router-dom';


const LoginPage: React.FunctionComponent = () => {
  const location = useLocation();
  const {keycloak} = useKeycloak();

  const currentLocationState: any = location.state || {
    from: {pathname: '/home'},
  };

  keycloak?.login({redirectUri: window.location.origin + currentLocationState?.from});

  return (
      <div>
        <p>Redirecting to identity provider...</p>
      </div>
  );
};

export default LoginPage;
