export enum ActionMode {
  ADD = 0,
  REMOVE = 1,
  EDIT = 2,
}

export function actionModeToString(mode: ActionMode): string {
  if (mode === ActionMode.ADD) return "Ajouter";
  if (mode === ActionMode.REMOVE) return "Supprimer";
  if (mode === ActionMode.EDIT) return "Modifier";
  throw new Error(`Unknown ActionMode : ${mode}`);
}
