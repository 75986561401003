import { ReactKeycloakProvider } from "@react-keycloak/web";
import Keycloak from "keycloak-js";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { getConfig } from "./api/actions";
import { AppRouter } from "./routes/AppRouter";
import { configSlice, RootState, userSlice } from "./slices";


const eventLogger = (_event: unknown, _error: unknown) => {
  //console.log("Keycloak event : event=", event, "error=", error);
};

// Wrap the AppRouter with KeyCloak
const SecuredApp: React.FunctionComponent = () => {
  const config = useSelector((state: RootState) => state.config);
  const dispatch = useDispatch();

  React.useEffect(() => {
    getConfig()
      .then(res => res.json())
      .then((resp) => {
        dispatch(configSlice.actions.setOauthConfig({
          idpUrl: resp.idp_url,
          realmName: resp.realm_name,
          userProfileUrl: resp.user_profile_url,
          clientId: resp.client_id,
          realmAdministrationUrl: resp.realm_administration_url
        }));
      });
  }, [dispatch]);

  function onTokens(tokens: any) {
    dispatch(userSlice.actions.setToken({token: tokens.token}));
  }

  if (config.clientId === "") {
    return (
      <div>
        <p>Retrieving Configuration...</p>
      </div>
    );
  }

  const keycloak = Keycloak({
    url: config.idpUrl,
    realm: config.realmName,
    clientId: config.clientId,
  });

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onEvent={eventLogger}
      onTokens={onTokens}
    >
      <AppRouter />
    </ReactKeycloakProvider>
  );
};

export default SecuredApp;
