import { ICalendarStrings } from "@fluentui/date-time-utilities";
import {IDatePickerStrings} from "@fluentui/react/lib/components/DatePicker/DatePicker.types";

export const frDatePickerString: IDatePickerStrings = {
    days: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
    goToToday: "Aller à la date du jour",
    months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    shortDays: ["D", "L", "M", "M", "J", "V", "S"],
    shortMonths: ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Août", "Sept", "Oct", "Nov", "Déc" ],
    weekNumberFormatString: "Semaine numéro {0}",
    prevMonthAriaLabel: "Aller au mois précédent",
    nextMonthAriaLabel: "Aller au mois suivant",
    prevYearAriaLabel: "Aller à l'année précédente",
    nextYearAriaLabel: "Aller à l'année suivante",
    prevYearRangeAriaLabel: "Aller à l'interval d'année précédent",
    nextYearRangeAriaLabel: "Aller à l'interval d'année suivant",
    closeButtonAriaLabel: "Fermer la sélection de date",
    selectedDateFormatString: "Date sélectionnée {0}",
    todayDateFormatString: "Date du jour {0}",
    monthPickerHeaderAriaLabel: "{0}, changer d'année'",
    yearPickerHeaderAriaLabel: "{0}, changer de mois",
    dayMarkedAriaLabel: "marqué",
    isRequiredErrorMessage: undefined,
    invalidInputErrorMessage: "Format de date invalide",
    isResetStatusMessage: "Entrée invalide \"{0}\", date ré-initialisée à \"{1}\"",
    isOutOfBoundsErrorMessage: "La date de début doit être antérieure à la date de fin"
}

export const frCalendarStrings: ICalendarStrings = {
    months: frDatePickerString.months,
    shortMonths: frDatePickerString.shortMonths,
    days: frDatePickerString.days,
    shortDays: frDatePickerString.shortDays,
    goToToday: frDatePickerString.goToToday,
    weekNumberFormatString: frDatePickerString.weekNumberFormatString,
    prevMonthAriaLabel: frDatePickerString.prevMonthAriaLabel,
    nextMonthAriaLabel: frDatePickerString.nextMonthAriaLabel,
    prevYearAriaLabel: frDatePickerString.prevYearAriaLabel,
    nextYearAriaLabel: frDatePickerString.nextYearAriaLabel,
    prevYearRangeAriaLabel: frDatePickerString.prevYearRangeAriaLabel,
    nextYearRangeAriaLabel: frDatePickerString.nextYearRangeAriaLabel,
    closeButtonAriaLabel: frDatePickerString.closeButtonAriaLabel,
    selectedDateFormatString: frDatePickerString.selectedDateFormatString,
    todayDateFormatString: frDatePickerString.todayDateFormatString,
    monthPickerHeaderAriaLabel: frDatePickerString.monthPickerHeaderAriaLabel,
    yearPickerHeaderAriaLabel: frDatePickerString.yearPickerHeaderAriaLabel,
    dayMarkedAriaLabel: frDatePickerString.dayMarkedAriaLabel,
}
