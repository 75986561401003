import { createSlice } from "@reduxjs/toolkit";


interface ErrorMessage {
  title: string;
  message: string;
}

let initialState = {
  messages: [] as ErrorMessage[],
};

const errorSlice = createSlice({
  name: "error",
  initialState: initialState,
  reducers: {
    // Push a new error
    push: (state, action) => {
      const newError: ErrorMessage = action.payload;
      state.messages.push(newError);
    },
    // Pop the first error
    pop: state => {
      state.messages.shift();
    },
  },
});

export { errorSlice };
