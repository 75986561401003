import { Dropdown, IColumn, IDropdownOption, Selection, Spinner, Stack, Text, TextField } from "@fluentui/react";
import React from "react";

import { createOneUser, deleteOneUser, getAllUsers, modifyOneUser } from "../api/actions";
import { ITmpUser, IUser, IUserPatch, IUserPost, roleToString, UserRole, validateTmpUser } from "../api/types";
import { GenericActionBar } from "../components/genericconfig/GenericActionBar";
import { GenericActionDialog, GenericRemoveDialog } from "../components/genericconfig/GenericActionDialog";
import { GenericTable } from "../components/genericconfig/GenericTable";
import { ActionMode } from "../components/genericconfig/types";
import { ImageSelector } from "../components/ImageSelector";
import { createErrorPopup } from "../helpers/errors";
import { unpackResponse } from "../helpers/requests";


// Create the dropdown for the 3 user roles
const allRoles = [UserRole.RESIDENT, UserRole.STAFF, UserRole.ADMIN];
const userTypeOptions: IDropdownOption[] = allRoles.map(role => ({
  key: role,
  text: roleToString(role),
}));

const userDisplayColumns: IColumn[] = [
  {
    key: "id",
    name: "ID",
    fieldName: "id",
    minWidth: 25,
    maxWidth: 25,
    isRowHeader: true,
    isResizable: true,
  }, {
    key: "username",
    name: "Nom d'utilisateur",
    fieldName: "username",
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
  }, {
    key: "firstname",
    name: "Prénom",
    fieldName: "firstname",
    minWidth: 100,
    maxWidth: 500,
    isResizable: true,
  }, {
    key: "lastname",
    name: "Nom",
    fieldName: "lastname",
    minWidth: 100,
    maxWidth: 500,
    isResizable: true,
  }, {
    key: "role",
    name: "Type d'utilisateur",
    fieldName: "role",
    minWidth: 100,
    maxWidth: 500,
    isResizable: true,
    onRender: (item) => roleToString(item.role),
  },
];

// Create the default user (default fields values)
const DEFAULT_USER: ITmpUser = {
  username: "",
  email: "",
  firstname: "",
  lastname: "",
  image_url: "",
  badge_uid: "",
  role: UserRole.RESIDENT,
};

// Return True if the given user is the only admin in the allUser list
function isLastAdmin(user: IUser, allUsers: IUser[]): boolean {
  const admins = allUsers.filter(u => u.role === UserRole.ADMIN);
  const adminsIDs = admins.map(u => u.id);
  return adminsIDs.length === 1 && adminsIDs[0] === user.id;
}

export const UserConfig: React.FC = () => {
  const [allUsers, setAllUsers] = React.useState<IUser[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [selected, setSelected] = React.useState<IUser>();
  const [actionMode, setActionMode] = React.useState<ActionMode>(ActionMode.ADD);
  const [isActionDialogHidden, setIsActionDialogHidden] = React.useState(true);
  const [tmpUser, setTmpUser] = React.useState<ITmpUser>(DEFAULT_USER);

  const validationResult = validateTmpUser(tmpUser);

  const selection = React.useMemo(() => {
    return new Selection({
      onSelectionChanged: () => {
        const allSelected: IUser[] = selection.getSelection() as IUser[];
        const firstSelected = allSelected.length !== 0 ? allSelected[0] : undefined;
        setSelected(firstSelected);
      },
    });
  }, []);

  // Reload the user list
  function fetchUsers() {
    setIsLoading(true);
    getAllUsers().then(unpackResponse).then(
      data => {
        setAllUsers(data);
        setIsLoading(false);
      },
      err => createErrorPopup("Erreur lors du chargement des utilisateurs", err.message),
    );
  }

  function sendPostUser(): void {
    const postData: IUserPost = {
      username: tmpUser.username,
      email: tmpUser.email !== "" ? tmpUser.email : null,
      firstname: tmpUser.firstname,
      lastname: tmpUser.lastname,
      image_url: tmpUser.image_url || null,
      badge_uid: tmpUser.badge_uid !== "" ? tmpUser.badge_uid : null,
      role: tmpUser.role,
    };
    createOneUser(postData).then(unpackResponse).then(
      _ => {
        fetchUsers();
      },
      err => createErrorPopup("Erreur lors de la création", err.message),
    );
  }

  function sendDeleteUser(): void {
    if (selected === undefined) {
      console.warn("Can't delete when no user is selected.");
      return;
    }
    if (isLastAdmin(selected, allUsers)) {
      createErrorPopup(
        "Supression impossible",
        "Il doit rester un administrateur au minimum.",
      );
      return;
    }
    deleteOneUser(selected.id).then(unpackResponse).then(
      _ => {
        fetchUsers();
      },
      err => createErrorPopup("Erreur lors de la suppression", err.message),
    );
  }

  function sendPatchUser(): void {
    if (selected === undefined) {
      console.warn("Can't edit when no user is selected.");
      return;
    }
    const patchData: IUserPatch = {
      email: tmpUser.email !== "" ? tmpUser.email : null,
      firstname: tmpUser.firstname,
      lastname: tmpUser.lastname,
      image_url: tmpUser.image_url || null,
      badge_uid: tmpUser.badge_uid !== "" ? tmpUser.badge_uid : null,
    };
    modifyOneUser(selected.id, patchData).then(unpackResponse).then(
      _ => {
        fetchUsers();
      },
      err => createErrorPopup("Erreur lors de la modification", err.message),
    );
  }

  function onActionDialogSubmit(): void {
    setIsActionDialogHidden(true);
    if (actionMode === ActionMode.ADD) {
      sendPostUser();
    } else if (actionMode === ActionMode.REMOVE) {
      sendDeleteUser();
    } else if (actionMode === ActionMode.EDIT) {
      sendPatchUser();
    }
  }

  // Load the user list on component creation
  React.useEffect(() => {
    fetchUsers();
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Stack>
      <Text variant={"large"}>Liste des utilisateurs</Text>
      <GenericTable items={allUsers} columns={userDisplayColumns} selection={selection} />
      <GenericActionBar
        disableRemoveEdit={selected === undefined}
        setActionMode={setActionMode}
        displayModal={() => setIsActionDialogHidden(false)}
        emptyTmp={() => setTmpUser(DEFAULT_USER)}
        fillTmp={() => setTmpUser({
          username: selected?.username ?? DEFAULT_USER.username,
          email: selected?.email ?? DEFAULT_USER.email,
          firstname: selected?.firstname ?? DEFAULT_USER.firstname,
          lastname: selected?.lastname ?? DEFAULT_USER.lastname,
          image_url: selected?.image_url ?? DEFAULT_USER.image_url,
          badge_uid: selected?.badge_uid ?? DEFAULT_USER.badge_uid,
          role: selected?.role ?? DEFAULT_USER.role,
        })}
      />
      <GenericRemoveDialog
        isHidden={isActionDialogHidden || actionMode !== ActionMode.REMOVE}
        hide={() => setIsActionDialogHidden(true)}
        title={"Supprimer un utilisateur"}
        onSubmit={onActionDialogSubmit}
      >
        <Text>
          {`Voulez-vous supprimer l'utilisateur #${selected?.id} : `}
          <b>{selected?.username}</b> ?<br />
          Toutes les informations associées (profils, tâches, ...) seront
          également supprimées.
        </Text>
      </GenericRemoveDialog>
      <GenericActionDialog
        isHidden={isActionDialogHidden || actionMode === ActionMode.REMOVE}
        hide={() => setIsActionDialogHidden(true)}
        actionMode={actionMode}
        onSubmit={onActionDialogSubmit}
        genericName={"un utilisateur"}
        errorText={validationResult}
      >
        <TextField
          label={"Nom d'utilisateur"}
          disabled={actionMode === ActionMode.EDIT}
          value={tmpUser.username}
          onChange={(ev, val) => {
            setTmpUser({...tmpUser, username: val ?? DEFAULT_USER.username});
          }}
        />
        <TextField
          label={"Adresse email"}
          value={tmpUser.email}
          onChange={(ev, val) => {
            setTmpUser({...tmpUser, email: val ?? DEFAULT_USER.email});
          }}
        />
        <TextField
          label={"Prénom"}
          value={tmpUser.firstname}
          onChange={(ev, val) => {
            setTmpUser({...tmpUser, firstname: val ?? DEFAULT_USER.firstname});
          }}
        />
        <TextField
          label={"Nom"}
          value={tmpUser.lastname}
          onChange={(ev, val) => {
            setTmpUser({...tmpUser, lastname: val ?? DEFAULT_USER.lastname});
          }}
        />
        <ImageSelector
          label={"Avatar"}
          category={"avatar"}
          value={tmpUser.image_url}
          onChange={val => setTmpUser({...tmpUser, image_url: val})}
        />
        <TextField
          label={"ID du badge"}
          value={tmpUser.badge_uid}
          disabled={tmpUser.role === UserRole.RESIDENT}
          onChange={(ev, val) => {
            setTmpUser({...tmpUser, badge_uid: val?.toLocaleLowerCase() ?? DEFAULT_USER.badge_uid});
          }}
        />
        <Dropdown
          label={"Type d'utilisateur"}
          options={userTypeOptions}
          selectedKey={tmpUser.role}
          disabled={actionMode === ActionMode.EDIT}
          onChange={(ev, val) => {
            setTmpUser({
              ...tmpUser,
              role: val?.key as UserRole ?? DEFAULT_USER.role,
            });
          }}
        />
      </GenericActionDialog>
    </Stack>
  );
};
