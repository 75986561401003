// Prefix the number by a 0 if it is below 10.
function toDoubleDigit(n: number): string {
  return String(n).padStart(2, "0");
}

// Return the format HH:MM from a Date object.
export function toHoursAndMinutes(d: Date): string {
  return toDoubleDigit(d.getHours()) + ":" + toDoubleDigit(d.getMinutes());
}

// Return the minutes total from a "HH:MM" string.
function toMinutes(hoursAndMinutes: string): number {
  const [hours, minutes] = hoursAndMinutes.split(":");
  return Number(hours) * 60 + Number(minutes);
}

// Return True if s1 < s2 (with s1 and s2 at the "HH:MM" format).
export function verifyOrder(s1: string, s2: string): boolean {
  return toMinutes(s1) < toMinutes(s2);
}

// Return a date object from the day and the time at the "HH:MM" format.
export function mergeDayAndTime(day: Date, hoursAndMinutes: string): Date {
  const timeInMinutes = toMinutes(hoursAndMinutes);
  const returnedDate = new Date(day); // Copy the object
  returnedDate.setHours(0, timeInMinutes, 0, 0);
  return returnedDate;
}

export function onFormatDate(date?: Date): string {
  return !date ? '' : String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0') + '/' + (date.getFullYear());
}

export function dateAsString(date: Date): string {
  return String(date.getFullYear()) + "-" + String(date.getMonth()+1).padStart(2, '0') + "-" + String(date.getDate())
}

export function stringAsDate(dateString: string): Date | undefined {
  if(dateString) {
    const parts = dateString.split("-");
    return new Date(parseInt(parts[0], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[2], 10))
  }
  return undefined
}
