import { makeGetRequest, makeRequestWithJson } from "../helpers/requests";
import { API_URL } from "./actions";

// Tags

export interface IScanWallsTag {
  id: number;
  category: string;
  desc: string;
  resource_ids: number[];
}

export interface IScanWallsTagPost {
  category: string;
  desc: string;
}

export interface IScanWallsTagPatch {
  category?: string;
  desc?: string;
}

export interface ITmpScanWallsTag {
  category: string;
  desc: string;
}

export function validateTmpScanWallsTag(data: ITmpScanWallsTag): string | undefined {
  if (data.category.length > 50) {
    return "La catégorie est trop longue.";
  }
}

export function getAllTags(): Promise<Response> {
  return makeGetRequest(API_URL + "/scanwalls/tag");
}

export function createOneTag(tag: IScanWallsTagPost): Promise<Response> {
  return makeRequestWithJson(API_URL + "/scanwalls/tag", "POST", tag);
}

export function modifyOneTag(id: number, res: IScanWallsTagPatch): Promise<Response> {
  return makeRequestWithJson(API_URL + "/scanwalls/tag/" + id, "PATCH", res);
}

export function deleteOneTag(id: number): Promise<Response> {
  return makeRequestWithJson(API_URL + "/scanwalls/tag/" + id, "DELETE", {});
}

export function addResourceToTag(tag_id: number, resource_id: number): Promise<Response> {
  return makeRequestWithJson(`${API_URL}/scanwalls/tag/${tag_id}/add_resource/${resource_id}`, "POST", {});
}

export function removeResourceFromTag(tag_id: number, resource_id: number): Promise<Response> {
  return makeRequestWithJson(`${API_URL}/scanwalls/tag/${tag_id}/remove_resource/${resource_id}`, "POST", {});
}

// Tags

export enum ResourceType {
  WEBSITE = "website",
  PHONE = "phone",
}

export function resourceTypeToString(type: ResourceType): string {
  if (type === ResourceType.WEBSITE) return "Site web";
  else if (type === ResourceType.PHONE) return "Numéro de téléphone";
  throw new Error("Unknown ResourceType : " + type);
}

export interface IScanWallsResource {
  id: number;
  desc: string;
  type: ResourceType;
  url: string;
}

export interface IResourcePost {
  desc: string;
  type: ResourceType;
  url: string;
}

export interface IResourcePatch {
  desc?: string;
  url?: string;
}

export interface ITmpResource {
  desc: string;
  type: ResourceType;
  url: string;
}

const phoneRegExp = new RegExp("^\\+[0-9]{2}[0-9]{9}$");
const urlRegExp = new RegExp("^https?://");

// Validate a ITmpResource. Return an error message or undefined if valid.
export function validateTmpResource(data: ITmpResource): string | undefined {
  if (data.type === ResourceType.PHONE && !phoneRegExp.test(data.url)) {
    return "Le numéro de téléphone n'est pas au bon format (ex: +33123456789).";
  }
  if (data.type === ResourceType.WEBSITE && !urlRegExp.test(data.url)) {
    return "L'URL doit commencer par 'http://' ou 'https://'.";
  }
  return undefined;
}

export function getAllResources(): Promise<Response> {
  return makeGetRequest(API_URL + "/scanwalls/resource");
}

export function createOneResource(res: IResourcePost): Promise<Response> {
  return makeRequestWithJson(API_URL + "/scanwalls/resource", "POST", res);
}

export function modifyOneResource(id: number, res: IResourcePatch): Promise<Response> {
  return makeRequestWithJson(API_URL + "/scanwalls/resource/" + id, "PATCH", res);
}

export function deleteOneResource(id: number): Promise<Response> {
  return makeRequestWithJson(API_URL + "/scanwalls/resource/" + id, "DELETE", {});
}
