import {
  IColumn,
  ITag,
  Label,
  Link,
  PrimaryButton,
  Selection,
  Separator,
  Spinner,
  Stack,
  TagPicker,
  Text,
  TextField
} from "@fluentui/react";
import React from "react";
import {
  addResourceToTag,
  createOneTag,
  deleteOneTag,
  getAllResources,
  getAllTags,
  IScanWallsResource,
  IScanWallsTag,
  IScanWallsTagPatch,
  IScanWallsTagPost,
  ITmpScanWallsTag,
  modifyOneTag,
  removeResourceFromTag,
  validateTmpScanWallsTag
} from "../../api/scanwalls";

import { GenericActionBar } from "../../components/genericconfig/GenericActionBar";
import { GenericActionDialog, GenericRemoveDialog } from "../../components/genericconfig/GenericActionDialog";
import { GenericTable } from "../../components/genericconfig/GenericTable";
import { ActionMode } from "../../components/genericconfig/types";
import { createErrorPopup } from "../../helpers/errors";
import { unpackResponse } from "../../helpers/requests";


const DEFAULT_TMP_TAG: ITmpScanWallsTag = {category: "", desc: ""};
const tableColumns: IColumn[] = [
  {key: "id", name: "ID", fieldName: "id", minWidth: 20, maxWidth: 40},
  {key: "category", name: "Catégorie", fieldName: "category", minWidth: 100, maxWidth: 100, isResizable: true},
  {key: "desc", name: "Description", fieldName: "desc", minWidth: 100, isResizable: true},
  {
    key: "url",
    name: "URL associée",
    minWidth: 200,
    onRender: item => {
      const url = `${window.location.origin}/api/scanwalls/scan/${item.id}`;
      return <Link href={url}>{url}</Link>;
    }
  },
];

export const TagsTab: React.FC = () => {
  const [allTags, setAllTags] = React.useState<IScanWallsTag[]>([]);
  const [isLoadingTabs, setIsLoadingTabs] = React.useState(true);
  const [selected, setSelected] = React.useState<IScanWallsTag>();
  const [actionMode, setActionMode] = React.useState(ActionMode.ADD);
  const [isActionDialogHidden, setIsActionDialogHidden] = React.useState(true);
  const [tmpTag, setTmpTag] = React.useState<ITmpScanWallsTag>(DEFAULT_TMP_TAG);

  const validationError = validateTmpScanWallsTag(tmpTag);

  const selection = React.useMemo(() => {
    return new Selection({
      onSelectionChanged: () => {
        const allSelected: IScanWallsTag[] = selection.getSelection() as IScanWallsTag[];
        const firstSelected = allSelected.length !== 0 ? allSelected[0] : undefined;
        setSelected(firstSelected);
      },
    });
  }, []);

  function fetchTags(): void {
    setIsLoadingTabs(true);
    getAllTags().then(unpackResponse).then(
      data => {
        setAllTags(data);
        setIsLoadingTabs(false);
      },
      err => {
        createErrorPopup("Erreur lors du chargement", err.message);
      },
    );
  }

  function sendPostTag(): void {
    const postData: IScanWallsTagPost = {category: tmpTag.category, desc: tmpTag.desc};
    createOneTag(postData).then(unpackResponse).then(
      _data => {
        fetchTags();
      },
      err => {
        createErrorPopup("Erreur lors de la création", err.message);
      },
    );
  }

  function sendPatchTag(): void {
    if (selected === undefined) {
      console.warn("Can't modify when no tag is selected.");
      return;
    }
    const patchData: IScanWallsTagPatch = {category: tmpTag.category, desc: tmpTag.desc};
    modifyOneTag(selected.id, patchData).then(unpackResponse).then(
      _data => {
        fetchTags();
      },
      err => {
        createErrorPopup("Erreur lors de la modification", err.message);
      },
    );
  }

  function sendDeleteTag(): void {
    if (selected === undefined) {
      console.warn("Can't delete when no tag is selected.");
      return;
    }
    deleteOneTag(selected.id).then(unpackResponse).then(
      _data => {
        fetchTags();
      },
      err => {
        createErrorPopup("Erreur lors de la suppression", err.message);
      },
    );
  }

  function onActionDialogSubmit(): void {
    setIsActionDialogHidden(true);
    if (actionMode === ActionMode.ADD) {
      sendPostTag();
    } else if (actionMode === ActionMode.REMOVE) {
      sendDeleteTag();
    } else if (actionMode === ActionMode.EDIT) {
      sendPatchTag();
    }
  }

  React.useEffect(() => {
    fetchTags();
  }, []);

  if (isLoadingTabs) {
    // We can't do that, as we don't want to destroy the ResourcePicker component,
    // which will trigger a new fetchResources request.
    // return <Spinner />;
  }

  return (
    <Stack>
      <GenericTable items={allTags} columns={tableColumns} selection={selection} />
      <GenericActionBar
        disableRemoveEdit={selected === undefined}
        setActionMode={setActionMode}
        displayModal={() => setIsActionDialogHidden(false)}
        emptyTmp={() => setTmpTag(DEFAULT_TMP_TAG)}
        fillTmp={() => setTmpTag({
          category: selected?.category ?? DEFAULT_TMP_TAG.category,
          desc: selected?.desc ?? DEFAULT_TMP_TAG.desc,
        })}
      />
      <GenericRemoveDialog
        isHidden={isActionDialogHidden || actionMode !== ActionMode.REMOVE}
        hide={() => setIsActionDialogHidden(true)}
        title={"Supprimer un tag"}
        onSubmit={onActionDialogSubmit}
      >
        <Text>{`Voulez-vous supprimer le tag #${selected?.id} ?`}</Text>
      </GenericRemoveDialog>
      <GenericActionDialog
        isHidden={isActionDialogHidden || actionMode === ActionMode.REMOVE}
        hide={() => setIsActionDialogHidden(true)}
        actionMode={actionMode}
        onSubmit={onActionDialogSubmit}
        genericName={"un tag"}
        errorText={validationError}
      >
        <TextField
          label={"Catégorie"}
          value={tmpTag.category}
          onChange={(ev, val) => setTmpTag({...tmpTag, category: val ?? DEFAULT_TMP_TAG.category})}
        />
        <TextField
          label={"Description"}
          value={tmpTag.desc}
          onChange={(ev, val) => setTmpTag({...tmpTag, desc: val ?? DEFAULT_TMP_TAG.desc})}
        />
      </GenericActionDialog>
      <Separator />
      <ResourcePicker tag={selected} onTagRefresh={fetchTags} />
    </Stack>
  );
};

interface IResourcePickerProps {
  tag?: IScanWallsTag;
  onTagRefresh: () => void;
}

function resourceToTag(res: IScanWallsResource): ITag {
  return {key: res.id, name: `#${res.id} : ${res.desc}`};
}

const ResourcePicker: React.FC<IResourcePickerProps> = (props) => {
  const [allResources, setAllResources] = React.useState<IScanWallsResource[]>([]);
  const [isLoadingResources, setIsLoadingResources] = React.useState(true);
  // This may be a bit confusing. Those are the tags (ITag) used by FluentUI TagPicker.
  // Not the NFC tags of the above component (IScanWallsTag).
  const [selectedTags, setSelectedTags] = React.useState<ITag[]>([]);

  function areDistantAndLocalSetIdentical(): boolean {
    if (props.tag === undefined) {
      return false;
    }
    const distantSet: Set<number> = new Set(props.tag.resource_ids);
    const localSet: Set<number> = new Set(selectedTags.map(tag => tag.key as number));
    if (distantSet.size !== localSet.size) {
      return false;
    }
    // Can't use localSet.forEach here. Nor for...of, as it causes a build error.
    for (const localID of Array.from(localSet)) {
      if (!distantSet.has(localID)) {
        return false;
      }
    }
    return true;
  }

  function fetchResources(): void {
    setIsLoadingResources(true);
    getAllResources().then(unpackResponse).then(
      data => {
        setAllResources(data);
        setIsLoadingResources(false);
      },
      err => createErrorPopup("Erreur lors du chargement des ressources associées au tag", err.message),
    );
  }

  function sendPostAddResource(tag_id: number, resource_id: number): void {
    addResourceToTag(tag_id, resource_id).then(unpackResponse).then(
      _data => props.onTagRefresh(),
      err => createErrorPopup("Erreur lors de l'ajout d'une ressource", err.message),
    );
  }

  function sendPostRemoveResource(tag_id: number, resource_id: number): void {
    removeResourceFromTag(tag_id, resource_id).then(unpackResponse).then(
      _data => props.onTagRefresh(),
      err => createErrorPopup("Erreur lors du retrait d'une ressource", err.message),
    );
  }

  function getNonSelectedResources(): IScanWallsResource[] {
    const selectedIDs: number[] = selectedTags.map(tag => tag.key as number);
    return allResources.filter(res => !selectedIDs.includes(res.id));
  }

  function getNonSelectedTags(): ITag[] {
    return getNonSelectedResources().map(res => resourceToTag(res));
  }

  function resolveSuggestions(currentText: string, _currentTags?: ITag[]) {
    if (currentText === "") {
      return getNonSelectedTags();
    }
    const nonSelected = getNonSelectedResources();
    const filtered = nonSelected.filter(res => res.desc.toLowerCase().includes(currentText.toLowerCase()));
    return filtered.map(res => resourceToTag(res));
  }

  function onSubmit(): void {
    if (props.tag !== undefined) {
      const tag: IScanWallsTag = props.tag;
      const distantSet: Set<number> = new Set(props.tag.resource_ids);
      const localSet: Set<number> = new Set(selectedTags.map(tag => tag.key as number));

      // We have to add all the IDs in the localSet that are not in the distantSet.
      localSet.forEach(localID => {
        if (!distantSet.has(localID)) {
          sendPostAddResource(tag.id, localID);
        }
      });

      // We have to remove all the IDs of the distantSet that are not in the localSet.
      distantSet.forEach(distantID => {
        if (!localSet.has(distantID)) {
          sendPostRemoveResource(tag.id, distantID);
        }
      });
    }
  }

  // Fill the selected tags (options) according to the new tag (NFC)
  React.useEffect(() => {
    if (props.tag === undefined) {
      setSelectedTags([]);
    } else {
      const newSelectedTags: ITag[] = [];
      for (const resourceID of props.tag.resource_ids) {
        const associated = allResources.find(res => res.id === resourceID);
        if (associated !== undefined) {
          newSelectedTags.push(resourceToTag(associated));
        } else {
          console.log("The ID", resourceID, "is associated but couldn't be found in", allResources);
        }
      }
      setSelectedTags(newSelectedTags);
    }
  }, [props.tag, allResources]);

  React.useEffect(() => {
    fetchResources();
  }, []);

  if (isLoadingResources) {
    return <Spinner />;
  }

  if (props.tag === undefined) {
    return <Text>Sélectionnez un tag pour afficher les ressources associées.</Text>;
  }

  return (
    <Stack tokens={{childrenGap: 10}}>
      <Label>Ressources associées</Label>
      <TagPicker
        onResolveSuggestions={resolveSuggestions}
        onEmptyInputFocus={getNonSelectedTags}
        selectedItems={selectedTags}
        onChange={tags => setSelectedTags(tags ?? [])}
      />
      <PrimaryButton
        text={"Appliquer"}
        disabled={areDistantAndLocalSetIdentical()}
        onClick={onSubmit}
        styles={{root: {width: 150}}}
      />
    </Stack>
  );
};
