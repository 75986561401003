import {
  DefaultPalette,
  DetailsList,
  IColumn,
  Selection,
  SelectionMode,
  Separator,
  Stack,
  Text,
  Spinner,
} from "@fluentui/react";
import { GroupedVerticalBarChart, IGroupedVerticalBarChartData, } from '@fluentui/react-charting';
import moment, { Moment } from 'moment';
import React, { useEffect } from "react";

import { getAllUsers, getAppUsageStatsSummary, } from '../api/actions';
import { IUser, UsageStatsSummary, UsageStatsSummaryData, UserRole } from '../api/types';
import { createErrorPopup } from "../helpers/errors";
import { unpackResponse } from "../helpers/requests";


export const MobileApps: React.FC = () => {

  return (<UsageViewByApp />);
};


const UsageViewByApp: React.FC = () => {

  const [isLoading, setIsLoading] = React.useState(true);
  const [allUsers, setAllUsers] = React.useState<IUser[]>([]);
  const [selectedUser, setSelectedUser] = React.useState<IUser>();
  const [summaryData, setSummaryData] = React.useState<IGroupedVerticalBarChartData[]>([]);

  const userColumns: IColumn[] = React.useMemo(() => [
    {
      key: "id",
      name: "#",
      fieldName: "id",
      minWidth: 25,
      maxWidth: 25,
    }, {
      key: "firstname",
      name: "Prénom",
      fieldName: "firstname",
      minWidth: 100,
      maxWidth: 500,
    }, {
      key: "lastname",
      name: "Nom",
      fieldName: "lastname",
      minWidth: 100,
      maxWidth: 500,
    },
  ], []);

  const userSelection = new Selection({
    onSelectionChanged: () => {
      setSelectedUser(
        userSelection.count ? userSelection.getSelection()[0] as IUser : undefined);
    }
  });

  function* computeTimes(min: Moment, max: Moment) {
    let m = moment(min);
    while (m <= max) {
      yield m;
      m = moment(m).add(1, 'M');
    }
  }


  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUserAppStats = React.useCallback(async (userId: number) => {
    setIsLoading(true);
    try {
      const resSummary = await getAppUsageStatsSummary(userId);
      const summary: UsageStatsSummary[] = await unpackResponse(resSummary);

      const times = summary.map(x => moment(x.timestamp)).sort();
      const minT = times[0];
      const maxT = times[times.length - 1];
      const series = summary.flatMap(x => x.data.map(y => y.label));
      const seriesMap = new Map(series.map(x => [x, {key: x, data: 0, color: DefaultPalette.accent, legend: x}]));

      const mergeSeries = (data: UsageStatsSummaryData[]) => {
        const sm = new Map(data.map(x => [x.label, {
          key: x.label,
          data: (x.usage / 3600.0),
          color: DefaultPalette.accent,
          legend: x.label
        }]));
        const mm = (new Map([...Array.from(seriesMap), ...Array.from(sm)])).values();
        return Array.from(mm);
      };

      const minData = Array.from(computeTimes(minT, maxT)).map(
        x => ({
          name: x.format('MM/YYYY'),
          series: mergeSeries([])
        }));

      const datat = summary.map(
        x => ({
          name: moment(x.timestamp).format('MM/YYYY'),
          series: mergeSeries(x.data)
        })
      );

      const t1 = new Map(minData.map(x => [x.name, x]));
      const t2 = new Map(datat.map(x => [x.name, x]));
      setSummaryData(Array.from((new Map([...Array.from(t1), ...Array.from(t2)])).values()).slice(-12));


    } catch (error) {
      setSummaryData([]);
      createErrorPopup("Impossible de récupérer les données", String(error));
    } finally {
      setIsLoading(false);
    }
  }, [])


  useEffect(() => {
    if (selectedUser)
      fetchUserAppStats(selectedUser.id);

  }, [selectedUser, fetchUserAppStats]);

  async function fetchUsers() {
    setIsLoading(true);

    try {
      const resUsers = await getAllUsers();
      const users: IUser[] = await unpackResponse(resUsers);

      setAllUsers(users.filter(u => u.role === UserRole.RESIDENT));

    } catch (error) {
      createErrorPopup("Impossible de récupérer les données", String(error));
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (

    <Stack horizontal tokens={{childrenGap: 20}} verticalAlign="start">
      <Stack>
        <DetailsList
          selection={userSelection}
          selectionMode={SelectionMode.single}
          columns={userColumns}
          items={allUsers}
        />
      </Stack>
      <Separator vertical />
      {
        (selectedUser && summaryData.length) &&
        <Stack tokens={{childrenGap: 20}}>
          <Text variant="mediumPlus">
            Utilisation des Application, en heures pour {selectedUser?.firstname} {selectedUser?.lastname}
          </Text>
          <div style={{width: '650px', height: '400px'}}>
            <GroupedVerticalBarChart
              data={summaryData}
              width={650}
              height={400}
              showYAxisGridLines
              yAxisTickCount={10}
              barwidth={43}
            />
          </div>
        </Stack>
      }
    </Stack>
  );
};
