import {
  DefaultButton,
  DetailsList,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IColumn,
  IconButton,
  IDialogContentProps,
  IDropdownOption,
  Image,
  ImageFit,
  mergeStyles,
  Pivot,
  PivotItem,
  PrimaryButton,
  Selection,
  SelectionMode,
  SpinButton,
  Stack,
  StackItem,
  Text,
  TextField
} from "@fluentui/react";
import { useBoolean } from '@fluentui/react-hooks';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createOneAssetBeacon,
  createOneRoomBeacon,
  deleteOneAssetBeacon,
  deleteOneRoomBeacon,
  getAllAssetBeacons,
  getAllBeaconPlaces,
  getAllBeaconScanners,
  getAllRoomBeacons,
  getAllUsers,
  modifyOneAssetBeacon,
  modifyOneRoomBeacon
} from '../api/actions';

import { IAssetBeacon, IBeaconBase, IBeaconScanner, IRoom, IRoomBeacon, IUser, UserRole } from '../api/types';
import { handleError } from "../helpers/requests";
import { errorSlice } from "../slices/errorSlice";
import { RootState } from "../slices";


export const Beacons: React.FC = () => {

  return (
    <Stack>
      <Pivot>
        <PivotItem headerText="Balises fixes" itemIcon="Hotel"><RoomBeacons /></PivotItem>
        <PivotItem headerText="Balises mobiles" itemIcon="People"><AssetBeacons /></PivotItem>
        <PivotItem headerText="Scanners" itemIcon="Streaming"><BeaconScanners /></PivotItem>
      </Pivot>
    </Stack>
  );
};


interface IEditRoomBeaconDialogProps {
  rooms: IRoom[];
  selection: IRoomBeacon | undefined;
  onSave: () => void;
  onCancel: () => void;
}

interface IDeleteRoomBeaconDialogProps {
  selection: IRoomBeacon;
  onSave: () => void;
  onCancel: () => void;
}

const DeleteRoomBeaconDialog: React.FC<IDeleteRoomBeaconDialogProps> = (props: IDeleteRoomBeaconDialogProps) => {

  const dispatch = useDispatch();
  const [isPostPending, setIsPostPending] = React.useState(false);

  const contentProps: IDialogContentProps = {
    type: DialogType.largeHeader,
    title: "Supprimer un Beacon",
    subText: "Êtes-vous sûr de vouloir supprimer ce beacon",
  };

  async function onDeleteRoomBeaconSubmit(onDone: () => void, onFailed: () => void): Promise<void> {

    setIsPostPending(true);

    let success = false;

    try {
      const res = await deleteOneRoomBeacon(props.selection.room_id, props.selection.id);
      const json = await res.json();
      if (res.ok) {
        success = true;
      } else {
        dispatch(errorSlice.actions.push({
          title: "Erreur lors de la requête",
          message: json.detail,
        }));
      }
      success = true;
    } catch (err) {
      console.log(err);
      handleError(err as Error);
    }
    setIsPostPending(false);
    if (success)
      onDone();
    else
      onFailed();
  }

  return (
    <Dialog
      hidden={false}
      onDismiss={props.onCancel}
      modalProps={{isBlocking: true, styles: {main: {maxWidth: 450}}}}
      dialogContentProps={contentProps}
    >
      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            onDeleteRoomBeaconSubmit(
              () => {
                props.onSave();
              },
              () => {
                props.onCancel();
              }).then();
          }}
          disabled={isPostPending}
          text="Enregistrer"
        />
        <DefaultButton
          onClick={props.onCancel}
          disabled={isPostPending}
          text="Annuler"
        />
      </DialogFooter>
    </Dialog>
  );
};

const EditRoomBeaconDialog: React.FC<IEditRoomBeaconDialogProps> = (props: IEditRoomBeaconDialogProps) => {

  const dispatch = useDispatch();
  const [isPostPending, setIsPostPending] = React.useState(false);
  const [selectedRoom, setSelectedRoom] = React.useState<IRoom>();
  const [appId,] = React.useState(props.selection ? props.selection.application_id : "50424443-4b2d-4245-4143-4f4e2d415050");
  const [major, setMajor] = React.useState(props.selection ? props.selection.major : 1);
  const [minor, setMinor] = React.useState(props.selection ? props.selection.minor : 1);

  const contentProps: IDialogContentProps = {
    type: DialogType.largeHeader,
    title: props.selection ? "Modifier un beacon" : "Nouveau Beacon",
    subText: props.selection ? "Modifier les paramètres du beacon" : "Renseigner les paramètres du nouveau beacon",
  };

  const roomOptions: IDropdownOption[] = React.useMemo(() =>
    props.rooms.map(p => ({key: p.id, text: p.label})), [props]);

  const onSelectionChanged = React.useCallback((
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption | undefined
  ): void => {
    if (item)
      setSelectedRoom(props.rooms.find(p => p.id === item.key));
  }, [props]);

  const onChangeMajor = React.useCallback((event: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
    if (newValue !== undefined) {
      const mv = Number.parseInt(newValue);
      if (!isNaN(mv))
        setMajor(mv);
    }
  }, []);

  const onChangeMinor = React.useCallback((event: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
    if (newValue !== undefined) {
      const mv = Number.parseInt(newValue);
      if (!isNaN(mv))
        setMinor(mv);
    }
  }, []);

  useEffect(() => {

    const selection = props.selection;
    if (selection) {
      const room = props.rooms.find(x => x.id = selection.room_id);
      setSelectedRoom(room);
    }

  }, [props.rooms, props.selection]);


  async function onAddOrEditRoomBeaconSubmit(
    room_id: number, app_id: string,
    major: number, minor: number, onDone: () => void, onFailed: () => void
  ): Promise<void> {

    setIsPostPending(true);
    const postData: IBeaconBase = {
      application_id: app_id,
      major: major,
      minor: minor
    };
    let success = false;

    try {
      let res: Response;
      let json: any;

      if (props.selection) {
        res = await modifyOneRoomBeacon(room_id, props.selection.id, postData);
        json = await res.json();

      } else {
        res = await createOneRoomBeacon(room_id, postData);
        json = await res.json();
      }
      if (res.ok) {
        success = true;
      } else {
        dispatch(errorSlice.actions.push({
          title: "Erreur lors de la requête",
          message: json.detail,
        }));
      }
      success = true;
    } catch (err) {
      console.log(err);
      handleError(err as Error);
    }
    setIsPostPending(false);
    if (success)
      onDone();
    else
      onFailed();
  }

  return (
    <Dialog
      hidden={false}
      onDismiss={props.onCancel}
      modalProps={{isBlocking: true, styles: {main: {maxWidth: 450}}}}
      dialogContentProps={contentProps}
    >
      <Stack tokens={{childrenGap: 20}} style={{paddingBottom: 30}}>
        <Dropdown
          label="Nom de la pièce"
          defaultSelectedKey={props.selection ? props.selection.room_id : undefined}
          options={roomOptions}
          styles={{dropdown: {width: 300}}}
          onChange={onSelectionChanged}
          disabled={props.selection !== undefined}
        />
        <TextField
          label="Identifiant applicatif"
          value={appId}
          readOnly
        />
        <Stack horizontal tokens={{childrenGap: 50}}>
          <SpinButton
            label="Majeur"
            value={major.toString()}
            min={0}
            max={255}
            step={1}
            onChange={onChangeMajor}
          />
          <SpinButton
            label="Mineur"
            value={minor.toString()}
            min={0}
            max={255}
            step={1}
            onChange={onChangeMinor}
          />
        </Stack>
      </Stack>
      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            if (selectedRoom)
              onAddOrEditRoomBeaconSubmit(selectedRoom.id,
                appId, major, minor,
                () => {
                  props.onSave();
                },
                () => {
                  props.onCancel();
                }).then();
          }}
          disabled={isPostPending || !selectedRoom}
          text="Enregistrer"
        />
        <DefaultButton
          onClick={props.onCancel}
          disabled={isPostPending}
          text="Annuler"
        />
      </DialogFooter>
    </Dialog>
  );
};

const RoomBeacons: React.FC = () => {

  const isAdmin = useSelector((state: RootState) => state.user.isAdmin)
  const defaultImageId = "FloorOne";
  const [, setIsLoading] = useState(true);
  const [selectedBeacon, setSelectedBeacon] = useState<IRoomBeacon>();
  const [selectedImage, setSelectedImage] = useState<IRoom>();
  const [beacons, setBeacons] = useState<IRoomBeacon[]>([]);
  const [rooms, setRooms] = useState<IRoom[]>([]);
  const [hideAddDialog, {toggle: toggleHideAddDialog}] = useBoolean(true);
  const [hideEditDialog, {toggle: toggleHideEditDialog}] = useBoolean(true);
  const [hideDeleteDialog, {toggle: toggleHideDeleteDialog}] = useBoolean(true);

  function imagePath(room: IRoom | undefined) {
    if (room)
      return `beacons/room/${room.image_id}.png`;
    else
      return undefined;
  }

  const listStyle = mergeStyles({
    flex: "1 1 auto",
    overflowX: "hidden",
    overflowY: "auto",
    maxHeight: '100%',
  });

  const columns: IColumn[] = React.useMemo(() => [
    {
      key: "id",
      name: "#",
      fieldName: "id",
      minWidth: 25,
      maxWidth: 25,
    }, {
      key: "appId",
      name: "Id. Applicatif",
      fieldName: "application_id",
      minWidth: 220,
      maxWidth: 220,
    }, {
      key: "major",
      name: "Id. Majeur",
      fieldName: "major",
      minWidth: 70,
      maxWidth: 70,
    }, {
      key: "minor",
      name: "Id. Mineur",
      fieldName: "minor",
      minWidth: 70,
      maxWidth: 70,
    }, {
      key: "roomId",
      name: "Lieu",
      fieldName: "room_id",
      minWidth: 70,
      maxWidth: 70,
    },
  ], []);


  function fetchBeacons() {
    setIsLoading(true);

    getAllRoomBeacons()
      .then(res => res.json())
      .then(data => {
        setBeacons(data);
        setIsLoading(false);
      })
      .catch(err => handleError(err));
  }

  function fetchRooms() {
    setIsLoading(true);
    getAllBeaconPlaces()
      .then(res => res.json())
      .then(data => {
        setRooms(data);
        setIsLoading(false);
      })
      .catch(err => handleError(err));
  }


  useEffect(() => {
    fetchRooms();
    fetchBeacons();
  }, []);

  useEffect(() => {
    setSelectedBeacon(undefined);

  }, [beacons]);

  useEffect(() => {
    setSelectedImage(rooms.find(x => x.image_id === defaultImageId));

    console.log(rooms);

  }, [rooms]);

  useEffect(() => {

    if (selectedBeacon) {
      console.log(rooms);
      const img = rooms.find((x) => (x.id === selectedBeacon.room_id));

      console.log(img);
      setSelectedImage(img);
    }

  }, [selectedBeacon, rooms]);

  const selection = new Selection({
    onSelectionChanged: () => {
      const beacon = selection.count ? selection.getSelection()[0] as IRoomBeacon : undefined;
      setSelectedBeacon(beacon);
    }
  });

  return (
    <Stack tokens={{childrenGap: 20}}>

      <Stack tokens={{childrenGap: 20}}>
        <StackItem align="center" style={{paddingBottom: 30}}>
          <Text variant={"large"} style={{textDecorationLine: 'underline'}}>Gestion des balises fixes</Text>
        </StackItem>
        <Stack horizontal tokens={{childrenGap: 20}} verticalAlign="center">
          <StackItem grow={2} align="center" style={{width: 300}}>
            <Stack tokens={{childrenGap: 20}}>
              <div className={listStyle} data-is-scrollable="true">
                <DetailsList
                  items={beacons}
                  columns={columns}
                  selectionMode={SelectionMode.single}
                  selection={selection}
                />
              </div>
              <Stack
                horizontal tokens={{childrenGap: 20}} verticalAlign="center"
                horizontalAlign="center"
              >
                <IconButton
                  iconProps={{iconName: "Add"}}
                  disabled={!isAdmin}
                  onClick={toggleHideAddDialog}
                />
                <IconButton
                  iconProps={{iconName: "Cancel"}}
                  disabled={!isAdmin || !selectedBeacon}
                  onClick={toggleHideDeleteDialog}
                />
                <IconButton
                  iconProps={{iconName: "Edit"}}
                  disabled={!isAdmin || !selectedBeacon}
                  onClick={toggleHideEditDialog}
                />
              </Stack>
            </Stack>
          </StackItem>
          <StackItem grow={1} align="center">
            <Stack horizontal>
              <Image
                src={imagePath(selectedImage)}
                height={300}
                {...{imageFit: ImageFit.contain}}
              />
            </Stack>
          </StackItem>
        </Stack>
      </Stack>
      {
        !hideAddDialog && <EditRoomBeaconDialog {...{
          rooms: rooms,
          selection: undefined,
          onSave: () => {
            fetchBeacons();
            toggleHideAddDialog();
          },
          onCancel: () => {
            toggleHideAddDialog();
          }
        }} />
      }
      {
        !hideEditDialog && selectedBeacon && <EditRoomBeaconDialog {...{
          rooms: rooms,
          selection: selectedBeacon,
          onSave: () => {
            fetchBeacons();
            toggleHideEditDialog();
          },
          onCancel: () => {
            toggleHideEditDialog();
          }
        }} />
      }
      {
        !hideDeleteDialog && selectedBeacon && <DeleteRoomBeaconDialog {...{
          selection: selectedBeacon,
          onSave: () => {
            fetchBeacons();
            toggleHideDeleteDialog();
          },
          onCancel: () => {
            toggleHideDeleteDialog();
          }
        }} />
      }
    </Stack>
  );

};


interface IEditAssetBeaconDialogProps {
  users: IUser[];
  selection: IAssetBeacon | undefined;
  onSave: () => void;
  onCancel: () => void;
}

interface IDeleteAssetBeaconDialogProps {
  selection: IAssetBeacon;
  onSave: () => void;
  onCancel: () => void;
}

const DeleteAssetBeaconDialog: React.FC<IDeleteAssetBeaconDialogProps> = (props: IDeleteAssetBeaconDialogProps) => {

  const dispatch = useDispatch();
  const [isPostPending, setIsPostPending] = React.useState(false);

  const contentProps: IDialogContentProps = {
    type: DialogType.largeHeader,
    title: "Supprimer un Beacon",
    subText: "Êtes-vous sûr de vouloir supprimer ce beacon",
  };

  async function onDeleteRoomBeaconSubmit(onDone: () => void, onFailed: () => void): Promise<void> {

    setIsPostPending(true);
    let success = false;

    try {
      const res = await deleteOneAssetBeacon(props.selection.user_id, props.selection.id);
      const json = await res.json();
      if (res.ok) {
        success = true;
      } else {
        dispatch(errorSlice.actions.push({
          title: "Erreur lors de la requête",
          message: json.detail,
        }));
      }
      success = true;
    } catch (err) {
      console.log(err);
      handleError(err as Error);
    }
    setIsPostPending(false);
    if (success)
      onDone();
    else
      onFailed();
  }

  return (
    <Dialog
      hidden={false}
      onDismiss={props.onCancel}
      modalProps={{isBlocking: true, styles: {main: {maxWidth: 450}}}}
      dialogContentProps={contentProps}
    >
      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            onDeleteRoomBeaconSubmit(
              () => {
                props.onSave();
              },
              () => {
                props.onCancel();
              }).then();
          }}
          disabled={isPostPending}
          text="Enregistrer"
        />
        <DefaultButton
          onClick={props.onCancel}
          disabled={isPostPending}
          text="Annuler"
        />
      </DialogFooter>
    </Dialog>
  );
};

const EditAssetBeaconDialog: React.FC<IEditAssetBeaconDialogProps> = (props: IEditAssetBeaconDialogProps) => {

  const dispatch = useDispatch();
  const [isPostPending, setIsPostPending] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<IUser>();
  const [appId,] = React.useState(props.selection ? props.selection.application_id : "50424443-4b2d-4245-4143-4f4e2d415051");
  const [major, setMajor] = React.useState(props.selection ? props.selection.major : 1);
  const [minor, setMinor] = React.useState(props.selection ? props.selection.minor : 1);

  const contentProps: IDialogContentProps = {
    type: DialogType.largeHeader,
    title: props.selection ? "Modifier un beacon" : "Nouveau Beacon",
    subText: props.selection ? "Modifier les paramètres du beacon" : "Renseigner les paramètres du nouveau beacon",
  };

  const userOptions: IDropdownOption[] = React.useMemo(() =>
    props.users.map(p => ({key: p.id, text: `${p.firstname} ${p.lastname}`})), [props]);

  const onSelectionChanged = React.useCallback((
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption | undefined
  ): void => {
    if (item)
      setSelectedUser(props.users.find(p => p.id === item.key));
  }, [props]);

  const onChangeMajor = React.useCallback((event: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
    if (newValue !== undefined) {
      const mv = Number.parseInt(newValue);
      if (!isNaN(mv))
        setMajor(mv);
    }
  }, []);

  const onChangeMinor = React.useCallback((event: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
    if (newValue !== undefined) {
      const mv = Number.parseInt(newValue);
      if (!isNaN(mv))
        setMinor(mv);
    }
  }, []);

  useEffect(() => {

    const selection = props.selection;
    if (selection) {
      const user = props.users.find(x => x.id = selection.user_id);
      setSelectedUser(user);
    }

  }, [props.selection, props.users]);


  async function onAddOrEditAssetBeaconSubmit(
    user_id: number, app_id: string,
    major: number, minor: number, onDone: () => void, onFailed: () => void
  ): Promise<void> {

    setIsPostPending(true);
    const postData: IBeaconBase = {
      application_id: app_id,
      major: major,
      minor: minor
    };
    let success = false;

    try {
      let res: Response;
      let json: any;

      if (props.selection) {
        res = await modifyOneAssetBeacon(user_id, props.selection.id, postData);
        json = await res.json();
      } else {
        res = await createOneAssetBeacon(user_id, postData);
        json = await res.json();
      }

      if (res.ok) {
        success = true;
      } else {
        dispatch(errorSlice.actions.push({
          title: "Erreur lors de la requête",
          message: json.detail,
        }));
      }
      success = true;
    } catch (err) {
      console.log(err);
      handleError(err as Error);
    }
    setIsPostPending(false);
    if (success)
      onDone();
    else
      onFailed();
  }

  return (
    <Dialog
      hidden={false}
      onDismiss={props.onCancel}
      modalProps={{isBlocking: true, styles: {main: {maxWidth: 450}}}}
      dialogContentProps={contentProps}
    >
      <Stack tokens={{childrenGap: 20}} style={{paddingBottom: 30}}>
        <Dropdown
          label="Résident porteur du beacon"
          defaultSelectedKey={props.selection ? props.selection.user_id : undefined}
          options={userOptions}
          styles={{dropdown: {width: 300}}}
          onChange={onSelectionChanged}
          disabled={props.selection !== undefined}
        />
        <TextField
          label="Identifiant applicatif"
          value={appId}
          readOnly
        />
        <Stack horizontal tokens={{childrenGap: 50}}>
          <SpinButton
            label="Majeur"
            value={major.toString()}
            min={0}
            max={255}
            step={1}
            onChange={onChangeMajor}
          />
          <SpinButton
            label="Mineur"
            value={minor.toString()}
            min={0}
            max={255}
            step={1}
            onChange={onChangeMinor}
          />
        </Stack>
      </Stack>
      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            if (selectedUser)
              onAddOrEditAssetBeaconSubmit(selectedUser.id,
                appId, major, minor,
                () => {
                  props.onSave();
                },
                () => {
                  props.onCancel();
                }).then();
          }}
          disabled={isPostPending || !selectedUser}
          text="Enregistrer"
        />
        <DefaultButton
          onClick={props.onCancel}
          disabled={isPostPending}
          text="Annuler"
        />
      </DialogFooter>
    </Dialog>
  );
};

const AssetBeacons: React.FC = () => {

  const isAdmin = useSelector((state: RootState) => state.user.isAdmin)
  const [, setIsLoading] = useState(true);
  const [selectedBeacon, setSelectedBeacon] = useState<IAssetBeacon>();
  const [beacons, setBeacons] = useState<IAssetBeacon[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [hideAddDialog, {toggle: toggleHideAddDialog}] = useBoolean(true);
  const [hideEditDialog, {toggle: toggleHideEditDialog}] = useBoolean(true);
  const [hideDeleteDialog, {toggle: toggleHideDeleteDialog}] = useBoolean(true);


  const listStyle = mergeStyles({
    flex: "1 1 auto",
    overflowX: "hidden",
    overflowY: "auto",
    maxHeight: '100%',
  });

  function userLabel(users: IUser[], beacon: IAssetBeacon | undefined) {
    if (beacon) {
      const user = users.find(x => x.id === beacon.user_id);
      if (user)
        return `${user.firstname} ${user.lastname}`;
    }
    return "";
  }

  const columns: IColumn[] = React.useMemo(() => [
    {
      key: "id",
      name: "#",
      fieldName: "id",
      minWidth: 25,
      maxWidth: 25,
    }, {
      key: "appId",
      name: "Id. Applicatif",
      fieldName: "application_id",
      minWidth: 220,
      maxWidth: 220,
    }, {
      key: "major",
      name: "Id. Majeur",
      fieldName: "major",
      minWidth: 70,
      maxWidth: 70,
    }, {
      key: "minor",
      name: "Id. Mineur",
      fieldName: "minor",
      minWidth: 70,
      maxWidth: 70,
    }, {
      key: "user",
      name: "Résident",
      minWidth: 70,
      maxWidth: 70,
      onRender: (item: IAssetBeacon) => {
        console.log(item);
        console.log(users);
        return (<Text>{userLabel(users, item)}</Text>);
      }
    },
  ], [users]);


  function fetchBeacons() {
    setIsLoading(true);
    getAllAssetBeacons()
      .then(res => res.json())
      .then(data => {
        setBeacons(data);
        setIsLoading(false);
      })
      .catch(err => handleError(err));
  }

  function fetchUsers() {
    setIsLoading(true);
    getAllUsers()
      .then(res => res.json())
      .then((data: IUser[]) => {
        setUsers(data.filter(x => x.role === UserRole.RESIDENT));
        setIsLoading(false);
      })
      .catch(err => handleError(err));
  }


  useEffect(() => {
    fetchUsers();
    fetchBeacons();
  }, []);

  useEffect(() => {
    setSelectedBeacon(undefined);

  }, [beacons]);


  const selection = new Selection({
    onSelectionChanged: () => {
      const beacon = selection.count ? selection.getSelection()[0] as IAssetBeacon : undefined;
      setSelectedBeacon(beacon);
      console.log(beacon);
    }
  });

  return (
    <Stack tokens={{childrenGap: 20}}>

      <Stack tokens={{childrenGap: 20}}>
        <StackItem align="center" style={{paddingBottom: 30}}>
          <Text variant={"large"} style={{textDecorationLine: 'underline'}}>Gestion des balises mobiles</Text>
        </StackItem>
        <Stack horizontal tokens={{childrenGap: 20}} verticalAlign="center">
          <StackItem grow={2} align="center" style={{width: 300}}>
            <Stack tokens={{childrenGap: 20}}>
              <div className={listStyle} data-is-scrollable="true">
                <DetailsList
                  items={beacons}
                  columns={columns}
                  selectionMode={SelectionMode.single}
                  selection={selection}
                />
              </div>
              <Stack
                horizontal tokens={{childrenGap: 20}} verticalAlign="center"
                horizontalAlign="center"
              >
                <IconButton
                  iconProps={{iconName: "Add"}}
                  disabled={!isAdmin}
                  onClick={toggleHideAddDialog}
                />
                <IconButton
                  iconProps={{iconName: "Cancel"}}
                  disabled={!isAdmin || !selectedBeacon}
                  onClick={toggleHideDeleteDialog}
                />
                <IconButton
                  iconProps={{iconName: "Edit"}}
                  disabled={!isAdmin || !selectedBeacon}
                  onClick={toggleHideEditDialog}
                />
              </Stack>
            </Stack>
          </StackItem>
        </Stack>
      </Stack>
      {
        !hideAddDialog && <EditAssetBeaconDialog {...{
          users: users,
          selection: undefined,
          onSave: () => {
            fetchUsers();
            fetchBeacons();
            toggleHideAddDialog();
          },
          onCancel: () => {
            toggleHideAddDialog();
          }
        }} />
      }
      {
        !hideEditDialog && selectedBeacon && <EditAssetBeaconDialog {...{
          users: users,
          selection: selectedBeacon,
          onSave: () => {
            fetchUsers();
            fetchBeacons();
            toggleHideEditDialog();
          },
          onCancel: () => {
            toggleHideEditDialog();
          }
        }} />
      }
      {
        !hideDeleteDialog && selectedBeacon && <DeleteAssetBeaconDialog {...{
          selection: selectedBeacon,
          onSave: () => {
            fetchBeacons();
            toggleHideDeleteDialog();
          },
          onCancel: () => {
            toggleHideDeleteDialog();
          }
        }} />
      }
    </Stack>
  );

};

const BeaconScanners: React.FC = () => {
  const defaultImageId = "FloorOne";
  const [, setIsLoading] = useState(true);
  const [selectedScanner, setSelectedScanner] = useState<IBeaconScanner>();
  const [selectedImage, setSelectedImage] = useState<IRoom>();
  const [scanners, setScanners] = useState<IBeaconScanner[]>([]);
  const [rooms, setRooms] = useState<IRoom[]>([]);

  function imagePath(room: IRoom | undefined) {
    if (room)
      return `beacons/room/${room.image_id}.png`;
    else
      return undefined;
  }

  const listStyle = mergeStyles({
    flex: "1 1 auto",
    overflowX: "hidden",
    overflowY: "auto",
    maxHeight: '100%',
  });

  const columns: IColumn[] = React.useMemo(() => [
    {
      key: "id",
      name: "#",
      fieldName: "id",
      minWidth: 25,
      maxWidth: 25,
    }, {
      key: "macId",
      name: "Adresse MAC",
      fieldName: "mac_id",
      minWidth: 220,
      maxWidth: 220,
    }, {
      key: "roomId",
      name: "Lieu",
      fieldName: "room_id",
      minWidth: 70,
      maxWidth: 70,
    },
  ], []);


  function fetchScanners() {
    setIsLoading(true);
    getAllBeaconScanners()
      .then(res => res.ok ? res.json() : Promise.resolve([]))
      .then(data => {
        setScanners(data);
        setIsLoading(false);
      })
      .catch(err => handleError(err));
  }

  function fetchRooms() {
    setIsLoading(true);
    getAllBeaconPlaces()
      .then(res => res.json())
      .then(data => {
        setRooms(data);
        setIsLoading(false);
      })
      .catch(err => handleError(err));
  }


  useEffect(() => {
    fetchRooms();
    fetchScanners();
  }, []);

  useEffect(() => {
    setSelectedScanner(undefined);

  }, [scanners]);

  useEffect(() => {
    setSelectedImage(rooms.find(x => x.image_id === defaultImageId));

    console.log(rooms);

  }, [rooms]);

  useEffect(() => {

    if (selectedScanner) {
      console.log(rooms);
      const img = rooms.find((x) => (x.id === selectedScanner.room_id));

      console.log(img);
      setSelectedImage(img);
    }

  }, [selectedScanner, rooms]);

  const selection = new Selection({
    onSelectionChanged: () => {
      const scanner = selection.count ? selection.getSelection()[0] as IBeaconScanner : undefined;
      setSelectedScanner(scanner);
    }
  });

  return (
    <Stack tokens={{childrenGap: 20}}>

      <Stack tokens={{childrenGap: 20}}>
        <StackItem align="center" style={{paddingBottom: 30}}>
          <Text variant={"large"} style={{textDecorationLine: 'underline'}}>Scanner de beacons mobiles</Text>
        </StackItem>
        <Stack horizontal tokens={{childrenGap: 20}} verticalAlign="center">
          <StackItem grow={2} align="center" style={{width: 300}}>
            <Stack tokens={{childrenGap: 20}}>
              <div className={listStyle} data-is-scrollable="true">
                <DetailsList
                  items={scanners}
                  columns={columns}
                  selectionMode={SelectionMode.single}
                  selection={selection}
                />
              </div>
            </Stack>
          </StackItem>
          <StackItem grow={1} align="center">
            <Stack horizontal>
              <Image
                src={imagePath(selectedImage)}
                height={300}
                {...{imageFit: ImageFit.contain}}
              />
            </Stack>
          </StackItem>
        </Stack>
      </Stack>
    </Stack>
  );
};
