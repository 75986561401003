import { createSlice } from "@reduxjs/toolkit";


const configSlice = createSlice({
  name: "config",
  initialState: {
    idpUrl: "",
    userProfileUrl: "",
    clientId: "",
    realmName: "",
    realmAdministrationUrl: "",
  },
  reducers: {
    setOauthConfig: (state, action) => {
      state.idpUrl = action.payload.idpUrl;
      state.userProfileUrl = action.payload.userProfileUrl;
      state.clientId = action.payload.clientId;
      state.realmName = action.payload.realmName;
      state.realmAdministrationUrl = action.payload.realmAdministrationUrl;
    }
  },
});

export { configSlice };
