import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, Text } from "@fluentui/react";
import React from "react";

import { ActionMode, actionModeToString } from "./types";


interface IGenericActionDialogProps {
  // Boolean that hides the dialog
  isHidden: boolean;
  // Function that hides the dialog. Used on validation and cancellation.
  hide: () => void;
  // ActionMode that specifies the use of the dialog (creation of modification)
  actionMode: ActionMode;
  // Function called on dialog validation (not cancellation)
  onSubmit: () => void;
  // Name of the resource being added/edited. Used in the title.
  genericName: string;
  // String displayed in red. Disables the primary button if defined.
  errorText?: string;
  // Boolean that also display a "Remove" button on "EDIT" mode (hidden if undefined)
  displayRemove?: boolean;
  // Function called on the optional "Remove" button press
  onRemovePress?: () => void;
}

// A dialog used to add or edit resources.
// All components set as children will be displayed inside this dialog. Use them
// to specify fields and logic used modifying your temporary object.
export const GenericActionDialog: React.FC<IGenericActionDialogProps> = (props) => {
  const actionMode = props.actionMode;
  const actionModeStr = actionModeToString(actionMode);

  return (
    <Dialog
      hidden={props.isHidden}
      modalProps={{isBlocking: true}}
      onDismiss={props.hide}
      dialogContentProps={{
        type: DialogType.largeHeader,
        title: actionModeStr + " " + props.genericName,
      }}
    >
      {props.children}
      {props.errorText && (
        <Text styles={{root: {color: "red"}}}>{props.errorText}</Text>
      )}
      <DialogFooter>
        <PrimaryButton
          text={actionModeStr}
          disabled={props.errorText !== undefined}
          onClick={props.onSubmit}
        />
        {((props.displayRemove ?? false) && actionMode !== ActionMode.ADD) && (
          <PrimaryButton
            text={"Supprimer"}
            onClick={props.onRemovePress}
          />
        )}
        <DefaultButton text={"Annuler"} onClick={props.hide} />
      </DialogFooter>
    </Dialog>
  );
};

interface IGenericRemoveDialogProps {
  // Boolean that hides the dialog
  isHidden: boolean;
  // Function that hides the dialog. Used on validation and cancellation.
  hide: () => void;
  // String used in title
  title: string;
  // Function called on dialog validation (not cancellation)
  onSubmit: () => void;
}

// A dialog used to ask confirmation before deleting resources.
// All components set as children will be displayed inside this dialog. Use them
// to ask the user for deletion and warn it of consequences.
export const GenericRemoveDialog: React.FC<IGenericRemoveDialogProps> = (props) => {
  return (
    <Dialog
      hidden={props.isHidden}
      modalProps={{isBlocking: true}}
      onDismiss={props.hide}
      dialogContentProps={{type: DialogType.largeHeader, title: props.title}}
    >
      {props.children}
      <DialogFooter>
        <PrimaryButton text={"Supprimer"} onClick={props.onSubmit} />
        <DefaultButton text={"Annuler"} onClick={props.hide} />
      </DialogFooter>
    </Dialog>
  );
};
