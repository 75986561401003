import { useKeycloak } from '@react-keycloak/web';
import { KeycloakInstance } from "keycloak-js";
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import HomePage from '../pages/Home';
import LoginPage from '../pages/Login';
import { RootState, userSlice } from "../slices";


// Redirect on pages according to the given routes.
export const AppRouter = () => {
  const {initialized, keycloak} = useKeycloak();
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.user.token);

  React.useEffect(() => {
    const token: any = keycloak.idTokenParsed;
    if (token) {
      dispatch(userSlice.actions.setUserInfo({
        username: token.preferred_username,
        lastname: token.family_name,
        firstname: token.given_name,
        email: token.email,
      }));
    }
  }, [dispatch, initialized, keycloak]);

  if (!initialized || token === "") {
    return (
      <div>
        <p>Loading...</p>
      </div>
    );
  }

  let home = <RequireAuth redirectTo="/login" redirectFrom="/home" auth={keycloak}>
    <HomePage />
  </RequireAuth>;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/home" element={home} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/actions" element={home} />
        <Route path="/iamlost" element={home} />
        <Route path="/users" element={home} />
        <Route path="/devices" element={home} />
        <Route path="/nfc" element={home} />
        <Route path="/beacons" element={home} />
        <Route path="/apps" element={home} />
        <Route path="/loc" element={home} />
        <Route path="/plannings" element={home} />
        <Route path="/welcome-screen" element={home} />
        <Route path="/scanwalls" element={home} />
        <Route path="/404" element={<p>404</p>} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </BrowserRouter>
  );
};

function RequireAuth({
                       children,
                       redirectTo,
                       redirectFrom,
                       auth
                     }: { children: JSX.Element, redirectTo: string, redirectFrom: string, auth: KeycloakInstance }) {
  let isAuthenticated = auth.authenticated;
  return isAuthenticated ? children : <Navigate to={redirectTo} state={{from: redirectFrom}}/>;
}
