import {
  createTheme,
  DefaultButton,
  Image,
  ImageFit,
  ITheme,
  mergeStyleSets,
  Modal,
  Pivot,
  PivotItem,
  PrimaryButton,
  Stack,
  Text,
  TextField
} from "@fluentui/react";
import React from "react";
import { deleteOneImage, getAllImagesFromCategory, uploadOneImage } from "../api/actions";

import { IImage } from "../api/types";
import { createErrorPopup } from "../helpers/errors";
import { unpackResponse } from "../helpers/requests";


interface IImageSelectorProps {
  category: string;
  value: string;
  onChange: (value: string) => void;
  label?: string;
}

export const ImageSelector: React.FC<IImageSelectorProps> = (props) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  // Library tab
  const [tmpURL, setTmpURL] = React.useState("");
  const [allImages, setAllImages] = React.useState<IImage[]>([]);
  // Image upload tab
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = React.useState<File>();

  const fetchImages = React.useCallback(() => {
    getAllImagesFromCategory(props.category).then(unpackResponse).then(
      json => setAllImages(json),
      err => createErrorPopup("Erreur lors du chargement des images", err.message),
    );
  }, [props.category]);

  function sendDeleteImage(image_id: number): void {
    deleteOneImage(image_id).then(unpackResponse).then(
      json => fetchImages(),
      err => createErrorPopup("Erreur lors de la suppresion d'une image", err.message),
    );
  }

  // Called on submit button press (library tab)
  function onImageChoice(): void {
    setIsModalOpen(false);
    props.onChange(tmpURL);
  }

  function onImageDelete(): void {
    const found = allImages.find(img => img.url === tmpURL);
    if (found === undefined) {
      console.warn("No image selected.");
      return;
    }
    sendDeleteImage(found.id);
  }

  // Called on file selection in the "upload image" tab
  const onFileSelection: React.ChangeEventHandler<HTMLInputElement> = event => {
    const files = event.target.files;
    if (files === null || files.length === 0) {
      return;
    }
    const file = files[0];
    setSelectedFile(file);
  };

  // Called on submit button press (upload image tab)
  function onUploadClick(): void {
    if (selectedFile === undefined) {
      console.warn("Can't upload when no file is selected");
      return;
    }
    uploadOneImage(props.category, selectedFile).then(unpackResponse).then(
      json => {
        setIsModalOpen(false);
        props.onChange(json.url);
      },
      err => {
        createErrorPopup("Erreur lors de l'envoi de l'image", err.message);
      },
    );
  }

  React.useEffect(() => {
    setTmpURL(props.value);
  }, [props.value]);

  React.useEffect(() => {
    if (isModalOpen) {
      fetchImages();
    }
  }, [fetchImages, isModalOpen]);

  return (
    <>
      <Stack horizontal verticalAlign={"end"} tokens={{childrenGap: 10}}>
        <TextField
          label={props.label ?? "Image"}
          readOnly
          value={props.value}
        />
        <PrimaryButton
          text={"Changer"}
          iconProps={{iconName: "installation"}}
          onClick={() => setIsModalOpen(true)}
        />
      </Stack>
      <Modal
        isOpen={isModalOpen}
        isBlocking={true}
        onDismiss={() => setIsModalOpen(false)}
        containerClassName={classes.container}
      >
        <Stack
          styles={{root: {margin: 10}}}
          tokens={{childrenGap: 10}}
        >
          <Text variant={"large"}>Sélectionner une image</Text>
          <Pivot>
            <PivotItem headerText={"Bibliothèque"} itemIcon={"PictureLibrary"}>
              <Stack styles={imagesGridStyles}>
                {allImages.map(image => (
                  <Image
                    key={image.id}
                    src={image.url}
                    imageFit={ImageFit.contain}
                    onClick={() => setTmpURL(image.url)}
                    styles={image.url === tmpURL ? {root: {border: "3px solid #0078d4"}} : {root: {padding: 3}}}
                  />
                ))}
              </Stack>
              <Stack horizontal horizontalAlign={"space-between"}>
                <PrimaryButton
                  text={"Supprimer"}
                  disabled={tmpURL === ""}
                  theme={redButtonTheme}
                  onClick={onImageDelete}
                />
                <Stack horizontal horizontalAlign={"end"} tokens={{childrenGap: 5}}>
                  <PrimaryButton text={"Choisir"} disabled={tmpURL === ""} onClick={onImageChoice} />
                  <DefaultButton text={"Annuler"} onClick={() => setIsModalOpen(false)} />
                </Stack>
              </Stack>
            </PivotItem>
            <PivotItem headerText={"Ajout d'une nouvelle image"} itemIcon={"MediaAdd"}>
              {/*
                This input is needed for the whole file selection but it is ugly.
                Thus it is hidden, and opened on TextField click.
              */}
              <Stack tokens={{childrenGap: 10}}>
                <input
                  type={"file"}
                  accept="image/*" // Only show images in the file explorer
                  ref={fileInputRef}
                  onChange={onFileSelection}
                  style={{display: "none"}} // Hidden
                />
                <PrimaryButton
                  text={"Sélectionner un fichier"}
                  onClick={() => fileInputRef.current !== null && fileInputRef.current.click()}
                />
                <Text>{selectedFile === undefined ? "Pas d'image sélectionnée" : selectedFile.name}</Text>
                <Stack horizontal horizontalAlign={"end"} tokens={{childrenGap: 5}}>
                  <PrimaryButton text={"Envoyer"} disabled={selectedFile === undefined} onClick={onUploadClick} />
                  <DefaultButton text={"Annuler"} onClick={() => setIsModalOpen(false)} />
                </Stack>
              </Stack>
            </PivotItem>
          </Pivot>
        </Stack>
      </Modal>
    </>
  );
};

const imagesGridStyles = {
  root: {
    display: "grid",
    margin: 10,
    padding: 10,
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
    gridAutoRows: "50%",
    gridGap: "10px",
    height: "50vh",
    border: "1px solid darkgrey",
    borderRadius: 2,
    overflowY: "scroll",
  }
};

const redButtonTheme: ITheme = createTheme({
  palette: {
    themePrimary: '#a4373a',
    themeDarkAlt: '#933235',
    themeDark: '#7c2a2d',
  }
});

const classes = mergeStyleSets({
  // Fix an issue with the modal scrolling
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
});

