import {makeDeleteRequest, makeGetRequest, makePostRequestWithFormData, makeRequestWithJson} from "../helpers/requests";
import {
  IAbsencePatch,
  IAbsencePost,
  IBeaconBase,
  IBeaconScannerBase,
  ICheckListItemPatch,
  ICheckListItemPost,
  ICheckListProfilePatch,
  ICheckListProfilePost,
  IDayEventsPost,
  IMontageImagePatch,
  IMontageImagePost,
  IPlacePatch,
  IPlacePost,
  IRecurringTaskPost,
  ITaskPatch,
  ITaskPost,
  ITaskTypePatch,
  ITaskTypePost,
  ITmpAlertConfiguration,
  IUserCheckListProfileExceptionPost,
  IUserCheckListProfilePost,
  IUserPatch,
  IUserPost
} from "./types";


export const API_URL = "/api"; // Use relative URLs

export function getConfig(): Promise<Response> {
  return makeGetRequest(API_URL + "/config");
}

export function getIsAdmin(): Promise<Response> {
  return makeGetRequest(API_URL + "/admin");
}

export function getVersion(): Promise<Response> {
  return makeGetRequest(API_URL + "/version");
}

// Images

export function getAllImagesFromCategory(category: string): Promise<Response> {
  return makeGetRequest(API_URL + "/image/" + category);
}

export function deleteOneImage(id: number): Promise<Response> {
  return makeRequestWithJson(API_URL + "/image/" + id, "DELETE", {});
}

// "I am lost" app

export function getAllAlerts(): Promise<Response> {
  return makeGetRequest(API_URL + "/iamlost/alert");
}

export function getActiveAlerts(): Promise<Response> {
  return makeGetRequest(API_URL + "/iamlost/alert/active");
}

export function setAlertAcknowledgement(alertId: number): Promise<Response> {
  return makeDeleteRequest(API_URL + "/iamlost/alert/" + alertId);
}

export function getAlertConfiguration(): Promise<Response> {
  return makeGetRequest(API_URL + "/iamlost/configuration");
}

export function saveAlertConfiguration(newConfiguration: ITmpAlertConfiguration): Promise<Response> {
  return makeRequestWithJson(API_URL + "/iamlost/configuration", "PATCH", newConfiguration);
}

export function getAlertConfigurationModifications(): Promise<Response> {
  return makeGetRequest(API_URL + "/iamlost/configuration_history");
}

// Plannings

export function getAllPlaces(): Promise<Response> {
  return makeGetRequest(API_URL + "/screens/place");
}

export function createOnePlace(place: IPlacePost): Promise<Response> {
  return makeRequestWithJson(API_URL + "/screens/place", "POST", place);
}

export function modifyOnePlace(id: number, data: IPlacePatch): Promise<Response> {
  return makeRequestWithJson(API_URL + "/screens/place/" + id, "PATCH", data);
}

export function deleteOnePlace(id: number): Promise<Response> {
  return makeRequestWithJson(API_URL + "/screens/place/" + id, "DELETE", {});
}

export function getAllTaskTypes(): Promise<Response> {
  return makeGetRequest(API_URL + "/screens/tasktype");
}

export function createOneTaskType(taskType: ITaskTypePost): Promise<Response> {
  return makeRequestWithJson(API_URL + "/screens/tasktype", "POST", taskType);
}

export function modifyOneTaskType(id: number, data: ITaskTypePatch): Promise<Response> {
  return makeRequestWithJson(API_URL + "/screens/tasktype/" + id, "PATCH", data);
}

export function deleteOneTaskType(id: number): Promise<Response> {
  return makeRequestWithJson(API_URL + "/screens/tasktype/" + id, "DELETE", {});
}

export function getUserRecurringTasks(user_id: number, start?: string, end?: string): Promise<Response> {
  let queryStr = ""
  if(start && !end) {
    queryStr = `?start=${start}`;
  }
  if(!start && end) {
    queryStr = `?end=${end}`;
  }
  if(start && end) {
    queryStr = `?start=${start}&end=${end}`;
  }
  return makeGetRequest(API_URL + "/screens/recurring_task/user/" + user_id + queryStr);
}

export function getUserTasks(user_id: number, start?: string, end?: string): Promise<Response> {
  let queryStr = ""
  if(start && !end) {
    queryStr = `?start=${start}`;
  }
  if(!start && end) {
    queryStr = `?end=${end}`;
  }
  if(start && end) {
    queryStr = `?start=${start}&end=${end}`;
  }
  return makeGetRequest(API_URL + "/screens/task/user/" + user_id + queryStr);
}

export function createOneTask(task: ITaskPost): Promise<Response> {
  return makeRequestWithJson(API_URL + "/screens/task", "POST", task);
}

export function modifyOneTask(id: number, task: ITaskPatch): Promise<Response> {
  return makeRequestWithJson(API_URL + "/screens/task/" + id, "PATCH", task);
}

export function deleteOneTask(id: number): Promise<Response> {
  return makeRequestWithJson(API_URL + "/screens/task/" + id, "DELETE", {});
}

export function createAbsence(absence: IAbsencePost): Promise<Response> {
  return makeRequestWithJson(API_URL + "/screens/resident/absence", "POST", absence);
}

export function modifyAbsence(id: number, absence: IAbsencePatch): Promise<Response> {
  return makeRequestWithJson(API_URL + "/screens/resident/absence/" + id, "PATCH", absence);
}

export function deleteAbsence(id: number): Promise<Response> {
  return makeRequestWithJson(API_URL + "/screens/resident/absence/" + id, "DELETE", {});
}

export function createOneRecurringTask(recurringTask: IRecurringTaskPost): Promise<Response> {
  return makeRequestWithJson(API_URL + "/screens/recurring_task", "POST", recurringTask);
}

export function modifyOneRecurringTask(id: number, recurringTask: IRecurringTaskPost): Promise<Response> {
  return makeRequestWithJson(API_URL + "/screens/recurring_task/" + id, "PATCH", recurringTask);
}

export function deleteOneRecurringTask(id: number): Promise<Response> {
  return makeRequestWithJson(API_URL + "/screens/recurring_task/" + id, "DELETE", {});
}

export function getResidentAbsence(user_id: number, start?: string, end?: string): Promise<Response> {
  let queryStr = ""
  if(start && !end) {
    queryStr = `?start=${start}`;
  }
  if(!start && end) {
    queryStr = `?end=${end}`;
  }
  if(start && end) {
    queryStr = `?start=${start}&end=${end}`;
  }
  return makeGetRequest(API_URL + "/screens/resident/" + user_id + "/absence" + queryStr);
}

// Images

export function uploadOneImage(category: string, file: File): Promise<Response> {
  const data = new FormData();
  data.append("category", category);
  data.append("file", file);
  return makePostRequestWithFormData(API_URL + "/image/", data);
}

export function getAllMontageImages(): Promise<Response> {
  return makeGetRequest(API_URL + "/screens/montage/image");
}

export function createOneMontageImage(image: IMontageImagePost): Promise<Response> {
  return makeRequestWithJson(API_URL + "/screens/montage/image", "POST", image);
}

export function modifyOneMontageImage(id: number, data: IMontageImagePatch): Promise<Response> {
  return makeRequestWithJson(API_URL + "/screens/montage/image/" + id, "PATCH", data);
}

export function deleteOneMontageImage(id: number): Promise<Response> {
  return makeRequestWithJson(API_URL + "/screens/montage/image/" + id, "DELETE", {});
}

// Users

export function getOneUser(id: number): Promise<Response> {
  return makeGetRequest(API_URL + "/user/" + id);
}

export function getAllUsers(): Promise<Response> {
  return makeGetRequest(API_URL + "/user");
}

export function createOneUser(user: IUserPost): Promise<Response> {
  return makeRequestWithJson(API_URL + "/user", "POST", user);
}

export function modifyOneUser(id: number, user: IUserPatch): Promise<Response> {
  return makeRequestWithJson(API_URL + "/user/" + id, "PATCH", user);
}

export function deleteOneUser(id: number): Promise<Response> {
  return makeRequestWithJson(API_URL + "/user/" + id, "DELETE", {});
}

export function getAllProfiles(): Promise<Response> {
  return makeGetRequest(API_URL + "/actions/checklist/profile/");
}

export function createOneProfile(profile: ICheckListProfilePost): Promise<Response> {
  return makeRequestWithJson(API_URL + "/actions/checklist/profile", "POST", profile);
}

export function deleteOneProfile(id: number): Promise<Response> {
  return makeRequestWithJson(API_URL + "/actions/checklist/profile/" + id, "DELETE", {});
}

export function modifyOneProfile(id: number, profile: ICheckListProfilePatch): Promise<Response> {
  return makeRequestWithJson(API_URL + "/actions/checklist/profile/" + id, "PATCH", profile);
}

export function createOneCheckListItem(profile_id: number, item: ICheckListItemPost): Promise<Response> {
  return makeRequestWithJson(`${API_URL}/actions/checklist/profile/${profile_id}/checkitem/`, "POST", item);
}

export function deleteOneCheckListItem(profile_id: number, item_id: number): Promise<Response> {
  return makeRequestWithJson(`${API_URL}/actions/checklist/profile/${profile_id}/checkitem/${item_id}`, "DELETE", {});
}

export function modifyOneCheckListItem(profile_id: number, item_id: number, profile: ICheckListItemPatch): Promise<Response> {
  return makeRequestWithJson(`${API_URL}/actions/checklist/profile/${profile_id}/checkitem/${item_id}`, "PATCH", profile);
}


export function getOneUserProfile(user_id: number): Promise<Response> {
  return makeGetRequest(`${API_URL}/actions/checklist/${user_id}`);
}

export function createOneUserProfile(userProfile: IUserCheckListProfilePost): Promise<Response> {
  return makeRequestWithJson(`${API_URL}/actions/checklist/`, "POST", userProfile);
}

export function deleteOneUserProfile(user_id: number): Promise<Response> {
  return makeRequestWithJson(`${API_URL}/actions/checklist/${user_id}`, "DELETE", {});
}

export function createOneUserProfileException(user_id: number, exception: IUserCheckListProfileExceptionPost): Promise<Response> {
  return makeRequestWithJson(`${API_URL}/actions/checklist/${user_id}/exception`, "POST", exception);
}

export function deleteOneUserProfileException(user_id: number, exception_id: number): Promise<Response> {
  return makeRequestWithJson(`${API_URL}/actions/checklist/${user_id}/exception/${exception_id}`, "DELETE", {});
}

export function getAllAssetBeacons(): Promise<Response> {
  return makeGetRequest(`${API_URL}/beacons/asset/`);
}

export function createOneAssetBeacon(user_id: number, beacon: IBeaconBase): Promise<Response> {
  return makeRequestWithJson(`${API_URL}/beacons/asset/${user_id}`, "POST", beacon);
}

export function modifyOneAssetBeacon(user_id: number, beacon_id: number, beacon: IBeaconBase): Promise<Response> {
  return makeRequestWithJson(`${API_URL}/beacons/asset/${user_id}/${beacon_id}`, "PATCH", beacon);
}

export function deleteOneAssetBeacon(user_id: number, beacon_id: number): Promise<Response> {
  return makeRequestWithJson(`${API_URL}/beacons/asset/${user_id}/${beacon_id}`, "DELETE", {});
}

export function getAllRoomBeacons(): Promise<Response> {
  return makeGetRequest(`${API_URL}/beacons/room/`);
}

export function getAllBeaconPlaces(): Promise<Response> {
  return makeGetRequest(`${API_URL}/beacons/place/`);
}

export function createOneRoomBeacon(room_id: number, beacon: IBeaconBase): Promise<Response> {
  return makeRequestWithJson(`${API_URL}/beacons/room/${room_id}`, "POST", beacon);
}

export function modifyOneRoomBeacon(room_id: number, beacon_id: number, beacon: IBeaconBase): Promise<Response> {
  return makeRequestWithJson(`${API_URL}/beacons/room/${room_id}/${beacon_id}`, "PATCH", beacon);
}

export function deleteOneRoomBeacon(room_id: number, beacon_id: number): Promise<Response> {
  return makeRequestWithJson(`${API_URL}/beacons/room/${room_id}/${beacon_id}`, "DELETE", {});
}

export function getAllBeaconScanners(): Promise<Response> {
  return makeGetRequest(`${API_URL}/beacons/scanner`);
}

export function createOneBeaconScanner(scanner: IBeaconScannerBase): Promise<Response> {
  return makeRequestWithJson(`${API_URL}/beacons/scanner`, "POST", scanner);
}

export function modifyOneBeaconScanner(id: number, scanner: IBeaconScannerBase): Promise<Response> {
  return makeRequestWithJson(`${API_URL}/beacons/scanner/${id}`, "PATCH", scanner);
}

export function deleteOneBeaconScanner(id: number): Promise<Response> {
  return makeRequestWithJson(`${API_URL}/beacons/scanner/${id}`, "DELETE", {});
}

export function getAllBeaconScannerEvents(): Promise<Response> {
  return makeGetRequest(`${API_URL}/beacons/scanner/event/`);
}

export function getOneDayEvents(date: string): Promise<Response> {
  return makeGetRequest(`${API_URL}/screens/general_info/events/${date}`);
}

export function createOneDayEvents(day_events: IDayEventsPost): Promise<Response> {
  return makeRequestWithJson(`${API_URL}/screens/general_info/events`, "POST", day_events);
}

export function patchOneDayEvents(date: string, day_events: IDayEventsPost): Promise<Response> {
  return makeRequestWithJson(`${API_URL}/screens/general_info/events/${date}`, "PATCH", day_events);
}

export function deleteOneDayEvents(date: string): Promise<Response> {
  return makeRequestWithJson(`${API_URL}/screens/general_info/events/${date}`, "DELETE", {});
}

export function getAppUsageStatsSummary(user_id: number): Promise<Response> {
  return makeGetRequest(`${API_URL}/appusage/summary/${user_id}`);
}
