import { DetailsList, IColumn, ISelection, SelectionMode } from "@fluentui/react";
import React from "react";


interface IGenericTable {
  // List of items to show in the table
  items: any[];
  // List of columns to display the table. Don't provide sorting props.
  columns: IColumn[];
  // Selection object to handle single selection
  selection: ISelection;
}

// A table that support single item selection and sorting by column.
export const GenericTable: React.FC<IGenericTable> = (props) => {
  const [sortingKey, setSortingKey] = React.useState("");
  const [sortingFieldName, setSortingFieldName] = React.useState("");
  const [isSortingIncreasing, setIsSortingIncreasing] = React.useState(true);

  // Add the sorting props to the given columns
  const editedColumns: IColumn[] = React.useMemo(() => {
    return props.columns.map(col => ({
      ...col,
      isSorted: sortingKey === col.key,
      isSortedDescending: isSortingIncreasing,
      onColumnClick: (ev, clickedCol) => {
        // The user clicked on the same column
        if (clickedCol.key === sortingKey) {
          setSortingKey(clickedCol.key);
          setSortingFieldName(clickedCol.fieldName ?? "");
          // Invert the sorting order
          setIsSortingIncreasing(!isSortingIncreasing);
        } else {
          setSortingKey(clickedCol.key);
          setSortingFieldName(clickedCol.fieldName ?? "");
          setIsSortingIncreasing(true);
        }
      },
    }));
  }, [props.columns, sortingKey, isSortingIncreasing]);

  // Sort the given items
  const sortedItems = React.useMemo(() => {
    const copiedItems = [...props.items];
    if (sortingFieldName === "") {
      return copiedItems;
    }

    function sortFunction<T>(a: T, b: T): number {
      const fieldName = sortingFieldName as keyof T;
      if (isSortingIncreasing) {
        return a[fieldName] < b[fieldName] ? -1 : 1;
      } else {
        return a[fieldName] > b[fieldName] ? -1 : 1;
      }
    }

    return copiedItems.sort(sortFunction);
  }, [props.items, sortingFieldName, isSortingIncreasing]);

  return (
    <DetailsList
      items={sortedItems}
      columns={editedColumns}
      selectionMode={SelectionMode.single}
      selectionPreservedOnEmptyClick={true}
      selection={props.selection}
      styles={{root: {marginBottom: 10}}}
    />
  );
};

