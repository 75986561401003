import {verifyOrder} from "../helpers/time";

// Validation helpers

const mailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const alphaNumRegExp = /^[0-9a-zA-Z]+$/;
const isWhitespaceRegExp = /^[\s]*$/;

// Return True if the string is a valid mail address.
function isMailAddress(str: string): boolean {
  return mailRegExp.test(str);
}

// Return True if the string is a entirely made of digits or classic letters.
function isAlphaNum(str: string): boolean {
  return alphaNumRegExp.test(str);
}

// Return True if the string is empty or entirely made of whitespace characters.
function isStrEmpty(str: string): boolean {
  return str === "" || isWhitespaceRegExp.test(str);
}

// Users

export enum UserRole {
  RESIDENT = "resident",
  STAFF = "staff",
  ADMIN = "admin",
}

export function roleToString(role?: UserRole): string {
  if (role === UserRole.RESIDENT) return "Résident";
  if (role === UserRole.STAFF) return "Membre du personnel";
  if (role === UserRole.ADMIN) return "Administrateur";
  if (role === undefined) return "Inconnu";
  throw new Error("Unknown user role : " + role);
}

export interface IUser {
  id: number;
  idp_id: string | null;
  username: string;
  email: string | null;
  firstname: string;
  lastname: string;
  role: UserRole;
  is_on_site: boolean;
  image_url: string | null;
  badge_uid: string | null;
}

export interface ITmpUser {
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  image_url: string;
  badge_uid: string;
  role: UserRole;
}

export interface IUserPost {
  username: string;
  email?: string | null;
  firstname: string;
  lastname: string;
  role: UserRole;
  image_url: string | null;
  badge_uid?: string | null;
}

export interface IUserPatch {
  email?: string | null;
  firstname?: string;
  lastname?: string;
  is_on_site?: boolean;
  image_url?: string | null;
  badge_uid?: string | null;
}


// Validate a ITmpUser. Returns an error message, undefined if good.
export function validateTmpUser(user: ITmpUser): string | undefined {
  if (isStrEmpty(user.username)) {
    return "Le nom d'utilisateur est vide.";
  }
  if (!isAlphaNum(user.username)) {
    return "Le nom d'utilisateur est invalide.";
  }
  if (isStrEmpty(user.email) && user.role !== UserRole.RESIDENT) {
    return "Une adresse mail est obligatoire pour les non-résidents.";
  }
  // Only test the following if the email is defined.
  if (user.email !== "" && !isMailAddress(user.email)) {
    return "L'adresse email n'est pas valide.";
  }
  if (isStrEmpty(user.firstname)) {
    return "Le prénom de l'utilisateur est vide.";
  }
  if (isStrEmpty(user.lastname)) {
    return "Le nom de famille de l'utilisateur est vide.";
  }
  if (user.badge_uid !== "" && user.role === UserRole.RESIDENT) {
    return "Un résident ne doit pas avoir de badge associé.";
  }
}

// Images

export interface IImage {
  id: number;
  url: string;
  category: string;
  is_used: boolean | null;
}

// Alerts

export interface IAlert {
  id: number;
  user_id: number;
  status: number;
  start: string;
  end: string | null;
  sms_emitted: boolean;
  email_emitted: boolean;
  acknowledge_by: number | null;
}

export interface IAlertConfiguration {
  send_sms_on_alert: boolean;
  phone_number: string | null;
  sms_sender: string | null;
  sms_service: string | null;
  send_email_on_alert: boolean;
  email_address: string | null;
  email_account: string | null;
  email_account_password: string | null;
  email_smtp_server_address: string | null;
  email_smtp_server_port: number | null;
}

export interface ITmpAlertConfiguration {
  send_sms_on_alert: boolean | null;
  phone_number: string | null;
  sms_sender: string | null;
  sms_service: string | null;
  send_email_on_alert: boolean | null;
  email_address: string | null;
  email_account: string | null;
  email_account_password: string | null;
  email_smtp_server_address: string | null;
  email_smtp_server_port: number | null;
}

export interface IAlertConfigurationModification {
  id: number;
  message: number;
  date: string;
}

// Checklists

export interface ICheckListItem {
  id: number,
  label: string
}

export interface ICheckListItemPost {
  label: string;
}

export interface ICheckListItemPatch {
  label: string;
}

export interface ICheckListProfile {
  id: number,
  label: string,
  description: string,
  items: ICheckListItem[]
}

export interface ICheckListProfilePost {
  label: string,
  description: string,
}

export interface ICheckListProfilePatch {
  label: string,
  description: string,
  enabled: boolean
}

export interface IUserCheckListProfilePost {
  user_id: number,
  profile_id: number
}

export interface IUserCheckListProfile {
  id: number,
  user_id: number,
  checklist: ICheckListProfile,
  exceptions: number[]
}

export interface IUserCheckListProfileExceptionPost {
  item_id: number;
}

// Plannings

export interface IPlace {
  id: number;
  name: string;
  image_url?: string;
}

export interface ITmpPlace {
  name: string;
  image_url: string;
}

export interface IPlacePost {
  name: string;
  image_url?: string | null;
}

export interface IPlacePatch {
  name?: string;
  image_url?: string | null;
}

// Validate a ITmpPlace. Returns an error message, undefined if good.
export function validatePlace(place: ITmpPlace): string | undefined {
  if (place.name === "") {
    return "Le nom du lieu est vide.";
  }
}

export interface ITaskType {
  id: number;
  name: string;
  image_url: string | null;
}

export interface ITmpTaskType {
  name: string;
  image_url: string;
}

export interface ITaskTypePost {
  name: string;
  image_url: string | null;
}

export interface ITaskTypePatch {
  name: string;
  image_url?: string | null;
}

// Validate a ITmpPlace. Returns an error message, undefined if good.
export function validateTmpTaskType(taskType: ITmpTaskType): string | undefined {
  if (taskType.name === "") {
    return "Le nom du type de tâche est vide.";
  }
}

export const recurring_type_list = [{
  key: "daily",
  text: "Quotidien",
},{
  key: "weekly",
  text: "Hebdomadaire",
},{
  key: "monthly",
  text: "Mensuel",
},{
  key: "yearly",
  text: "Annuel",
}]

export const default_day_of_week: IDayOfWeek = {
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: true,
  sunday: true,
}

export interface IDayOfWeek {
  monday?: boolean;
  tuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;
  saturday?: boolean;
  sunday?: boolean;
}

export interface ITask {
  id: number;
  user_id: number;
  type: ITaskType;
  place: IPlace;
  start: string;
  end: string;
  timer: number;
  recurring_task_id: number;
}

export interface IRecurringTask {
  id: number;
  recurring_type: string;
  start_period: string;
  end_period: string;
  start_task_time: string;
  end_task_time: string;
  timezone: string;
  user_id: number;
  type: ITaskType;
  place: IPlace;
  timer: number;
  day_of_week?: IDayOfWeek;
}

export interface IRecurringTaskPost {
  recurring_type: string;
  start_period: string;
  end_period: string;
  start_task_time: string;
  end_task_time: string;
  timezone: string;
  user_id: number;
  type_id: number;
  place_id: number;
  timer: number;
  day_of_week?: IDayOfWeek;
}

export interface ITmpTask {
  user_id: number;
  type_id: number;
  place_id: number;
  day: Date;
  start: string;
  end: string;
  start_period: string;
  end_period: string;
  start_task_time: string;
  end_task_time: string;
  timer: string;
  recurring_task: boolean;
  recurring_type?: string;
  day_of_week?: IDayOfWeek;
  absence: boolean;
  absence_all_day: boolean;
}

export interface IAbsence {
  id: number;
  user_id: number;
  start: string;
  end: string;
  all_day: boolean;
}

export interface IAbsencePost {
  user_id: number;
  start?: string;
  end?: string;
  all_day: boolean;
}

export interface IAbsencePatch {
  start?: string;
  end?: string;
  all_day?: boolean;
}

export interface ITaskPost {
  user_id: number;
  type_id: number;
  place_id: number;
  start: string;
  end: string;
  timer: number;
}

export interface ITaskPatch {
  type_id?: number;
  place_id?: number;
  start?: string;
  end?: string;
  timer?: number;
}

export interface ISelectedDateRange {
  start: string;
  end: string;
}

const timeRegExp = new RegExp("^(2[0-3]|[01][0-9]):[0-5][0-9]$");

// Validate a ITmpTask. Returns an error message, undefined if good.
export function validateTmpTask(task: ITmpTask): string | undefined {
  if(task.absence) {
    if (task.user_id <= 0) {
      return "L'utilisateur de la tâche n'est pas défini.";
    }
    if (!timeRegExp.test(task.start)) {
      return "L'heure de début n'est correcte (HH:MM).";
    }
    if (!timeRegExp.test(task.end)) {
      return "L'heure de fin n'est correcte (HH:MM).";
    }
  }
  else {
    if (task.user_id <= 0) {
      return "L'utilisateur de la tâche n'est pas défini.";
    }
    if (task.type_id <= 0) {
      return "Le type de la tâche n'est pas défini.";
    }
    if (task.place_id <= 0) {
      return "Le lieu de la tâche n'est pas défini.";
    }
    if (task.recurring_task) {
      if (!task.start_period) {
        return "La date de début n'est pas définie.";
      }
      if (!task.end_period) {
        return "La date de fin n'est pas définie.";
      }
    }
    if (!timeRegExp.test(task.start)) {
      return "L'heure de début n'est pas au format HH:MM.";
    }
    if (!timeRegExp.test(task.end)) {
      return "L'heure de fin n'est pas au format HH:MM.";
    }
    if (!verifyOrder(task.start, task.end)) {
      return "La date de fin n'est pas supérieure à celle de début.";
    }
    const timerCast = Number(task.timer);
    if (isNaN(timerCast) || timerCast <= 0) {
      return "Le décompte n'est pas un nombre valide.";
    }
  }
}

// Montage images

export interface IMontageImage {
  id: number;
  url: string;
}

export interface IMontageImagePost {
  url: string;
}

export interface IMontageImagePatch {
  url?: string;
}

export interface ITmpMontageImage {
  url: string;
}

// Validate a ITmpMontageImage. Returns an error message, undefined if good.
export function validateTmpMontageImage(image: ITmpMontageImage): string | undefined {
  if (image.url === "") {
    return "L'image doit être définie.";
  }
}

// Welcome screen

export interface IDayEventsPost {
  date: string;
  free_box_title: string | null;
  free_box_content: string | null;
  today_message: string | null;
  today_birthday: string | null;
}

export interface IBeaconBase {
  application_id: string;
  major: number;
  minor: number;
}

export interface IRoomBeacon extends IBeaconBase {
  id: number;
  room_id: number;
}

export interface IRoom {
  id: number;
  label: string;
  image_id: string;
}

export interface IAssetBeacon extends IBeaconBase {
  id: number;
  user_id: number;
}

export interface IBeaconScannerBase {
  mac_id: string;
  room_id: number;
}

export interface IBeaconScanner extends IBeaconScannerBase {
  id: number;
}


export interface IScannerEvent {
  id: number;
  scanner_id: number;
  beacon_id: number;
  timestamp: string;
}

export interface UsageStatsSummaryData {
  label: string;
  usage: number;
}

export interface UsageStatsSummary {
  timestamp: string;
  data: UsageStatsSummaryData[];
}
