import { store } from "../slices";


// Return the user JWT found in store.
function getJWT(): string {
  return store.getState().user.token;
}

// Create a get response using the user token.
export function makeGetRequest(url: string): Promise<Response> {
  const options = {
    headers: {
      Authorization: `Bearer ${getJWT()}`,
    },
  };
  return fetch(url, options);
}

export function makeDeleteRequest(url: string): Promise<Response> {
  const options = {
    method: "DELETE",
    headers: {
      "Authorization": `Bearer ${getJWT()}`,
    },
  };
  return fetch(url, options);
}

// Create a post response using the user token and the provided form data.
export function makePostRequestWithFormData(
  url: string,
  data: FormData,
): Promise<Response> {
  const options = {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${getJWT()}`,
    },
    body: data,
  };
  return fetch(url, options);
}

// Create a response using the user token and the provided json data.
export function makeRequestWithJson(
  url: string,
  method: string,
  data: object
): Promise<Response> {
  const options = {
    method: method,
    headers: {
      "Authorization": `Bearer ${getJWT()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return fetch(url, options);
}

// Default function to handle requests errors
export function handleError(error: Error): void {
  console.error("Handled error :", error);
}

// Return the proper JSON data from a request.
// If the request succeed, but is not a 2XX code, throws an error with the
// error message provided by the backend.
export async function unpackResponse(response: Response): Promise<any> {
  const json: any = await response.json();
  if (response.ok) {
    return json;
  } else {
    // The status code is not 2XX.
    throw new Error(json.detail);
  }
}
