import { Pivot, PivotItem } from "@fluentui/react";
import React from "react";

import { PlacesTab } from "./PlacesTab";
import { TasksTab } from "./TasksTab";
import { TaskTypesTab } from "./TaskTypesTab";


export const PlanningConfig: React.FC = () => {
  return (
    <Pivot>
      <PivotItem headerText="Tâches" itemIcon={"CalendarAgenda"}>
        <TasksTab />
      </PivotItem>
      <PivotItem headerText="Types de tâche" itemIcon={"TaskSolid"}>
        <TaskTypesTab />
      </PivotItem>
      <PivotItem headerText="Lieux" itemIcon={"Photo2"}>
        <PlacesTab />
      </PivotItem>
    </Pivot>
  );
};
