import { store } from "../slices";
import { errorSlice } from "../slices/errorSlice";


export function createErrorPopup(title: string, message: string): void {
  store.dispatch(errorSlice.actions.push({
    title: title,
    message: message,
  }));
}
