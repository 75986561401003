import {
  Calendar, DayOfWeek,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  FontWeights,
  IColumn,
  Icon,
  IIconProps,
  Image,
  ImageFit,
  IStackItemStyles,
  Label,
  Link,
  Pivot,
  PivotItem,
  PrimaryButton,
  Selection,
  Separator,
  Spinner,
  Stack,
  StackItem,
  Text,
  TextField,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import React from "react";

import {
  createOneDayEvents,
  createOneMontageImage,
  deleteOneDayEvents,
  deleteOneMontageImage,
  getAllMontageImages,
  getOneDayEvents,
  patchOneDayEvents,
} from "../api/actions";
import { IMontageImage, IMontageImagePost, ITmpMontageImage, validateTmpMontageImage } from "../api/types";
import { GenericActionBar } from "../components/genericconfig/GenericActionBar";
import { GenericActionDialog, GenericRemoveDialog } from "../components/genericconfig/GenericActionDialog";
import { GenericTable } from "../components/genericconfig/GenericTable";
import { ActionMode } from "../components/genericconfig/types";
import { ImageSelector } from "../components/ImageSelector";
import { createErrorPopup } from "../helpers/errors";
import { frCalendarStrings } from "../helpers/frCalendarStrings";
import { unpackResponse } from "../helpers/requests";


const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 'fitContent',
    justifyContent: 'center',
    paddingTop: 30,
  },
};

const textfieldStyles = {
  color: 'black',
  backgroundColor: '#fbfbfb',
};

const deleteButtonStyle = {
  color: 'white',
  backgroundColor: '#ff1d1d'
};

const dateFormatOption: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric'
};

const add: IIconProps = {iconName: 'Add', style: {fontSize: 20, }};
const birthdayCake = <Icon iconName="BirthdayCake" />;
const todayMsg = <Icon iconName="GotoToday" />;
const freeBoxTitleIcon = <Icon iconName="Comment" />;
const freeBoxContentIcon = <Icon iconName="CommentPrevious" />;
const edit: IIconProps = {iconName: 'Edit'};
const deleteIcon: IIconProps = {iconName: 'Delete'};
const cancel: IIconProps = {iconName: 'Cancel'};
const check: IIconProps = {iconName: 'CheckMark'};

export const WelcomeScreenConfig: React.FunctionComponent = () => {
  return (
    <Pivot>
      <PivotItem headerText={"Informations générales"}>
        <GeneralInfoTab />
      </PivotItem>
      <PivotItem headerText={"Pêle-mêle d'images"}>
        <PhotoMontageTab />
      </PivotItem>
    </Pivot>
  );
};

const GeneralInfoTab: React.FunctionComponent = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState<Date>();
  const [eventPlanned, setEventPlanned] = React.useState(0);
  const [pastDate, setPastDate] = React.useState(0);
  const [hideDialog, {toggle: toggleHideDialog}] = useBoolean(true);

  const [eventFreeBoxTitle, setEventFreeBoxTitle] = React.useState('');
  const [eventFreeBoxContent, setEventFreeBoxContent] = React.useState('');
  const [eventMsg, setEventMsg] = React.useState('');
  const [eventBirthday, setEventBirthday] = React.useState('');

  const [modifyEventDisabled, {toggle: toggleModifyEventDisabled}] = useBoolean(true);
  const [hideDeleteDialog, {toggle: toggleHideDeleteDialog}] = useBoolean(true);

  const addEventsdialogContentProps = {
    type: DialogType.normal,
    title: 'Ajouter un évément le ' + selectedDate?.toLocaleDateString('fr-FR', dateFormatOption),
    closeButtonAriaLabel: 'Close',
    subText: 'Les champs ne sont pas obligatoires. Laissez vide si nécessaire.',
  };

  const deleteEventsdialogContentProps = {
    type: DialogType.normal,
    title: 'Êtes-vous certain de supprimer les événements du ' + selectedDate?.toLocaleDateString('fr-FR', dateFormatOption) + ' ?',
    closeButtonAriaLabel: 'Close',
  };

  function isDateBeforeToday(date: Date) {
    let today = new Date();
    today.setHours(0, 0, 0);
    return date < today;
  }

  function getDayEvents(date: Date) {
    setIsLoading(true);
    getOneDayEvents(date.toISOString().split("T")[0])
      .then(res => {
        if (res.status === 404) {
          setEventPlanned(0);
        } else {
          setEventPlanned(1);
        }
        return res.json();
      })
      .then(eventData => {
        console.log(eventData);
        setEventFreeBoxTitle(eventData.free_box_title ?? '');
        setEventFreeBoxContent(eventData.free_box_content ?? '');
        setEventMsg(eventData.today_message ?? '');
        setEventBirthday(eventData.today_birthday ?? '');
        setIsLoading(false);
      })
      .catch(err => {
        createErrorPopup("Erreur lors du chargement des événements", err);
      });
  }

  function addEvents() {
    let body = {
      "date": selectedDate!.toISOString().split("T")[0],
      "free_box_title": eventFreeBoxTitle ?? null,
      "free_box_content": eventFreeBoxContent ?? null,
      "today_message": eventMsg ?? null,
      "today_birthday": eventBirthday ?? null
    };

    createOneDayEvents(body)
      .then(res => res.json())
      .then(eventData => {
        console.log(eventData);
        getDayEvents(selectedDate!);
      })
      .catch(err => {
        createErrorPopup("Erreur lors de l'ajout des événements", err);
      });
  }

  function modifyEvents() {
    let body = {
      "date": selectedDate!.toISOString().split("T")[0],
      "free_box_title": eventFreeBoxTitle ?? null,
      "free_box_content": eventFreeBoxContent ?? null,
      "today_message": eventMsg ?? null,
      "today_birthday": eventBirthday ?? null
    };

    patchOneDayEvents(selectedDate!.toISOString().split("T")[0], body)
      .then(res => res.json())
      .then(eventData => {
        console.log(eventData);
        getDayEvents(selectedDate!);
      })
      .catch(err => {
        createErrorPopup("Erreur lors de la modification de l'événement", err);
      });
  }

  function deleteEvents() {
    deleteOneDayEvents(selectedDate!.toISOString().split("T")[0])
      .then(res => res.json())
      .then(eventData => {
        console.log(eventData);
        getDayEvents(selectedDate!);
      })
      .catch(err => {
        createErrorPopup("Erreur lors de la suppression de l'événement", err);
      });
  }

  function resetEventState() {
    setEventFreeBoxTitle('');
    setEventFreeBoxContent('');
    setEventMsg('');
    setEventBirthday('');
    getDayEvents(selectedDate!);
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <Stack style={{marginBottom: 40}}>
        <StackItem align="center">
          <Text variant={"xLarge"}>Informations générales de l'écran d'accueil</Text>
        </StackItem>
        <StackItem align="center">
          <Text variant={"large"}>
            Sélectionnez une date dans le calendrier pour consulter les événements
            affichés sur l'écran d'accueil
          </Text>
        </StackItem>
      </Stack>

      <Stack horizontal horizontalAlign="space-evenly">
        <Stack.Item grow={1} styles={stackItemStyles}>
          <StackItem>
            <Calendar
              firstDayOfWeek={DayOfWeek.Monday}
              showMonthPickerAsOverlay
              highlightSelectedMonth
              showGoToToday={false}
              onSelectDate={(date) => {
                date.setHours(12, 0, 0);
                setSelectedDate(date);
                setPastDate(+ isDateBeforeToday(date));
                getDayEvents(date);
              }}
              value={selectedDate}
              strings={frCalendarStrings}
              styles={{root: {transform: 'scale(1.3)'}}}
            />
          </StackItem>
        </Stack.Item>
        <Separator vertical styles={{root: {selectors: {"::after": {backgroundColor: "black"}}}}} />
        <Stack.Item grow={3} styles={stackItemStyles}>
          <Stack tokens={{childrenGap: 10, }} horizontalAlign="center">
            {selectedDate ?
              <Stack tokens={{childrenGap: 10, }} horizontalAlign="center">
                <Text variant={"large"} styles={{root: {fontWeight: FontWeights.semibold}}}>
                  {selectedDate.toLocaleDateString('fr-FR', dateFormatOption)}
                </Text>
                {eventPlanned === 1
                  ? <Stack style={{width: '30vw'}} tokens={{childrenGap: 15}}>
                    <Label>{todayMsg} Message du jour</Label>
                    <TextField
                      style={textfieldStyles}
                      disabled={modifyEventDisabled}
                      defaultValue={eventMsg}
                      onChange={(e, newText) => {
                        setEventMsg(newText ?? '');
                      }}
                    />
                    <Label>{birthdayCake} Anniversaire du jour</Label>
                    <TextField
                      style={textfieldStyles}
                      disabled={modifyEventDisabled}
                      defaultValue={eventBirthday}
                      onChange={(e, newText) => {
                        setEventBirthday(newText ?? '');
                      }}
                    />
                    <Label>{freeBoxTitleIcon} Titre de la case libre</Label>
                    <TextField
                      style={textfieldStyles}
                      disabled={modifyEventDisabled}
                      defaultValue={eventFreeBoxTitle}
                      onChange={(e, newText) => {
                        setEventFreeBoxTitle(newText ?? '');
                      }}
                    />
                    <Label>{freeBoxContentIcon} Contenu de la case libre</Label>
                    <TextField
                      style={textfieldStyles}
                      disabled={modifyEventDisabled}
                      defaultValue={eventFreeBoxContent}
                      onChange={(e, newText) => {
                        setEventFreeBoxContent(newText ?? '');
                      }}
                    />
                    <Stack horizontalAlign="center">
                      {modifyEventDisabled
                        ? <Stack horizontal tokens={{childrenGap: 10}}>
                          {!pastDate && <DefaultButton iconProps={edit} onClick={toggleModifyEventDisabled} text="Modifier" />}
                          <DefaultButton
                            style={deleteButtonStyle}
                            iconProps={deleteIcon}
                            text="Supprimer"
                            onClick={toggleHideDeleteDialog}
                          />
                        </Stack>
                        : <Stack horizontal tokens={{childrenGap: 10}}>
                          <PrimaryButton
                            iconProps={check} text="Modifier"
                            onClick={() => {
                              modifyEvents();
                              toggleModifyEventDisabled();
                            }}
                          />
                          <DefaultButton
                            iconProps={cancel} text="Annuler"
                            onClick={() => {
                              resetEventState();
                              toggleModifyEventDisabled();
                            }}
                          />
                        </Stack>
                      }
                    </Stack>
                  </Stack>
                  : <Stack style={{width: '30vw'}} tokens={{childrenGap: 20, }} horizontalAlign="center">
                    <Text variant={"large"}>Aucun événement prévu ce jour</Text>
                    {
                      !pastDate
                      && <DefaultButton
                        iconProps={add}
                        secondaryText="Ajouter des événements"
                        onClick={toggleHideDialog}
                        text="Ajouter"
                      />
                    }
                  </Stack>
                }
              </Stack>
              : <Text variant={"large"}>Sélectionnez une date dans la calendrier</Text>
            }
          </Stack>
        </Stack.Item>
      </Stack>

      <Dialog hidden={hideDialog} onDismiss={toggleHideDialog} dialogContentProps={addEventsdialogContentProps}>
        <TextField
          label="Message du jour" onChange={(e, newText) => {
            setEventMsg(newText ?? '');
          }}
        />
        <TextField
          label="Anniversaire du jour" onChange={(e, newText) => {
            setEventBirthday(newText ?? '');
          }}
        />
        <Separator
          styles={{
            root: {
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: 10,
              width: '50%',
              alignItems: 'center',
              selectors: {"::before": {backgroundColor: "#878787"}}
            }
          }}
        />
        <TextField
          label="Titre de la case libre" onChange={(e, newText) => {
            setEventFreeBoxTitle(newText ?? '');
          }}
        />
        <TextField
          label="Contenu de la case libre" onChange={(e, newText) => {
            setEventFreeBoxContent(newText ?? '');
          }}
        />
        <DialogFooter>
          <PrimaryButton
            iconProps={check} text="Ajouter"
            onClick={() => {
              addEvents();
              getDayEvents(selectedDate!);
              toggleHideDialog();
            }}
          />
          <DefaultButton iconProps={cancel} onClick={toggleHideDialog} text="Annuler" />
        </DialogFooter>
      </Dialog>

      <Dialog
        hidden={hideDeleteDialog}
        onDismiss={toggleHideDeleteDialog}
        dialogContentProps={deleteEventsdialogContentProps}
      >
        <DialogFooter>
          <DefaultButton
            style={deleteButtonStyle} iconProps={deleteIcon} text="Supprimer"
            onClick={() => {
              deleteEvents();
              toggleHideDeleteDialog();
            }}
          />
          <DefaultButton iconProps={cancel} onClick={toggleHideDeleteDialog} text="Annuler" />
        </DialogFooter>
      </Dialog>
    </div>
  );
};

const montageImageColumns: IColumn[] = [
  {key: "id", name: "ID", fieldName: "id", minWidth: 20, maxWidth: 40},
  {
    key: "image",
    name: "Apercu",
    minWidth: 100,
    maxWidth: 100,
    onRender: (item) => <Image src={item.url} height={50} width={100} imageFit={ImageFit.contain} />
  },
  {
    key: "url",
    name: "URL de l'image",
    fieldName: "url",
    minWidth: 200,
    onRender: (item) => <Link href={item.url} target={"_blank"}>{item.url}</Link>
  },
];
const DEFAULT_MONTAGE_IMAGE: ITmpMontageImage = {url: ""};

const PhotoMontageTab: React.FC = () => {
  const [allImages, setAllImages] = React.useState<IMontageImage[]>([]);
  const [selectedImage, setSelectedImage] = React.useState<IMontageImage>();
  const [actionMode, setActionMode] = React.useState(ActionMode.ADD);
  const [isActionDialogHidden, setIsActionDialogHidden] = React.useState(true);
  const [tmpImage, setTmpImage] = React.useState(DEFAULT_MONTAGE_IMAGE);
  const validationError = validateTmpMontageImage(tmpImage);

  const selection = React.useMemo(() => {
    return new Selection({
      onSelectionChanged: () => {
        const allSelected: IMontageImage[] = selection.getSelection() as IMontageImage[];
        const firstSelected = allSelected.length !== 0 ? allSelected[0] : undefined;
        setSelectedImage(firstSelected);
      },
    });
  }, []);

  function fetchImages(): void {
    getAllMontageImages().then(unpackResponse).then(
      json => setAllImages(json),
      err => createErrorPopup("Erreur lors du chargement des images", err.message),
    );
  }

  function sendPostRequest(): void {
    const postData: IMontageImagePost = {url: tmpImage.url};
    createOneMontageImage(postData).then(unpackResponse).then(
      _ => fetchImages(),
      err => createErrorPopup("Erreur lors de l'ajout", err.message),
    );
  }

  function sendDeleteRequest(): void {
    if (selectedImage === undefined) {
      console.warn("Can't delete if no image is selected");
      return;
    }
    deleteOneMontageImage(selectedImage.id).then(unpackResponse).then(
      _ => fetchImages(),
      err => createErrorPopup("Erreur lors de la suppression", err.message),
    );
  }

  function onSubmit(): void {
    setIsActionDialogHidden(true);
    if (actionMode === ActionMode.ADD) {
      sendPostRequest();
    } else if (actionMode === ActionMode.REMOVE) {
      sendDeleteRequest();
    }
  }

  React.useEffect(() => {
    fetchImages();
  }, []);

  return (
    <Stack>
      <GenericTable items={allImages} columns={montageImageColumns} selection={selection} />
      <GenericActionBar
        disableRemoveEdit={selectedImage === undefined}
        setActionMode={setActionMode}
        displayModal={() => setIsActionDialogHidden(false)}
        emptyTmp={() => setTmpImage(DEFAULT_MONTAGE_IMAGE)}
        fillTmp={() => setTmpImage({url: selectedImage?.url ?? ""})}
        noEdit={true}
      />
      <GenericActionDialog
        isHidden={isActionDialogHidden || actionMode === ActionMode.REMOVE}
        hide={() => setIsActionDialogHidden(true)}
        actionMode={actionMode}
        onSubmit={onSubmit}
        genericName={"une image"}
        errorText={validationError}
      >
        <ImageSelector
          category={"montage"}
          value={tmpImage.url}
          onChange={url => setTmpImage({url: url})}
        />
      </GenericActionDialog>
      <GenericRemoveDialog
        isHidden={isActionDialogHidden || actionMode !== ActionMode.REMOVE}
        hide={() => setIsActionDialogHidden(true)}
        title={"Supprimer l'image"}
        onSubmit={onSubmit}
      >
        <Text>Voulez-vous supprimer cette image du pêle-mêle ?</Text>
        <Image src={selectedImage?.url ?? ""} imageFit={ImageFit.centerContain} width={"100%"} height={200} />
      </GenericRemoveDialog>
    </Stack>
  );
};
