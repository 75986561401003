import { IconButton, Stack } from "@fluentui/react";
import React from "react";

import { ActionMode } from "./types";


interface IGenericActionBarProps {
  // Boolean that disables the "Remove" and "Edit" buttons
  disableRemoveEdit: boolean;
  // Function that sets the "actionMode" state in the parent component
  setActionMode: (newMode: ActionMode) => void;
  // Function that displays the action dialog
  displayModal: () => void;
  // Function that empties the tmp object (called on "Add" and "Remove" presses)
  emptyTmp: () => void;
  // Function that fills the tmp object with current data (called on "Edit" press)
  fillTmp: () => void;
  // Hide the "edit" button
  noEdit?: boolean;
}

// An horizontal bar with 3 buttons : "Add", "Remove" and "Edit".
// When the buttons are pressed, the action mode is set, the action dialog is
// shown, and the emptyTmp/fillTmp function is called so that you can fill your
// temporary object (used in your action dialog) with the adequate data.
export const GenericActionBar: React.FC<IGenericActionBarProps> = (props) => {
  return (
    <Stack horizontal tokens={{childrenGap: 20}} verticalAlign="center" horizontalAlign="center">
      <IconButton
        title={"Ajouter"}
        iconProps={{iconName: "Add"}}
        onClick={() => {
          props.setActionMode(ActionMode.ADD);
          props.emptyTmp();
          props.displayModal();
        }}
      />
      <IconButton
        title={"Supprimer"}
        iconProps={{iconName: "Cancel"}}
        disabled={props.disableRemoveEdit}
        onClick={() => {
          props.setActionMode(ActionMode.REMOVE);
          props.emptyTmp();
          props.displayModal();
        }}
      />
      {props.noEdit !== true && (
        <IconButton
          title={"Modifier"}
          iconProps={{iconName: "Edit"}}
          disabled={props.disableRemoveEdit}
          onClick={() => {
            props.setActionMode(ActionMode.EDIT);
            props.fillTmp();
            props.displayModal();
          }}
        />
      )}
    </Stack>
  );
};
