import {
  Checkbox,
  DefaultButton,
  DetailsList,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IColumn,
  IconButton,
  IDialogContentProps,
  IDropdownOption,
  Pivot,
  PivotItem,
  PrimaryButton,
  Selection,
  SelectionMode,
  Separator,
  Spinner,
  Stack,
  StackItem,
  Text,
  TextField
} from "@fluentui/react";
import { useBoolean } from '@fluentui/react-hooks';
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  createOneCheckListItem,
  createOneProfile,
  createOneUserProfile,
  createOneUserProfileException,
  deleteOneCheckListItem,
  deleteOneProfile,
  deleteOneUserProfile,
  deleteOneUserProfileException,
  getAllProfiles,
  getAllUsers,
  getOneUserProfile,
  modifyOneCheckListItem,
  modifyOneProfile
} from '../api/actions';
import {
  ICheckListItem,
  ICheckListItemPatch,
  ICheckListItemPost,
  ICheckListProfile,
  ICheckListProfilePatch,
  ICheckListProfilePost,
  IUser,
  IUserCheckListProfile,
  IUserCheckListProfileExceptionPost,
  IUserCheckListProfilePost,
  UserRole
} from '../api/types';
import { handleError } from "../helpers/requests";
import { errorSlice } from "../slices/errorSlice";


export const ResidentActions: React.FC = () => {

  return (
    <Stack>
      <Pivot>
        <PivotItem headerText="Profiles" itemIcon="CheckList"><Profiles /></PivotItem>
        <PivotItem headerText="Résidents" itemIcon="People"><UserProfiles /></PivotItem>
      </Pivot>
    </Stack>
  );

};


// const profilData: ICheckListProfile[] = [
//     {
//         id: 1,
//         label: "Peu autonome",
//         description: "",
//         items: [
//             {id: 7, label: "Vérifier les lumières"},
//             {id: 8, label: "Prévenir mon référent"},
//             {id: 9, label: "Fermer la porte"}
//         ]
//     },
//     {
//         id: 2,
//         label: "Non autonome",
//         description: "",
//         items: [
//             {id: 1, label: "Femer les fenêtres"},
//             {id: 2, label: "Eteindre les lumières"},
//             {id: 3, label: "Prévenir mon référent"},
//             {id: 4, label: "Vérifier les robinets"},
//             {id: 5, label: "Vérifier la plaque de cuisson"},
//             {id: 6, label: "Fermer la porte"}
//         ]
//     },
// ]


interface DialogFormProps {
  state: { label: string, initValue: string }[],
  onValueChange: (newValues: string[]) => void
}


const MultipleFieldDialogForm: React.FC<DialogFormProps> = (props: DialogFormProps) => {

  const [state, setState] = useState(props.state.map(s => s.initValue));

  const onChangeDialogTextValue = props.state.map((_, i) =>
      (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const newState = [...state];
        newState[i] = newValue || '';

        setState(newState);
        props.onValueChange(newState);
      },
    [],
  );


  return (
    <Stack>
      {
        props.state.map((_, i) =>
          <TextField label={props.state[i].label} value={state[i]} onChange={onChangeDialogTextValue[i]} />)
      }
    </Stack>
  );
};


const Profiles: React.FC = () => {

  interface DialogProps {
    contentProps: IDialogContentProps;
    formProps: DialogFormProps;
    onSave: (state: string[], profile: number | undefined, item: number | undefined) => void;
    onCancel: () => void;
  }

  const emptyDialogProps: DialogProps = {
    contentProps: {
      type: DialogType.largeHeader,
      title: '',
      subText: '',
    },
    formProps: {
      state: [], onValueChange: () => {
      }
    },
    onSave: (_: string[], __: number | undefined, ___: number | undefined) => {
    },
    onCancel: () => {
    }
  };

  const dispatch = useDispatch();
  const [selectedProfile, setSelectedProfile] = useState<ICheckListProfile>();
  const [profiles, setProfiles] = useState<ICheckListProfile[]>([]);
  const [selectedChecklistItem, setSelectedChecklistItem] = useState<ICheckListItem>();
  const [hideDialog, {toggle: toggleHideDialog}] = useBoolean(true);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isPostPending, setIsPostPending] = React.useState(false);

  const dialogInitState: string[] = [];
  const [dialogProps, setDialogProps] = useState(emptyDialogProps);
  const [dialogState, setDialogState] = useState<string[]>(dialogInitState);

  const profilColumns: IColumn[] = [
    {key: "id", name: "#", fieldName: "id", minWidth: 16},
    {key: "name", name: "Nom du profil", fieldName: "label", minWidth: 100},
    {key: "descr", name: "Description du profil", fieldName: "description", minWidth: 300}
  ];

  const checklistColumns: IColumn[] = [
    {key: "id", name: "#", fieldName: "id", minWidth: 16},
    {key: "descr", name: "Description du point de contrôle", fieldName: "label", minWidth: 300}
  ];

  const profileSelection = useMemo(() => new Selection({
    onSelectionChanged: () => {
      setSelectedProfile(
        profileSelection.count ? profileSelection.getSelection()[0] as ICheckListProfile : undefined);
    }
  }), []);

  const checklistItemSelection = new Selection({
    onSelectionChanged: () => {
      setSelectedChecklistItem(
        checklistItemSelection.count ?
          checklistItemSelection.getSelection()[0] as ICheckListItem : undefined);
    }
  });

  const addChecklistItemDialogProps: DialogProps = {
    ...emptyDialogProps,
    contentProps: {
      type: DialogType.largeHeader,
      title: 'Ajouter un point de contrôle',
      subText: 'Donner la description du point de contrôle',
    },
  };

  const editChecklistItemDialogProps: DialogProps = {
    ...emptyDialogProps,
    contentProps: {
      type: DialogType.largeHeader,
      title: 'Modifier un point de contrôle',
      subText: 'Donner la nouvelle description du point de contrôle',
    },
  };

  const deleteChecklistItemDialogProps: DialogProps = {
    ...emptyDialogProps,
    contentProps: {
      type: DialogType.largeHeader,
      title: 'Supprimer un point de contrôle',
      subText: 'Êtes-vous certain de vouloir supprimer ce point de contrôle',
    },
  };

  const addProfileDialogProps: DialogProps = {
    ...emptyDialogProps,
    contentProps: {
      type: DialogType.largeHeader,
      title: 'Ajouter un profil',
      subText: 'Donner la description du profil',
    },
  };

  const editProfileDialogProps: DialogProps = {
    ...emptyDialogProps,
    contentProps: {
      type: DialogType.largeHeader,
      title: 'Modifier la description du profil',
      subText: 'Donner la nouvelle description du profil',
    },
  };

  const deleteProfileDialogProps: DialogProps = {
    ...emptyDialogProps,
    contentProps: {
      type: DialogType.largeHeader,
      title: 'Supprimer un profil résident',
      subText: 'Êtes-vous certain de vouloir supprimer ce profil',
    },
  };

  function fetchProfiles() {
    setIsLoading(true);
    getAllProfiles()
      .then(res => res.json())
      .then(profileData => {
        setProfiles(profileData);
        setIsLoading(false);
      })
      .catch(err => handleError(err));
  }

  useEffect(() => {
    fetchProfiles();
  }, []);

  useEffect(() => {
    if (profiles && profiles.length) {
      let index = 0;
      if (selectedProfile) {
        index = profiles.findIndex(p => p.id === selectedProfile.id);
      }
      setSelectedProfile(profiles[index]);
      profileSelection.selectToIndex(index, true);
    }
  }, [profiles, profileSelection, selectedProfile]);


  useEffect(() => {
    console.log(`dialogState updated to ${dialogState}`);
  }, [dialogState]);

  const addCheckListItem = () => {
    const newState = [{initValue: '', label: 'description'}];
    setDialogState(newState.map(s => s.initValue));
    setDialogProps({
      ...addChecklistItemDialogProps,
      formProps: {
        state: newState,
        onValueChange: s => setDialogState(s)
      },
      onSave: (state: string[], profile: number | undefined, _: number | undefined) => {
        if (profile)
          onAddCheckListItemSubmit(profile, state[0], toggleHideDialog).then();
        else
          toggleHideDialog();
      },
      onCancel: toggleHideDialog
    });
    toggleHideDialog();
  };

  const editCheckListItem = () => {
    const newState = [{
      initValue: selectedChecklistItem?.label ? selectedChecklistItem?.label : "",
      label: "description"
    }];
    setDialogState(newState.map(s => s.initValue));
    setDialogProps({
      ...editChecklistItemDialogProps,
      formProps: {
        state: newState,
        onValueChange: s => setDialogState(s)
      },
      onSave: (state: string[], profile: number | undefined, item: number | undefined) => {
        if (profile && item)
          onEditCheckListItemSubmit(profile, item, state[0], toggleHideDialog).then();
        else
          toggleHideDialog();
      },
      onCancel: toggleHideDialog
    });
    toggleHideDialog();
  };

  const deleteCheckListItem = () => {
    setDialogState([]);
    setDialogProps({
      ...deleteChecklistItemDialogProps,
      formProps: {
        state: [],
        onValueChange: s => setDialogState(s)
      },
      onSave: (state: string[], profile: number | undefined, item: number | undefined) => {
        if (profile && item)
          onRemoveCheckListItemSubmit(profile, item, toggleHideDialog).then();
        else
          toggleHideDialog();
      },
      onCancel: toggleHideDialog
    });
    toggleHideDialog();
  };

  const addProfile = () => {
    const newState = [{initValue: '', label: 'label'}, {initValue: '', label: 'description'}];
    setDialogState(newState.map(s => s.initValue));
    setDialogProps({
      ...addProfileDialogProps,
      formProps: {
        state: newState,
        onValueChange: s => setDialogState(s)
      },
      onSave: (state: string[], _: number | undefined, __: number | undefined) => {
        onAddProfileSubmit(state[0], state[1], toggleHideDialog).then();
      },
      onCancel: toggleHideDialog
    });
    toggleHideDialog();
  };

  const editProfile = () => {
    const newState = [
      {initValue: selectedProfile?.label ? selectedProfile?.label : "", label: 'label'},
      {initValue: selectedProfile?.description ? selectedProfile?.description : "", label: 'description'}];
    setDialogState(newState.map(s => s.initValue));
    setDialogProps({
      ...editProfileDialogProps,
      formProps: {
        state: newState,
        onValueChange: s => setDialogState(s)
      },
      onSave: (state: string[], profile: number | undefined, _: number | undefined) => {
        if (profile)
          onEditProfileSubmit(profile, state[0], state[1], toggleHideDialog).then();
        else
          toggleHideDialog();
      },
      onCancel: toggleHideDialog
    });
    toggleHideDialog();
  };

  const deleteProfile = () => {
    setDialogState([]);
    setDialogProps({
      ...deleteProfileDialogProps,
      onSave: (state: string[], profile: number | undefined, _: number | undefined) => {
        if (profile)
          onRemoveProfileSubmit(profile, toggleHideDialog).then();
        else
          toggleHideDialog();
      },
      onCancel: toggleHideDialog
    });
    toggleHideDialog();
  };

  function resetAndReload(): void {
    setIsPostPending(false);
    // Reload the user list
    fetchProfiles();
  }

  async function onAddCheckListItemSubmit(
    profile_id: number, label: string, onDone: () => void): Promise<void> {

    setIsPostPending(true);
    const postData: ICheckListItemPost = {
      label: label,
    };

    try {
      const res = await createOneCheckListItem(profile_id, postData);
      const json = await res.json();
      if (res.ok) {
        resetAndReload();
      } else {
        dispatch(errorSlice.actions.push({
          title: "Erreur lors de la requête",
          message: json.detail,
        }));
      }
    } catch (err) {
      console.log(err);
      handleError(err as Error);
    }
    setIsPostPending(false);
    onDone();
  }

  async function onEditCheckListItemSubmit(
    profile_id: number, item_id: number, label: string, onDone: () => void): Promise<void> {

    setIsPostPending(true);
    const postData: ICheckListItemPatch = {
      label: label,
    };

    console.log(postData);

    try {
      const res = await modifyOneCheckListItem(profile_id, item_id, postData);
      const json = await res.json();
      if (res.ok) {
        resetAndReload();
      } else {
        dispatch(errorSlice.actions.push({
          title: "Erreur lors de la requête",
          message: json.detail,
        }));
      }
    } catch (err) {
      console.log(err);
      handleError(err as Error);
    }
    setIsPostPending(false);
    onDone();
  }


  async function onRemoveCheckListItemSubmit(
    profile_id: number, item_id: number, onDone: () => void): Promise<void> {

    setIsPostPending(true);

    try {
      const res = await deleteOneCheckListItem(profile_id, item_id);
      const json = await res.json();
      if (res.ok) {
        resetAndReload();
      } else {
        dispatch(errorSlice.actions.push({
          title: "Erreur lors de la requête",
          message: json.detail,
        }));
      }
    } catch (err) {
      console.log(err);
      handleError(err as Error);
    }
    setIsPostPending(false);
    onDone();
  }

  async function onAddProfileSubmit(label: string, description: string, onDone: () => void): Promise<void> {
    setIsPostPending(true);
    const postData: ICheckListProfilePost = {
      label: label,
      description: description
    };

    try {
      const res = await createOneProfile(postData);
      const json = await res.json();
      if (res.ok) {
        resetAndReload();
      } else {
        dispatch(errorSlice.actions.push({
          title: "Erreur lors de la requête",
          message: json.detail,
        }));
      }
    } catch (err) {
      console.log(err);
      handleError(err as Error);
    }
    setIsPostPending(false);
    onDone();
  }

  async function onEditProfileSubmit(
    id: number,
    label: string,
    description: string,
    onDone: () => void
  ): Promise<void> {
    setIsPostPending(true);
    const postData: ICheckListProfilePatch = {
      label: label,
      description: description,
      enabled: true
    };

    try {
      const res = await modifyOneProfile(id, postData);
      const json = await res.json();
      if (res.ok) {
        resetAndReload();
      } else {
        dispatch(errorSlice.actions.push({
          title: "Erreur lors de la requête",
          message: json.detail,
        }));
      }
    } catch (err) {
      console.log(err);
      handleError(err as Error);
    }
    setIsPostPending(false);
    onDone();
  }

  async function onRemoveProfileSubmit(id: number, onDone: () => void): Promise<void> {
    setIsPostPending(true);

    try {
      const res = await deleteOneProfile(id);
      const json = await res.json();
      if (res.ok) {
        resetAndReload();
      } else {
        dispatch(errorSlice.actions.push({
          title: "Erreur lors de la requête",
          message: json.detail,
        }));
      }
    } catch (err) {
      console.log(err);
      handleError(err as Error);
    }
    setIsPostPending(false);
    onDone();
  }


  if (isLoading)
    return (<Spinner />);

  return (
    <Stack tokens={{childrenGap: 20}}>

      <Stack style={{paddingBottom: 30}}>
        <StackItem align="center">
          <Text variant={"large"} style={{textDecorationLine: 'underline'}}>Gestion des profiles d'autonomie
            des résidents</Text>
        </StackItem>
      </Stack>
      <Stack horizontal tokens={{childrenGap: 20}}>
        <Stack>
          <DetailsList
            selection={profileSelection}
            selectionMode={SelectionMode.single}
            columns={profilColumns}
            items={profiles}
          />
          <Separator />
          <Stack horizontal tokens={{childrenGap: 20}} verticalAlign="center" horizontalAlign="center">
            <IconButton
              iconProps={{iconName: "Add"}}
              onClick={addProfile}
            />
            <IconButton
              iconProps={{iconName: "Cancel"}}
              disabled={!selectedProfile}
              onClick={deleteProfile}
            />
            <IconButton
              iconProps={{iconName: "Edit"}}
              disabled={!selectedProfile}
              onClick={editProfile}
            />
          </Stack>
        </Stack>
        <Separator vertical />
        {
          selectedProfile &&
          <Stack horizontalAlign="center">
            <Text variant={"medium"}>
              Les points de contrôle pour le profile: {selectedProfile?.label}</Text>
            <DetailsList
              selection={checklistItemSelection}
              selectionMode={SelectionMode.single}
              columns={checklistColumns}
              items={selectedProfile?.items ? selectedProfile?.items : []}
            />
            <Separator />
            <Stack horizontal tokens={{childrenGap: 20}} verticalAlign="center" horizontalAlign="center">
              <IconButton
                iconProps={{iconName: "Add"}}
                disabled={!selectedProfile}
                onClick={addCheckListItem}
              />
              <IconButton
                iconProps={{iconName: "Cancel"}}
                disabled={!selectedChecklistItem}
                onClick={deleteCheckListItem}
              />
              <IconButton
                iconProps={{iconName: "Edit"}}
                disabled={!selectedChecklistItem}
                onClick={editCheckListItem}
              />
            </Stack>
          </Stack>
        }
      </Stack>
      <Dialog
        hidden={hideDialog}
        onDismiss={dialogProps.onCancel}
        modalProps={{isBlocking: true, styles: {main: {maxWidth: 450}}}}
        dialogContentProps={dialogProps.contentProps}
      >
        <MultipleFieldDialogForm {...dialogProps.formProps} />
        <DialogFooter>
          <PrimaryButton
            onClick={() => dialogProps.onSave(dialogState, selectedProfile?.id, selectedChecklistItem?.id)}
            disabled={isPostPending}
            text="Enregistrer"
          />
          <DefaultButton
            onClick={dialogProps.onCancel}
            disabled={isPostPending}
            text="Annuler"
          />
        </DialogFooter>
      </Dialog>
    </Stack>
  );

};

interface ISelectProfileDialogProps {
  user: number;
  selection: ICheckListProfile | undefined;
  profiles: ICheckListProfile[];
  onSave: (profile: ICheckListProfile) => void;
  onCancel: () => void;
}

const SelectProfileDialog: React.FC<ISelectProfileDialogProps> = (props: ISelectProfileDialogProps) => {

  const dispatch = useDispatch();
  const [isPostPending, setIsPostPending] = React.useState(false);
  const [selectedProfile, setSelectedProfile] = React.useState<ICheckListProfile>();

  const contentProps: IDialogContentProps = {
    type: DialogType.largeHeader,
    title: "Profil de l'utilisateur",
    subText: "Choisir un profil pour l'utilisateur",
  };

  const profileOptions: IDropdownOption[] = React.useMemo(() =>
    props.profiles.map(p => ({key: p.id, text: p.label})), [props]);

  const onSelectionChanged = React.useCallback((
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption | undefined
  ): void => {
    if (item)
      setSelectedProfile(props.profiles.find(p => p.id === item.key));
  }, [props]);


  async function onAddProfileSubmit(
    user_id: number,
    profile_id: number,
    delete_previous: boolean,
    onDone: () => void,
    onFailed: () => void
  ): Promise<void> {
    setIsPostPending(true);
    const postData: IUserCheckListProfilePost = {
      user_id: user_id,
      profile_id: profile_id
    };
    let success = false;

    try {
      if (delete_previous) {
        const res = await deleteOneUserProfile(user_id);
        if (!res.ok) {
          dispatch(errorSlice.actions.push({
            title: "Erreur lors de la requête",
            message: "Suppression d'un profil résident",
          }));
          setIsPostPending(false);
          onFailed();
          return;
        }
      }

      const res = await createOneUserProfile(postData);
      const json = await res.json();
      if (res.ok) {
        success = true;
      } else {
        dispatch(errorSlice.actions.push({
          title: "Erreur lors de la requête",
          message: json.detail,
        }));
      }
    } catch (err) {
      console.log(err);
      handleError(err as Error);
    }
    setIsPostPending(false);
    if (success)
      onDone();
    else
      onFailed();
  }


  return (
    <Dialog
      hidden={false}
      onDismiss={props.onCancel}
      modalProps={{isBlocking: true, styles: {main: {maxWidth: 450}}}}
      dialogContentProps={contentProps}
    >

      <Dropdown
        label="Nom du profil"
        defaultSelectedKey={props.selection?.id}
        options={profileOptions}
        styles={{dropdown: {width: 300}}}
        onChange={onSelectionChanged}
      />

      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            if (selectedProfile)
              onAddProfileSubmit(props.user,
                selectedProfile.id,
                props.selection !== undefined,
                () => {
                  props.onSave(selectedProfile);
                },
                () => {
                  props.onCancel();
                }).then();
          }}
          disabled={isPostPending || !selectedProfile}
          text="Enregistrer"
        />
        <DefaultButton
          onClick={props.onCancel}
          disabled={isPostPending}
          text="Annuler"
        />
      </DialogFooter>
    </Dialog>
  );
};

interface ISelectExceptionsDialogProps {
  profile: IUserCheckListProfile;
  onSave: () => void;
  onCancel: () => void;
}


const SelectExceptionsDialogs: React.FC<ISelectExceptionsDialogProps> = (props: ISelectExceptionsDialogProps) => {

  const dispatch = useDispatch();
  const [isPostPending, setIsPostPending] = React.useState(false);
  const [exceptions, setExceptions] = React.useState<number[]>(props.profile.exceptions);

  const onCheckChange = (item: number) => ((
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ): void => {

    if (checked !== undefined) {
      if (!exceptions.includes(item)) {
        if (!checked)
          setExceptions([...exceptions, item]);
      } else {
        if (checked) setExceptions(exceptions.filter(x => x !== item));
      }
    }
  });

  const checklistColumns: IColumn[] = [
    {key: "id", name: "#", fieldName: "id", minWidth: 16},
    {key: "descr", name: "Description du point de contrôle", fieldName: "label", minWidth: 300},
    {
      key: "enabled", name: "Actif ?", minWidth: 50,
      onRender: (item: ICheckListItem) => <Checkbox
        checked={!exceptions.includes(item.id)}
        onChange={onCheckChange(item.id)}
      />
    }
  ];

  const contentProps: IDialogContentProps = {
    type: DialogType.largeHeader,
    title: 'Modifier les exceptions',
    subText: 'Décochez les points de contrôle inutiles',
  };

  async function onUpdateExceptionsSubmit(
    user_id: number,
    current: number[],
    previous: number[],
    onDone: () => void,
    onFailed: () => void
  ): Promise<void> {
    setIsPostPending(true);

    let success = false;

    try {

      const toRemove = previous.filter(i => !current.includes(i));
      const toAdd = current.filter(i => !previous.includes(i));

      let error = false;

      for (let exception_id of toRemove) {
        const res = await deleteOneUserProfileException(user_id, exception_id);
        await res.json();
        if (!res.ok) {
          error = true;
          break;
        }
      }

      if (error) {
        dispatch(errorSlice.actions.push({
          title: "Erreur lors de la requête",
          message: "Erreur lors de la suppression de l'exception",
        }));

      } else {

        for (let exception_id of toAdd) {
          const postData: IUserCheckListProfileExceptionPost = {
            item_id: exception_id
          };
          const res = await createOneUserProfileException(user_id, postData);
          await res.json();
          if (!res.ok) {
            error = true;
            break;
          }
        }

        if (error) {
          dispatch(errorSlice.actions.push({
            title: "Erreur lors de la requête",
            message: "Erreur lors de l'ajout de l'exception",
          }));
        } else {
          success = true;
        }
      }

    } catch (err) {
      console.log(err);
      handleError(err as Error);
    }
    setIsPostPending(false);
    if (success)
      onDone();
    else
      onFailed();
  }

  return (
    <Dialog
      minWidth={600}
      hidden={false}
      onDismiss={props.onCancel}
      modalProps={{
        isBlocking: false,
      }}
      dialogContentProps={contentProps}
    >

      <DetailsList
        columns={checklistColumns}
        items={props.profile.checklist.items}
      />

      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            onUpdateExceptionsSubmit(
              props.profile.user_id,
              exceptions,
              props.profile.exceptions,
              props.onSave,
              props.onCancel
            ).then();
          }}
          disabled={isPostPending}
          text="Enregistrer"
        />
        <DefaultButton
          onClick={props.onCancel}
          disabled={isPostPending}
          text="Annuler"
        />
      </DialogFooter>
    </Dialog>
  );

};

const UserProfiles: React.FC = () => {

  const [isLoading, setIsLoading] = React.useState(true);
  const [allUsers, setAllUsers] = React.useState<IUser[]>([]);
  const [selectedUser, setSelectedUser] = React.useState<IUser>();
  const [profiles, setProfiles] = useState<ICheckListProfile[]>([]);
  const [selectedProfile, setSelectedProfile] = useState<IUserCheckListProfile>();
  const [hideProfileDialog, {toggle: toggleHideProfileDialog}] = useBoolean(true);
  const [hideExceptionsDialog, {toggle: toggleHideExceptionsDialog}] = useBoolean(true);

  const userColumns: IColumn[] = React.useMemo(() => [
    {
      key: "id",
      name: "#",
      fieldName: "id",
      minWidth: 25,
      maxWidth: 25,
    }, {
      key: "firstname",
      name: "Prénom",
      fieldName: "firstname",
      minWidth: 100,
      maxWidth: 500,
    }, {
      key: "lastname",
      name: "Nom",
      fieldName: "lastname",
      minWidth: 100,
      maxWidth: 500,
    },
  ], []);

  const checklistColumns: IColumn[] = [
    {key: "id", name: "#", fieldName: "id", minWidth: 16},
    {key: "descr", name: "Description du point de contrôle", fieldName: "label", minWidth: 300}
  ];

  const userSelection = new Selection({
    onSelectionChanged: () => {
      setSelectedUser(
        userSelection.count ? userSelection.getSelection()[0] as IUser : undefined);
    }
  });

  useEffect(() => {
    fetchUsers();
    fetchProfiles();
  }, []);

  useEffect(() => {
    if (selectedUser)
      fetchUserProfile(selectedUser.id).then();

  }, [selectedUser]);

  function fetchUsers() {
    setIsLoading(true);
    getAllUsers()
      .then(res => res.json())
      .then((users: IUser[]) => {
        setAllUsers(users.filter(u => u.role === UserRole.RESIDENT));
        setIsLoading(false);
      })
      .catch(err => handleError(err));
  }

  function fetchProfiles() {
    setIsLoading(true);
    getAllProfiles()
      .then(res => res.json())
      .then(profileData => {
        setProfiles(profileData);
        setIsLoading(false);
      })
      .catch(err => handleError(err));
  }

  async function fetchUserProfile(user_id: number) {
    setIsLoading(true);
    try {
      const res = await getOneUserProfile(user_id);
      const json = await res.json();
      if (res.ok) {
        console.log(json);
        setSelectedProfile(json);
      } else {
        console.log(`No profile defined for user ${user_id}`);
        setSelectedProfile(undefined);
      }
    } catch (err) {
      console.log(err);
      handleError(err as Error);
    }
    setIsLoading(false);
  }

  if (isLoading)
    return (<Spinner />);

  return (
    <Stack tokens={{childrenGap: 20}}>

      <Stack style={{paddingBottom: 30}}>
        <StackItem align="center">
          <Text variant={"large"} style={{textDecorationLine: 'underline'}}>Gestion des profiles d'autonomie
            des résidents</Text>
        </StackItem>
      </Stack>
      <Stack horizontal tokens={{childrenGap: 20}} verticalAlign="start">
        <Stack>
          <DetailsList
            selection={userSelection}
            selectionMode={SelectionMode.single}
            columns={userColumns}
            items={allUsers}
          />
        </Stack>
        <Separator vertical />
        {
          selectedUser &&
          <Stack horizontalAlign="start" tokens={{childrenGap: 20}}>
            <Text variant={"medium"}>
              Les informations de profil pour {selectedUser?.firstname} {selectedUser?.lastname}
            </Text>
            <Stack horizontal tokens={{childrenGap: 20}} verticalAlign="end">
              <StackItem>
                <TextField label="Nom du profil" readOnly value={selectedProfile?.checklist.label} />
              </StackItem>
              <StackItem>
                <IconButton
                  iconProps={{iconName: "Edit"}}
                  onClick={toggleHideProfileDialog}
                />
              </StackItem>
            </Stack>
            <Separator />
            {
              selectedProfile &&
              <Stack tokens={{childrenGap: 20}} horizontalAlign="center">
                <Text variant="medium">Point de contrôle définis pour l'utilisateur</Text>
                <DetailsList
                  columns={checklistColumns}
                  items={
                    selectedProfile?.checklist?.items ?
                      selectedProfile?.checklist?.items.filter(x => !selectedProfile.exceptions.includes(x.id))
                      : []
                  }
                />
                <IconButton
                  iconProps={{iconName: "Edit"}}
                  disabled={!selectedProfile}
                  onClick={toggleHideExceptionsDialog}
                />
              </Stack>
            }
          </Stack>
        }
      </Stack>
      {
        !hideProfileDialog && selectedUser &&
        <SelectProfileDialog {...{
          user: selectedUser?.id,
          selection: selectedProfile?.checklist,
          profiles: profiles,
          onSave: (_: ICheckListProfile) => {
            fetchUserProfile(selectedUser.id).then();
            toggleHideProfileDialog();
          },
          onCancel: () => {
            toggleHideProfileDialog();
          }
        }} />
      }
      {
        !hideExceptionsDialog && selectedProfile &&
        <SelectExceptionsDialogs {...{
          profile: selectedProfile,
          onSave: () => {
            fetchUserProfile(selectedProfile.user_id).then();
            toggleHideExceptionsDialog();
          },
          onCancel: toggleHideExceptionsDialog
        }}

        />
      }
    </Stack>
  );
};
