import { createSlice } from "@reduxjs/toolkit";


let initialState = {
  id: 0,
  username: "",
  lastname: "",
  firstname: "",
  email: "",
  isAdmin: false,
  token: "",
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    logout: state => {
      Object.assign(state, {...initialState});
    },
    setToken: (state, action) => {
      state.token = action.payload.token;
    },
    setUserInfo: (state, action) => {
      state.username = action.payload.username;
      state.lastname = action.payload.lastname;
      state.firstname = action.payload.firstname;
      state.email = action.payload.email;
    },
    setIsAdmin: (state, action) => {
      state.isAdmin = action.payload.isAdmin;
    },
  },
});

export { userSlice };
