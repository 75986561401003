import {
  DefaultButton,
  Depths,
  DetailsList,
  IColumn,
  Pivot,
  PivotItem,
  PrimaryButton,
  SelectionMode,
  Separator,
  Spinner,
  Stack,
  StackItem,
  Text,
  TextField,
  Toggle
} from "@fluentui/react";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
  getActiveAlerts,
  getAlertConfiguration,
  getAlertConfigurationModifications,
  getAllAlerts,
  getAllUsers,
  getOneUser,
  saveAlertConfiguration,
  setAlertAcknowledgement,
} from "../../api/actions";
import {
  IAlert,
  IAlertConfiguration,
  IAlertConfigurationModification,
  ITmpAlertConfiguration,
  IUser
} from "../../api/types";
import {errorSlice} from "../../slices/errorSlice";
import {handleError, unpackResponse} from "../../helpers/requests";
import {RootState} from "../../slices";
import {createErrorPopup} from "../../helpers/errors";


export const AlertPage: React.FC = () => {
  const isAdmin = useSelector((state: RootState) => state.user.isAdmin);

  return (
      <Stack>
        <Pivot>
          <PivotItem headerText="Alertes en cours" itemIcon="AlertSolid"><AlertInProgress /></PivotItem>
          <PivotItem headerText="Historique des alertes" itemIcon="History"><AlertHistory /></PivotItem>
          {isAdmin && <PivotItem headerText="Configurations des alertes" itemIcon="AlertSettings"><AlertConfiguration /></PivotItem>}
          {isAdmin && <PivotItem headerText="Historique de configuration des alertes" itemIcon="FullHistory"><AlertConfigurationHistory /></PivotItem>}
        </Pivot>
      </Stack>
  );
};


export const AlertInProgress: React.FC = () => {
  const [activeAlerts, setActiveAlerts] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const dispatch = useDispatch();

  async function onAlertAcknowledgement(alertId: number) {
    await setAlertAcknowledgement(alertId).then();
    await fetchAlerts();
  }

  const fetchAlerts = React.useCallback(async () => {
    const res = await getActiveAlerts();
    const json = await res.json();
    if (res.ok) {
      setIsLoading(false);
      setActiveAlerts(json);
    } else {
      dispatch(errorSlice.actions.push({
        title: "Erreur lors de la requête",
        message: json.detail,
      }));
    }
  }, [dispatch]);

  React.useEffect(() => {
    fetchAlerts().then();
    const timer = setInterval(() => {fetchAlerts().then()}, 30000);
    return () => clearInterval(timer);
  }, [fetchAlerts]);

  if (isLoading) {
    return <Spinner />;
  }

  if(activeAlerts.length === 0) {
    return (
      <Stack tokens={{childrenGap: 20}}>
        <StackItem align="center" style={{paddingBottom: 30}}>
          <Text variant={"large"} >Aucune alerte en cours</Text>
        </StackItem>
      </Stack>
    );
  } else {
    return (
      <Stack tokens={{childrenGap: 20}}>
        <Stack
            horizontal
            wrap
            horizontalAlign={"space-evenly"}
            tokens={{childrenGap: 10}}
        >
          {activeAlerts.map((alert, idx) => (
              <AlertCard key={idx} alert={alert} acknowledgementCallback={onAlertAcknowledgement}/>
          ))}
        </Stack>
      </Stack>
    );
  }
};

function formatDate(date_str: string): string {
  if(!date_str)
    return ""
  try {
    const date = new Date(date_str);
    return date.toLocaleString("fr")
  }
  catch (e){
    return ""
  }
}

export const AlertHistory: React.FC = () => {
  const [alerts, setAlerts] = useState<IAlert[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);

  const fetchUsers = React.useCallback(() => {
    getAllUsers()
        .then(res => res.json())
        .then((data: IUser[]) => {
          setUsers(data);
        })
        .catch(err => handleError(err));
  }, []);

  const fetchAlerts = React.useCallback(() => {
    getAllAlerts()
        .then(res => res.json())
        .then((data: IAlert[]) => {
          setAlerts(data);
        })
        .catch(err => handleError(err));
  }, []);

  useEffect(() => {
    fetchAlerts();
    fetchUsers();
    const timer = setInterval(() => {
      fetchAlerts();
      fetchUsers();
      }, 30000);
    return () => clearInterval(timer);
  }, [fetchAlerts, fetchUsers]);

  const columns: IColumn[] = React.useMemo(() => [
    {
      key: "id",
      name: "#",
      fieldName: "id",
      minWidth: 25,
      maxWidth: 25,
    }, {
      key: "firstname",
      name: "Prénom",
      fieldName: "firstname",
      minWidth: 120,
      maxWidth: 120,
      onRender: (item) => {
        let user = users.find((user) => {
          return user.id === item.user_id;
        })
        return user?.firstname??""
      }
    }, {
      key: "lastname",
      name: "Nom",
      fieldName: "lastname",
      minWidth: 120,
      maxWidth: 120,
      onRender: (item) => {
        let user = users.find((user) => {
          return user.id === item.user_id;
        })
        return user?.lastname??""
      }
    }, {
      key: "status",
      name: "Etat",
      fieldName: "status",
      minWidth: 70,
      maxWidth: 70,
      onRender: (item) => item.status === 1 ? "Acquittée":"En cours"
    }, {
      key: "start",
      name: "Date de l'émission",
      fieldName: "start",
      minWidth: 150,
      maxWidth: 150,
      onRender: (item) => formatDate(item.start)
    }, {
      key: "end",
      name: "Date acquittement",
      fieldName: "end",
      minWidth: 170,
      maxWidth: 170,
      onRender: (item) => formatDate(item.end)
    }, {
      key: "sms_emitted",
      name: "Alerte par SMS",
      fieldName: "sms_emitted",
      minWidth: 110,
      maxWidth: 110,
      onRender: (item) =>item.sms_emitted ? "Oui":"Non"
    }, {
      key: "email_emitted",
      name: "Alerte par email",
      fieldName: "email_emitted",
      minWidth: 110,
      maxWidth: 110,
      onRender: (item) =>item.email_emitted ? "Oui":"Non"
    }, {
      key: "acknowledge_by",
      name: "Acquittée par",
      fieldName: "acknowledge_by",
      minWidth: 150,
      maxWidth: 150,
      onRender: (item) => {
        let user = users.find((user) => {
          return user.id === item.acknowledge_by;
        })
        if (user !== undefined) {
          return user.firstname + " " + user.lastname
        }
        return ""
      }
    },
  ], [users]);

  return (
    <Stack tokens={{childrenGap: 20}}>
      <DetailsList
          items={alerts}
          columns={columns}
          selectionMode={SelectionMode.none}
      />
    </Stack>
  );
}

const cardStyles = {
  root: {
    width: 120,
    height: 140,
    padding: 10,
    alignContent: "center",
    border: "1px solid",
    borderRadius: 4,
    boxShadow: Depths.depth8,
  }
};

const centeredTextStyle = {
  root: {
    textAlign: "center",
  }
};

interface IAlertCardProps {
  alert: IAlert;
  acknowledgementCallback: any;
}

const AlertCard: React.FC<IAlertCardProps> = (props) => {
  const [user, setUser] = React.useState<IUser>();
  const dispatch = useDispatch();

  const userID = props.alert.user_id;

  const statusText = props.alert.status !== 1 ? "Active" : "Terminée";
  const userText = `${user?.firstname ?? ""} ${user?.lastname ?? ""}`;
  const dateText = formatDate(props.alert.start);

  const fetchUser = React.useCallback(async () => {
    const res = await getOneUser(userID);
    const json = await res.json();
    if (res.ok) {
      setUser(json);
    } else {
      dispatch(errorSlice.actions.push({
        title: "Erreur lors de la requête",
        message: json.detail,
      }));
    }
  }, [dispatch, userID]);

  React.useEffect(() => {
    fetchUser().then();
  }, [userID, fetchUser]);

  return (
    <Stack.Item styles={cardStyles}>
      <Stack horizontalAlign={"center"} verticalAlign={"space-between"} styles={{root: {height: "100%"}}}>
        <Text styles={centeredTextStyle}>Utilisateur :<br /><b>{userText}</b></Text>
        <Text styles={centeredTextStyle}>Statut : <b>{statusText}</b></Text>
        <Text styles={centeredTextStyle}>Depuis le :<br /><b>{dateText}</b></Text>
        <PrimaryButton text={"Acquitter"} onClick={() => {
          props.acknowledgementCallback(props.alert.id);
        }} />
      </Stack>
    </Stack.Item>
  );
};


export const AlertConfigurationHistory: React.FC = () => {
  const [alertConfigurationModifications, setAlertConfigurationModifications] = useState<IAlertConfigurationModification[]>([]);

  const fetchAlertConfigurationModifications = React.useCallback(() => {
    getAlertConfigurationModifications()
        .then(res => res.json())
        .then((data: IAlertConfigurationModification[]) => {
          setAlertConfigurationModifications(data);
        })
        .catch(err => handleError(err));
  }, []);

  useEffect(() => {
    fetchAlertConfigurationModifications();
    const timer = setInterval(() => {
      fetchAlertConfigurationModifications();
    }, 30000);
    return () => clearInterval(timer);
  }, [fetchAlertConfigurationModifications]);

  const columns: IColumn[] = React.useMemo(() => [
    {
      key: "id",
      name: "#",
      fieldName: "id",
      minWidth: 25,
      maxWidth: 25,
    }, {
      key: "message",
      name: "Modification",
      fieldName: "message",
      minWidth: 300,
      maxWidth: 800
    }, {
      key: "date",
      name: "Date de la modification",
      fieldName: "date",
      minWidth: 150,
      maxWidth: 150,
      onRender: (item) => formatDate(item.date)
    },
  ], []);

  return (
    <Stack tokens={{childrenGap: 20}}>
      <DetailsList
          items={alertConfigurationModifications}
          columns={columns}
          selectionMode={SelectionMode.none}
      />
    </Stack>
  );
}

const defaultAlertConfiguration: ITmpAlertConfiguration = {
  send_sms_on_alert: null,
  phone_number: null,
  sms_sender: null,
  sms_service: null,
  send_email_on_alert: null,
  email_address: null,
  email_account: null,
  email_account_password: null,
  email_smtp_server_address: null,
  email_smtp_server_port: null,
}

function areConfigurationsEqual(oldConfiguration: IAlertConfiguration, newConfiguration: ITmpAlertConfiguration): boolean {
  return (newConfiguration.send_sms_on_alert === null || newConfiguration.send_sms_on_alert === oldConfiguration.send_sms_on_alert) &&
         (newConfiguration.phone_number === null || newConfiguration.phone_number === oldConfiguration.phone_number) &&
         (newConfiguration.sms_sender === null || newConfiguration.sms_sender === oldConfiguration.sms_sender) &&
         (newConfiguration.sms_service === null || newConfiguration.sms_service === oldConfiguration.sms_service) &&
         (newConfiguration.send_email_on_alert === null || newConfiguration.send_email_on_alert === oldConfiguration.send_email_on_alert) &&
         (newConfiguration.email_address === null || newConfiguration.email_address === oldConfiguration.email_address) &&
         (newConfiguration.email_account === null || newConfiguration.email_account === oldConfiguration.email_account) &&
         (newConfiguration.email_account_password === null || newConfiguration.email_account_password === "") &&
         (newConfiguration.email_smtp_server_address === null || newConfiguration.email_smtp_server_address === oldConfiguration.email_smtp_server_address) &&
         (newConfiguration.email_smtp_server_port === null || newConfiguration.email_smtp_server_port === oldConfiguration.email_smtp_server_port)
}

export const AlertConfiguration: React.FC = () => {
  const [alertConfiguration, setAlertConfiguration] = useState<IAlertConfiguration>();
  const [newAlertConfiguration, setNewAlertConfiguration] = useState<ITmpAlertConfiguration>(defaultAlertConfiguration);
  let disableSave = false;

  const fetchAlertConfiguration = React.useCallback(() => {
    getAlertConfiguration()
        .then(res => res.json())
        .then((data: IAlertConfiguration) => {
          setAlertConfiguration(data);
          setNewAlertConfiguration((oldValue) => ({
            ...oldValue,
            send_sms_on_alert: data.send_sms_on_alert,
            send_email_on_alert: data.send_email_on_alert
          }))
        })
        .catch(err => handleError(err));
  }, []);

  const fetchAlertConfigurationAndReset = React.useCallback(() => {
    getAlertConfiguration()
        .then(res => res.json())
        .then((data: IAlertConfiguration) => {
          setAlertConfiguration(data);
          setNewAlertConfiguration((_) => ({
            ...defaultAlertConfiguration,
            send_sms_on_alert: data.send_sms_on_alert,
            send_email_on_alert: data.send_email_on_alert
          }))
        })
        .catch(err => handleError(err));
  }, []);

  useEffect(() => {
    fetchAlertConfiguration();
  }, [fetchAlertConfiguration])

  if(alertConfiguration !== undefined) {
    disableSave = areConfigurationsEqual(alertConfiguration, newAlertConfiguration);
  }

  function saveConfiguration(newConfiguration: ITmpAlertConfiguration): void {
    const postData: ITmpAlertConfiguration = {
      ...newConfiguration
    };
    saveAlertConfiguration(postData).then(unpackResponse).then(
        _ => {
          fetchAlertConfigurationAndReset();
        },
        err => {
          createErrorPopup("Erreur lors de la sauvegarde de la configuration", err.message);
        },
    );
  }

  return (
    <Stack tokens={{childrenGap: 20}}>
      <Toggle label={"Envoi de SMS : actuellement " + (alertConfiguration?.send_sms_on_alert??false?"activé":"désactivé")}
              checked={newAlertConfiguration.send_sms_on_alert??false}
              onText="Activé"
              offText="Désactivé"
              onChange={(ev, newValue) => {
                setNewAlertConfiguration({
                  ...newAlertConfiguration,
                  send_sms_on_alert: newValue??null
                })
              }}
              />
      <TextField label={"Numéro de téléphone à prévenir en cas d'alerte : "}
                 onChange={(ev, newValue) => {
                   setNewAlertConfiguration({
                     ...newAlertConfiguration,
                     phone_number: newValue||null
                   })
                 }}
                 value={newAlertConfiguration.phone_number??""}
                 placeholder={alertConfiguration?.phone_number??""}/>
      <TextField label={"Libellé émetteur SMS : "}
                 onChange={(ev, newValue) => {
                   setNewAlertConfiguration({
                     ...newAlertConfiguration,
                     sms_sender: newValue||null
                   })
                 }}
                 value={newAlertConfiguration.sms_sender??""}
                 placeholder={alertConfiguration?.sms_sender??""}/>
      <TextField label={"Libellé service SMS : "}
                 type="string"
                 onChange={(ev, newValue) => {
                   setNewAlertConfiguration({
                     ...newAlertConfiguration,
                     sms_service: newValue||null
                   })
                 }}
                 value={newAlertConfiguration.sms_service??""}
                 placeholder={alertConfiguration?.sms_service??""}/>

      <Separator />

      <Toggle label={"Envoi d'email : actuellement " + (alertConfiguration?.send_email_on_alert??false?"activé":"désactivé")}
          checked={newAlertConfiguration?.send_email_on_alert??false}
          onText="Activé"
          offText="Désactivé"
          onChange={(ev, newValue) => {
            setNewAlertConfiguration({
              ...newAlertConfiguration,
              send_email_on_alert: newValue??null
            })
          }}
      />
      <TextField label={"Email à prévenir en cas d'alerte : "}
                 onChange={(ev, newValue) => {
                   setNewAlertConfiguration({
                     ...newAlertConfiguration,
                     email_address: newValue||null
                   })
                 }}
                 value={newAlertConfiguration.email_address??""}
                 placeholder={alertConfiguration?.email_address??""}/>
      <TextField label={"Email émetteur de l'alerte : "}
                 onChange={(ev, newValue) => {
                   setNewAlertConfiguration({
                     ...newAlertConfiguration,
                     email_account: newValue||null
                   })
                 }}
                 value={newAlertConfiguration.email_account??""}
                 placeholder={alertConfiguration?.email_account??""}/>
      <TextField label={"Mot de passe du compte émetteur de l'alerte :"}
                 onChange={(ev, newValue) => {
                   setNewAlertConfiguration({
                     ...newAlertConfiguration,
                     email_account_password: newValue||null
                   })
                 }}
                 value={newAlertConfiguration.email_account_password??""}
                 placeholder={"**************"}
                 type={"password"}/>
      <TextField label={"Adresse du serveur SMTP pour envoyer le mail d'alerte : "}
                 onChange={(ev, newValue) => {
                   setNewAlertConfiguration({
                     ...newAlertConfiguration,
                     email_smtp_server_address: newValue||null
                   })
                 }}
                 value={newAlertConfiguration.email_smtp_server_address??""}
                 placeholder={alertConfiguration?.email_smtp_server_address??""}/>
      <TextField label={"Port du serveur SMTP pour envoyer le mail d'alerte : "}
                 type={"integer"}
                 onChange={(ev, newValue) => {
                   let email_smtp_server_port = null;
                   if(newValue !== undefined) {
                     const parsed = parseInt(newValue, 10);
                     if (!isNaN(parsed))
                       email_smtp_server_port = parsed
                   }
                   setNewAlertConfiguration({
                     ...newAlertConfiguration,
                     email_smtp_server_port: email_smtp_server_port
                   })
                 }}
                 value={(newAlertConfiguration.email_smtp_server_port !== null)?newAlertConfiguration.email_smtp_server_port.toString():""}
                 placeholder={(alertConfiguration?.email_smtp_server_port !== null)?alertConfiguration?.email_smtp_server_port.toString():""}/>
      <Stack
          horizontal
          tokens={{childrenGap: 20}}
          styles={{root: {width: "100%", justifyContent: "center"}}}>
        <PrimaryButton text="Sauvegarder"
                       styles={{root: {width: 300}}}
                       disabled={disableSave}
                       onClick={() => {
                         saveConfiguration(newAlertConfiguration);
                      }}/>
        <DefaultButton text="Annuler"
                       styles={{root: {width: 300}}}
                       disabled={disableSave}
                       onClick={
          () => {
            setNewAlertConfiguration({
              ...defaultAlertConfiguration,
              send_sms_on_alert: alertConfiguration?.send_sms_on_alert??false,
              send_email_on_alert: alertConfiguration?.send_email_on_alert??false
            });
          }}/>
      </Stack>
    </Stack>
  )
}
